import { Box, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTimeZone } from '../../../../helpers/hooks';
import TicketPercent from '../../../../modules/assets/TicketPercent';
import CheckedIcon from './components/CheckedIcon';
import Disclaimer from './components/Disclaimer';
import { DisclaimerProps } from './components/Disclaimer/Disclaimer';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    border: '1px solid #CACECC',
    padding: 16,
  },
  titleLine: {
    display: 'flex',
    gap: 8,
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  titleDisable: {
    color: '#7C837F',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.3125rem',
    color: '#636965',
    marginTop: 8,
  },
  subtitleDisable: {
    color: '#969C98',
  },
  rulesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    marginTop: 16,
  },
  validThru: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.3125rem',
    color: '#969C98',
    marginTop: 16,
  },
  validThruDisable: {
    color: '#969C98',
  },
  ruleButton: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontFamily: theme.typography.h6.fontFamily,
    textTransform: 'uppercase',
  },
}));

type DiscountProps = {
  minimumOrderValue: number;
  title: string;
  restrictions: string;
  endDate?: string;
  disabled?: boolean;
  disclaimer?: DisclaimerProps;
  selected?: boolean;
  onSelect?: () => void;
};

const Discount: React.FC<DiscountProps> = ({
  minimumOrderValue,
  title,
  endDate,
  disabled,
  restrictions,
  disclaimer,
  selected,
  onSelect,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const { dayjsWithOffset } = useTimeZone();

  const handleOnSelect = disabled ? undefined : onSelect;

  const isLoyaltyVariant = disclaimer?.variant === 'loyalty';
  const mainColor = disabled
    ? '#CACECC'
    : isLoyaltyVariant
    ? '#FFBE5C'
    : undefined;

  return (
    <Box
      data-testid="discount-item"
      position="relative"
      style={{
        cursor: handleOnSelect ? 'pointer' : 'auto',
      }}
      onClick={handleOnSelect}
      role={handleOnSelect ? 'button' : 'none'}
    >
      <CheckedIcon selected={selected ?? false} />
      <Box
        className={classes.root}
        data-testid="discount-card"
        style={
          selected
            ? {
                borderRadius: disclaimer ? '8px 8px 0 0' : 8,
                borderColor: mainColor ?? '#CACECC',
                outline: `2px solid ${theme.palette.primary.main}`,
                outlineOffset: -2,
              }
            : {
                borderRadius: disclaimer ? '8px 8px 0 0' : 8,
                borderColor: mainColor ?? '#CACECC',
              }
        }
      >
        <Box className={classes.titleLine}>
          <TicketPercent
            style={
              mainColor
                ? { fill: mainColor }
                : { fill: theme.palette.primary.main }
            }
          />
          <Typography
            variant="h2"
            className={`${classes.title} ${disabled && classes.titleDisable}`}
            data-testid="coupon-discount"
          >
            {title}
          </Typography>
        </Box>
        <Typography
          className={`${classes.subtitle} ${
            disabled && classes.subtitleDisable
          }`}
          data-testid="coupon-minimum-order-value"
        >
          {restrictions}
        </Typography>

        {endDate && (
          <Typography
            className={`${classes.validThru} ${
              disabled && classes.validThruDisable
            }`}
            data-testid="coupon-end-data"
          >
            Disponível até {dayjsWithOffset(endDate).format('DD/MM/YYYY')}
          </Typography>
        )}
      </Box>
      {disclaimer && <Disclaimer {...disclaimer} />}
    </Box>
  );
};

export default Discount;
