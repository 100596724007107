import axios from 'axios';
import { authInterceptor } from '../interceptors/authInterceptor';
import { errorInterceptor } from '../interceptors/errorInterceptor';
import {
  successMessageRequestInterceptor,
  successMessageResponseInterceptor,
} from '../interceptors/successMessageResponseInterceptor';
import { setupCache } from 'axios-cache-interceptor';

const Api = axios.create({ baseURL: process.env.NEXT_PUBLIC_API_URL });

Api.defaults.headers = {
  'x-api-version': 2,
  'x-app-id': process.env.NEXT_PUBLIC_APP_ID,
  'x-app-version': process.env.NEXT_PUBLIC_APP_VERSION,
} as any;
Api.interceptors.request.use(authInterceptor);
Api.interceptors.request.use(successMessageRequestInterceptor);
Api.interceptors.response.use(
  successMessageResponseInterceptor,
  errorInterceptor
);

const axiosCache = setupCache(Api, {
  headerInterpreter: () => {
    return 0.5;
  },
});

export default axiosCache;
