import { createSlice } from '@reduxjs/toolkit';

type initialStateProps = {
  search: string;
};
const initialState: initialStateProps = { search: '' };

export const establishmentSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearch(state, { payload }) {
      state.search = payload;
    },
    cleanSearch(state) {
      state.search = '';
    },
  },
});

export default establishmentSlice.reducer;
export const { setSearch, cleanSearch } = establishmentSlice.actions;
