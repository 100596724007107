import { Grid, InputLabelProps, TextField, Typography } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';
import { toCurrencyValue } from '../../../helpers/utils';
/* eslint-disable react/jsx-props-no-spreading */

const currencyFormatter = (value: any, prefix: boolean): string => {
  if (Number(value) === undefined) return '';
  const valueToUse = value === 0 ? 0 : value / 100;
  const amount = toCurrencyValue(valueToUse, prefix);
  return `${amount}`;
};

const NumberFormatCustomBase = (props: any, prefix: boolean): JSX.Element => {
  const { inputRef, onChange, name, onBlur, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      type="tel"
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale={2}
      format={(e: any) => currencyFormatter(e, prefix)}
      onBlur={(e: any) => {
        const event = { ...e, target: { ...e.target, name } };
        onBlur(event);
      }}
      onValueChange={(values: any) => {
        onChange({
          target: {
            value: (values.floatValue ?? 0) / 100,
            name,
          },
        });
      }}
    />
  );
};

const InputWithPrefix = (props: any): JSX.Element => {
  return NumberFormatCustomBase(props, true);
};

const InputWithoutPrefix = (props: any): JSX.Element => {
  return NumberFormatCustomBase(props, false);
};

export interface CurrencyFormikInput {
  handleChange(e: React.ChangeEvent<any>): void;
  handleBlur(e: React.FocusEvent<any>): void;
  name: string;
  error?: boolean;
  helperText?: React.ReactNode;
  label?: string;
  required?: boolean;
  value: any;
  variant?: string;
  placeholder?: string;
  usePrefix?: boolean;
  fullWidth?: boolean;
  id?: string;
  disableDefaultMargin?: boolean;
  InputLabelProps?: InputLabelProps;
  small?: boolean;
  title?: string;
}

const CurrencyInput: React.FC<CurrencyFormikInput> = (props) => {
  const {
    name,
    label,
    error,
    helperText,
    required,
    handleChange,
    handleBlur,
    value,
    variant,
    usePrefix,
    placeholder,
    fullWidth,
    id,
    disableDefaultMargin,
    InputLabelProps: inputLabelProps,
    small,
    title,
  } = props;

  return (
    <Grid>
      {title && (
        <Typography
          variant="h6"
          style={{
            fontWeight: 500,
            lineHeight: '16px',
            fontSize: '16px',
            maxWidth: '200px',
          }}
        >
          {title}
        </Typography>
      )}
      <TextField
        id={id}
        required={required ?? false}
        style={{
          width: small ? 135 : undefined,
          marginTop: disableDefaultMargin ? undefined : 16,
          marginBottom: disableDefaultMargin ? undefined : 8,
        }}
        label={label}
        placeholder={placeholder}
        value={value * 100}
        variant={(variant ?? 'outlined') as any}
        error={error}
        name={name}
        helperText={helperText}
        InputLabelProps={inputLabelProps}
        fullWidth={fullWidth}
        InputProps={{
          onChange: handleChange,
          onBlur: handleBlur,
          inputComponent:
            usePrefix ?? true ? InputWithPrefix : InputWithoutPrefix,
        }}
      />
    </Grid>
  );
};

export default CurrencyInput;
