import Warning from '@mui/icons-material/Warning';
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { red } from '@mui/material/colors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toCurrencyValue } from '../../../../../helpers/utils';
import { setDelivery } from '../../../../../redux/reducers/order/reducer';
import {
  couponErrorSelector,
  itemsTotalSelector,
  orderCheckoutDiscountSelector,
  orderCheckoutTotalSelector,
} from '../../../../../redux/reducers/order/selectors';
import { RootState } from '../../../../../redux/types';
import { DeliveryType } from '../../../../models';
import MinValueIndicator from '../../../MinValueIndicator';

interface CartSummarySectionProps {
  disableDefaultPadding?: boolean;
}

const CartSummarySection: React.FC<CartSummarySectionProps> = ({
  disableDefaultPadding,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { delivery } = useSelector(
    (state: RootState) => state.orderReducer.order
  );
  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const discountTotal = useSelector(orderCheckoutDiscountSelector);
  const couponError = useSelector(couponErrorSelector);
  const subtotal = useSelector(itemsTotalSelector);
  const orderTotal = useSelector(orderCheckoutTotalSelector);

  useEffect(() => {
    if (
      establishment?.deliverySettings?.freeDeliveryValue &&
      subtotal >= establishment?.deliverySettings.freeDeliveryValue &&
      delivery !== undefined
    ) {
      dispatch(setDelivery({ ...delivery, fee: undefined }));
    }
  }, [subtotal]);

  return (
    <Grid
      container
      style={{
        gap: 8,
        margin: '20px 0',
        marginBottom: isMobile ? 56 : 20,
        paddingRight: !disableDefaultPadding ? 8 : undefined,
      }}
    >
      {establishment?.deliverySettings?.freeDeliveryValue !== undefined ? (
        <Grid item xs={12} style={{ marginBottom: 12 }}>
          <MinValueIndicator
            value={subtotal}
            maxValue={establishment.deliverySettings.freeDeliveryValue}
          >
            <Typography style={{ fontSize: '.875rem', marginBottom: 12 }}>
              {subtotal < establishment.deliverySettings.freeDeliveryValue
                ? `Faltam ${toCurrencyValue(
                    establishment?.deliverySettings.freeDeliveryValue - subtotal
                  )} para ganhar entrega grátis`
                : 'Você ganhou taxa de entrega grátis!'}
            </Typography>
          </MinValueIndicator>
        </Grid>
      ) : null}

      {establishment?.deliverySettings?.minDeliveryAmount &&
      delivery?.type === DeliveryType.delivery &&
      subtotal < establishment?.deliverySettings?.minDeliveryAmount ? (
        <Grid
          data-testid="min-delivery-amount"
          item
          xs={12}
          style={{ marginBottom: 12 }}
        >
          <MinValueIndicator
            value={subtotal}
            maxValue={establishment.deliverySettings.minDeliveryAmount}
          >
            <Typography style={{ fontSize: '.875rem', marginBottom: 12 }}>
              {`Faltam ${toCurrencyValue(
                establishment?.deliverySettings.minDeliveryAmount - subtotal
              )} para o pedido mínimo`}
            </Typography>
          </MinValueIndicator>
        </Grid>
      ) : null}

      <Grid item display="flex" justifyContent="space-between" xs={12}>
        <Typography style={{ fontWeight: 400 }}>Subtotal</Typography>

        <Typography style={{ fontWeight: 400 }}>
          {toCurrencyValue(subtotal)}
        </Typography>
      </Grid>

      {delivery?.type === DeliveryType.delivery && (
        <Grid item display="flex" justifyContent="space-between" xs={12}>
          <Typography style={{ fontWeight: 400 }}>Taxa de entrega</Typography>

          <Typography style={{ fontWeight: 400 }}>
            {toCurrencyValue(delivery?.fee ?? 0)}
          </Typography>
        </Grid>
      )}

      {discountTotal > 0 && (
        <Grid item display="flex" justifyContent="space-between" xs={12}>
          {couponError ? (
            <>
              <Box>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Warning style={{ color: red[500], fontSize: 16 }} />

                  <Typography style={{ fontWeight: 700, color: red[500] }}>
                    Cupom de desconto
                  </Typography>
                </Stack>

                <Typography
                  style={{
                    fontSize: '.875rem',
                    fontWeight: 600,
                    color: red[500],
                    marginLeft: 24,
                  }}
                >
                  {couponError}
                </Typography>
              </Box>

              <Typography style={{ fontWeight: 400, color: red[500] }}>
                -{toCurrencyValue(discountTotal)}
              </Typography>
            </>
          ) : (
            <>
              <Typography style={{ fontWeight: 400 }}>
                Cupom de desconto
              </Typography>

              <Typography style={{ fontWeight: 400 }}>
                -{toCurrencyValue(discountTotal)}
              </Typography>
            </>
          )}
        </Grid>
      )}

      <Grid item display="flex" justifyContent="space-between" xs={12}>
        <Typography style={{ fontWeight: 600 }}>Total</Typography>

        <Typography style={{ fontWeight: 600 }}>
          {toCurrencyValue(orderTotal)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CartSummarySection;
