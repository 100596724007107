import { Dialog, Drawer, Hidden, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import DeliverySchedulingFormContent from './components/DeliverySchedulingFormContent';

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    width: 570,
    padding: 0,
    margin: 8,

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      left: 'calc(50% - 285px)',
      borderRadius: '8px 8px 0 0',
    },

    [theme.breakpoints.down(570)]: {
      width: '100%',
      left: 'calc(50% - 50vw)',
    },
  },
}));

interface DeliverySchedulingDialogFormProps {
  onClose: () => void;
  onSubmitCallback?: () => void;
}

const DeliverySchedulingDialogForm: React.FC<
  DeliverySchedulingDialogFormProps
> = ({ onClose, onSubmitCallback }) => {
  const classes = useStyles();

  return (
    <>
      <Hidden smDown>
        <Dialog
          open
          PaperProps={{ className: classes.paper }}
          onBackdropClick={onClose}
          onClose={onClose}
        >
          <DeliverySchedulingFormContent
            onClose={onClose}
            onSubmitCallback={onSubmitCallback}
          />
        </Dialog>
      </Hidden>

      <Hidden smUp>
        <Drawer
          open
          anchor="bottom"
          onBackdropClick={onClose}
          onClose={onClose}
          PaperProps={{ className: classes.paper }}
        >
          <DeliverySchedulingFormContent
            onClose={onClose}
            onSubmitCallback={onSubmitCallback}
          />
        </Drawer>
      </Hidden>
    </>
  );
};

export default DeliverySchedulingDialogForm;
