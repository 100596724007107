import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import Coupon from '../../../../../../../../../modules/assets/Coupon';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  title: {
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '130%',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '150%',
  },
}));

type NoCouponFoundProps = {};

const NoCouponFound: React.FC<NoCouponFoundProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} data-testid="no-coupon-found-container">
      <Coupon />
      <Typography mt={'25px'} className={classes.title} variant="h3">
        Digite seu cupom!
      </Typography>
      <Typography mt={'8px'} className={classes.subtitle}>
        Aplique um desconto na sua
        <br />
        compra utilizando o campo acima 😉
      </Typography>
    </Box>
  );
};

export default NoCouponFound;
