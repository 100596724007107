import { createAsyncThunk } from '@reduxjs/toolkit';
import { publicOrderService } from '../../../shared/services/publicOrder';
import { authService } from '../../../shared/services/authService';
import { publicUserService } from '../../../shared/services/publicUser';

export const fetchActiveOrdersInfo = createAsyncThunk(
  'activeOrders/fetch',
  async (params: {
    establishmentGuid: string;
    orders: string[];
    pageIndex?: number;
  }) => {
    const { establishmentGuid, orders } = params;
    const userIsLogged = authService.getAccessToken();

    if (userIsLogged) {
      return publicUserService.getOrders(
        params.pageIndex ?? 1,
        5,
        establishmentGuid
      );
    }

    if (orders.length === 0) {
      return [];
    }

    return publicOrderService.getActiveOrders(establishmentGuid, orders);
  }
);
