import * as Sentry from '@sentry/nextjs';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export const errorInterceptor = async (error: any): Promise<any> => {
  const response = error.response as AxiosResponse;

  // TODO: Fix jest error when use asynchronous import
  // const Swal = (await import('sweetalert2')).default;

  if (error.config.headers.hideErrorMessage === 'true') {
    return Promise.reject(error);
  }

  if (response?.data?.errorName === 'DiscountCodeNeedsPublicUserException') {
    return Promise.reject(error);
  }

  if (response?.data?.errorName === 'EstablishmentClosedException') {
    Swal.fire({
      icon: 'error',
      title: 'Ops...',
      text: 'Infelizmente o estabelecimento acabou de fechar :(',
    });
  } else if (response?.data.errorName === 'NullReferenceException') {
    return Promise.reject(error);
  } else if (response?.data?.message !== undefined) {
    toast.error(response.data.message, { theme: 'colored' });
  } else if (response !== undefined) {
    toast.error('Erro desconhecido', { theme: 'colored' });
    Sentry.captureException({
      ...error,
      message: `Erro desconhecido:: ${error?.message}`,
    });
  }

  return Promise.reject(error);
};
