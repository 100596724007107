import { createSlice } from '@reduxjs/toolkit';
import { OrderTimingType } from '../../../shared/models';
import { OrderState } from '../../types';
import {
  fetchDeliverySchedulingAvailableDates,
  fetchOrder,
  fetchTakeAwaySchedulingAvailableDates,
  postOrder,
  updateStatusOrder,
} from './actions';

const initialState: OrderState = {
  order: {
    items: [],
    timing: {
      orderTimingType: OrderTimingType.immediate,
    },
  },
  deliverySchedulingAvailableDates: [],
  takeAwaySchedulingAvailableDates: [],
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    OrderCheckoutResetNewItemAddedIndicator(state) {
      state.newItemAdded = false;
    },
    clearOrder(state) {
      state.order = {
        ...state.order,
        items: [],
        discountCode: undefined,
        observations: undefined,
        orderCoupon: undefined,
        payment: undefined,
      };
    },

    addItem(state, { payload }) {
      state.newItemAdded = true;
      state.order.items?.push({ ...payload });
    },

    removeItem(state, { payload }) {
      state.order.items = state.order.items?.filter(
        (_, index) => index !== payload
      );
    },

    removeItemsByGuid(state, { payload }) {
      state.order.items = state.order.items?.filter(
        (item) => item.guid !== payload
      );
    },

    editItem(state, { payload }) {
      if (state.order.items !== undefined) {
        const newItemsArray = [
          ...state.order.items.slice(0, payload.index),
          { ...payload.item },
          ...state.order.items.slice(payload.index + 1),
        ];

        state.order.items = [...newItemsArray];
      }
    },

    setDiscountCode(state, { payload }) {
      state.order.orderCoupon = payload;
      state.order.discountCode = payload.guid;
    },

    removeDiscountCode(state) {
      state.order.orderCoupon = undefined;
      state.order.discountCode = undefined;
    },

    setPaymentMethod(state, { payload }) {
      state.order.payment = payload;
    },

    setDelivery(state, { payload }) {
      state.order.delivery = {
        type: state.order.delivery?.type,
        address: state.order.delivery?.address,
        ...payload,
      };
    },

    resetDelivery(state) {
      state.order.delivery = undefined;
    },

    setObservations(state, { payload }) {
      state.order.observations = payload;
    },

    setOrderTiming(state, { payload }) {
      state.order.timing = payload;
    },

    setOrderTrackingDetails(state, { payload }) {
      state.order.trackingDetails = payload;
    },

    resetRequestStatus(state) {
      state.requestStatus = undefined;
    },

    setOutLocalization(state, { payload }) {
      state.outLocalization = payload;
      if (payload) {
        state.order.delivery = undefined;
      }
    },

    setScheduledDeliveryInfo(state, { payload }) {
      state.scheduledDeliveryInfo = payload;
    },

    setOrderDetails(state, { payload }) {
      state.orderDetails = payload;
    },

    setOrderMetadata(state, { payload }) {
      state.orderMetadata = payload;
    },
  },
  extraReducers: {
    [fetchOrder.fulfilled.type]: (state, { payload }) => {
      state = payload;
    },
    [fetchOrder.fulfilled.type]: (state, { payload }) => {
      state = payload;
    },
    [fetchDeliverySchedulingAvailableDates.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.deliverySchedulingAvailableDates = payload;
    },
    [fetchTakeAwaySchedulingAvailableDates.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.takeAwaySchedulingAvailableDates = payload;
    },
    [postOrder.pending.type]: (state) => {
      state.requestStatus = 'pending';
    },
    [postOrder.fulfilled.type]: (state, { payload }) => {
      state.requestStatus = 'fulfilled';
    },
    [updateStatusOrder.fulfilled.type]: (state, { payload }) => {
      state = payload;
    },
  },
});

export default orderSlice.reducer;
export const {
  clearOrder,
  addItem,
  removeItem,
  removeItemsByGuid,
  editItem,
  setDiscountCode,
  removeDiscountCode,
  setPaymentMethod,
  setDelivery,
  resetDelivery,
  setObservations,
  setOrderTiming,
  setOrderTrackingDetails,
  resetRequestStatus,
  setOutLocalization,
  setScheduledDeliveryInfo,
  OrderCheckoutResetNewItemAddedIndicator,
  setOrderDetails,
  setOrderMetadata,
} = orderSlice.actions;
