import { Optional } from '.';

export enum PromotionStatus {
  activated = 'activated',
  deactivated = 'deactivated',
  deleted = 'deleted',
}

export enum ItemPriceStatus {
  activated = 'activated',
  deactivated = 'deactivated',
  deleted = 'deleted',
}

export enum ItemImageStatus {
  activated = 'activated',
  deactivated = 'deactivated',
  deleted = 'deleted',
}

export enum CategoryOperationMethod {
  default = 'default',
  schedule = 'schedule',
}

export interface Promotion {
  guid?: string;
  status: PromotionStatus;
  promotionDiscountPercentage: number;
  promotionStartDate?: string;
  promotionEndDate?: string;
}

export interface ItemRecommendation {
  itemGuid: string;
  itemName: string;
  itemDescription: string;
  imageUrl?: string;
}

export interface CartItemRecommendation extends ItemRecommendation {
  recommendationGuid: string;
}

interface PriceBase {
  name?: string;
  status: ItemPriceStatus;
  isDefault: boolean;
}

export interface Price extends PriceBase {
  guid: string;
  price: number;
  optionals: Optional[];
  promotions: Promotion[];
}

interface ProductBase {
  name: string;
  description?: string;
  showOnWeb: boolean;
  acceptsFractional: boolean;
  categoryGuid: string;
  optionals: Array<Optional>;
}

export interface Image {
  guid: string;
  coverFile: boolean;
  path?: string;
  thumbPath?: string;
  status: ItemImageStatus;
}

export interface Product extends ProductBase {
  guid: string;
  prices: Price[];
  coverImage?: string;
  thumbCoverImage?: string;
  images?: Image[];
  recommendation?: ItemRecommendation;
}

export interface PromotionalPrice {
  originalPrice: number;
  promotionalValue: number;
}
