import { Order, OrderCheckout } from '@wls-solucoes/lets-eat-types';
import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { AverageTime, OrderPaginatedData, OrderStatus } from '../models';
import Api from './api';

export interface OrderMetadata {
  query?: Record<string, unknown>;
}

const baseUrl = '/api/Order';

function getPublicDetails(
  establishmentGuid: string,
  orderGuid: string,
  token: string
): Promise<Order> {
  const url = `${baseUrl}/${establishmentGuid}/${orderGuid}`;
  return Api.get<Order>(url, { headers: { 'x-recaptcha': token } }).then(
    (res) => res.data
  );
}

function sendPublicOrderFromPanel(
  establishmentGuid: string,
  order: OrderCheckout,
  metadata?: OrderMetadata
): Promise<Order> {
  const url = `${baseUrl}/${establishmentGuid}`;
  return Api.post(
    url,
    { ...order, metadata },
    { headers: { hideErrorMessage: 'true' } }
  ).then((res) => res.data);
}

function updatePublicStatus(
  orderGuid: string,
  status: OrderStatus,
  description?: string
): Promise<AxiosResponse<OrderPaginatedData>> {
  const url = `${baseUrl}/${orderGuid}/status`;
  return Api.patch(url, {
    status,
    description,
  });
}

function checkDeliveryFee(
  type: string,
  lat: number,
  lng: number,
  establishmentGuid: string
): Promise<{
  error: boolean;
  fee?: { fee: number; averageTime: AverageTime };
}> {
  const query = queryString.stringifyUrl({
    url: `${baseUrl}/checkDeliveryFeeByEstablishment`,
    query: { type, latDest: lat, lngDest: lng, establishmentGuid },
  });

  return Api.get(query)
    .then((d) => ({ error: false, fee: d.data }))
    .catch(() => ({ error: true }));
}

export const orderService = {
  getPublicDetails,
  sendPublicOrderFromPanel,
  updatePublicStatus,
  checkDeliveryFee,
};
