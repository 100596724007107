import { createSlice } from '@reduxjs/toolkit';
import { PublicUserState } from '../../types';
import { fetchPublicUserAddresses } from './actions';

const initialState: PublicUserState = {
  isFetchingData: false,
};

export const publicUserSlice = createSlice({
  name: 'publicUser',
  initialState,
  reducers: {
    setPublicUserInfo: (state, { payload }) => {
      state.user = payload;
    },

    setIsFetchingUserData: (state, { payload }) => {
      state.isFetchingData = payload;
    },

    setUserAvatar: (state, { payload }) => {
      state.avatar = payload;
    },
  },
  extraReducers: {
    [fetchPublicUserAddresses.fulfilled.type]: (state, { payload }) => {
      if (state.user) {
        state.user.addresses = payload;
      }
    },
  },
});

export default publicUserSlice.reducer;
export const { setPublicUserInfo, setIsFetchingUserData, setUserAvatar } =
  publicUserSlice.actions;
