import { createSlice } from '@reduxjs/toolkit';
import { LocalAddressesState } from '../../types';

const initialState: LocalAddressesState = {
  addresses: [],
};

export const localAddressesSlice = createSlice({
  name: 'localAddresses',
  initialState,
  reducers: {
    addAddress(state, { payload }) {
      state.addresses.push(payload);
    },

    editAddress(state, { payload }) {
      const index = state.addresses.findIndex(
        (address) => address.guid === payload.guid
      );

      state.addresses[index] = payload;
    },

    removeAddress(state, { payload }) {
      state.addresses = state.addresses.filter(
        (address) => address.guid !== payload
      );
    },
  },
});

export default localAddressesSlice.reducer;
export const { addAddress, editAddress, removeAddress } =
  localAddressesSlice.actions;
