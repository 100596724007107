import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CouponListItem,
  couponFactory,
} from '../../../../../../../../helpers/utils';
import {
  clearDiscountCode,
  setCouponAwaitingAuth,
} from '../../../../../../../../redux/reducers/discountCode/reducer';
import {
  removeDiscountCode,
  setDiscountCode,
} from '../../../../../../../../redux/reducers/order/reducer';
import { orderCheckoutTotalSelector } from '../../../../../../../../redux/reducers/order/selectors';
import { getDiscountCode } from '../../../../../../../../redux/reducers/userDiscountCode/actions';
import { RootState } from '../../../../../../../../redux/types';

interface Controllers {
  selectedCouponGuid: string | undefined;
  setNewSelectedCoupon: (couponGuid: string) => void;
  submitANewCoupon: (
    onSuccess: () => void,
    onRequireAuth?: () => void
  ) => Promise<void>;
  isLoading: boolean;
  pagination: {
    totalPages: number;
    goToPage: (page: number) => void;
    currentPage: number;
    totalItems?: number;
  };
}
type useCouponCodeReturn = [CouponListItem[], Controllers];

const useCouponsList = (): useCouponCodeReturn => {
  const { order } = useSelector((state: RootState) => state.orderReducer);
  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );
  const { user } = useSelector((state: RootState) => state.publicUserReducer);
  const [selectedCouponGuid, setSelectedCouponGuid] = useState(
    order.discountCode ?? ''
  );

  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const orderTotal = useSelector(orderCheckoutTotalSelector);

  const { discountCode, isFetchingData } = useSelector(
    (state: RootState) => state.userDiscountReducer
  );

  const { coupon } = useSelector(
    (state: RootState) => state.discountCodeReducer
  );

  const loyaltyCoupon = (discountCode?.result ?? []).map((c) =>
    couponFactory(c, orderTotal, !c.isPublic)
  );
  const codeCoupon = coupon && couponFactory(coupon, orderTotal);

  const couponsReturn = [...loyaltyCoupon, codeCoupon].filter(
    (c) => c
  ) as CouponListItem[];

  const handleSubmitANewCoupon = async (
    onSuccess: () => void,
    onRequireAuth?: () => void
  ) => {
    if (!selectedCouponGuid) {
      dispatch(clearDiscountCode());
      dispatch(removeDiscountCode());
      onSuccess();
      return;
    }
    const myCurrentCoupon = [
      ...(discountCode?.result ?? []),
      ...[page ? coupon : {}],
    ].find((c) => c?.guid === selectedCouponGuid);

    if (myCurrentCoupon) {
      if (myCurrentCoupon.needsPublicUserAuth && !user) {
        dispatch(setCouponAwaitingAuth(myCurrentCoupon.code));
        onRequireAuth?.();

        return;
      }

      dispatch(setDiscountCode(myCurrentCoupon));
      onSuccess();
    }
  };

  const handleGoToPage = (page: number) => {
    setPage(page);
  };

  const handleSetSelectedCouponGuid = (couponGuid: string) => {
    setSelectedCouponGuid(couponGuid);
  };

  useEffect(() => {
    if (!establishment?.guid) {
      return;
    }

    dispatch(
      getDiscountCode({
        establishmentGuid: establishment.guid,
        pageIndex: page,
        pageSize: 5,
      })
    );
  }, [page, establishment]);

  const controllers: Controllers = {
    selectedCouponGuid,
    setNewSelectedCoupon: handleSetSelectedCouponGuid,
    submitANewCoupon: handleSubmitANewCoupon,
    isLoading: isFetchingData,
    pagination: {
      totalPages: discountCode?.totalPages ?? 0,
      goToPage: handleGoToPage,
      currentPage: page,
      totalItems: discountCode?.totalItems,
    },
  };

  return [couponsReturn, controllers];
};

export default useCouponsList;
