export function isValidCpf(value: string): boolean {
    let strCPF = value;
    strCPF = strCPF || '';
    strCPF = strCPF.replace(/[^0-9]/g, '');
    let soma;
    let resto;
    soma = 0;
    
    if (strCPF === '00000000000') {
      return false;
    }
  
    for (let i = 1; i <= 9; i++) {
      soma += parseFloat(strCPF.substring(i - 1, i)) * (11 - i);
    }
  
    resto = (soma * 10) % 11;
  
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
  
    if (resto !== parseFloat(strCPF.substring(9, 10))) {
      return false;
    }
  
    soma = 0;
  
    for (let i = 1; i <= 10; i++) {
      soma += parseFloat(strCPF.substring(i - 1, i)) * (12 - i);
    }
  
    resto = (soma * 10) % 11;
  
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
  
    if (resto !== parseFloat(strCPF.substring(10, 11))) {
      return false;
    }
  
    return true;
  }