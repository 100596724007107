import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import { FormikProps, useFormik } from 'formik';
import React from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { DocumentType } from '../../../../../../../../shared/models';

const useStyles = makeStyles(() => ({
  paper: {
    width: 390,
    padding: 32,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DocumentValueDialogProps {
  paymentMethodFormik: FormikProps<any>;
  onClose: () => void;
}

const DocumentValueDialog: React.FC<DocumentValueDialogProps> = ({
  paymentMethodFormik,
  onClose,
}) => {
  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    document: Yup.string().test(
      'documentValidation',
      'Informe um CPF ou CNPJ válido',
      (value) => {
        if (!value || value?.length === 11 || value?.length === 14) {
          return true;
        }

        return false;
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      document: paymentMethodFormik.values.document,
      documentType: paymentMethodFormik.values.documentType,
    },
    validationSchema,
    onSubmit: (values) => {
      if (!values.document) {
        paymentMethodFormik.setFieldValue('document', undefined);
        paymentMethodFormik.setFieldValue('documentType', undefined);
      } else {
        paymentMethodFormik.setFieldValue('document', values.document);
        paymentMethodFormik.setFieldValue('documentType', values.documentType);
      }

      paymentMethodFormik.handleSubmit();

      onClose();
    },
  });

  const { values, errors, touched, setFieldValue, handleBlur, handleSubmit } =
    formik;

  const InputMaskAny = InputMask as any;

  return (
    <Dialog
      open
      onBackdropClick={onClose}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{ className: classes.paper }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ paddingBottom: 24 }}
      >
        <Typography style={{ fontSize: '1.125rem', fontWeight: 600 }}>
          CPF/CNPJ na nota
        </Typography>

        <Tooltip arrow title="Fechar">
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Box>

      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          padding: 0,
        }}
      >
        <Box mt={1}>
          <InputMaskAny
            mask={
              (values.document?.replace(/[^\d]+/g, '').length ?? 0) < 12
                ? '999.999.999-999'
                : '99.999.999/9999-99'
            }
            value={values.document}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value.replace(/[^\d]+/g, '');

              if (value.length > 11) {
                setFieldValue('documentType', DocumentType.cnpj);
              } else {
                setFieldValue('documentType', DocumentType.cpf);
              }

              setFieldValue('document', value);
            }}
            onBlur={handleBlur}
            maskChar=""
          >
            {() => (
              <TextField
                fullWidth
                autoFocus
                name="document"
                label="CPF/CNPJ"
                variant="outlined"
                error={touched.document && errors.document !== undefined}
                helperText={
                  errors.document && touched.document && errors.document
                }
              />
            )}
          </InputMaskAny>
        </Box>
      </DialogContent>

      <DialogActions style={{ paddingTop: 24 }}>
        <Button
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentValueDialog;
