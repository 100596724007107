import { createSlice } from '@reduxjs/toolkit';
import { ProgressIndicatorState } from '../../types';

const initialState: ProgressIndicatorState = {
  onFetchingData: [],
};

export const progressIndicatorSlice = createSlice({
  name: 'progressIndicator',
  initialState,
  reducers: {
    addIndicator(state, { payload }) {
      state.onFetchingData = [...state.onFetchingData, payload];
    },

    removeIndicator(state, { payload }) {
      state.onFetchingData = state.onFetchingData.filter((i) => i !== payload);
    },
  },
});

export default progressIndicatorSlice.reducer;
export const { addIndicator, removeIndicator } = progressIndicatorSlice.actions;
