import { createSlice } from '@reduxjs/toolkit';
import { CarouselState } from '../../types';

const initialState: CarouselState = {
  src: '',
  srcLoading: '',
  title: '',
  subtitle: '',
  uuid: '',
  price: 0,
  isOpen: false,
};

export const carouselSlice = createSlice({
  name: 'carousel',
  initialState,
  reducers: {
    openCarousel: (state, { payload }) => {
      state.src = payload.src;
      state.title = payload.title;
      state.subtitle = payload.subtitle;
      state.price = payload.price;
      state.srcLoading = payload.srcLoading;
      state.uuid = payload.uuid;
      state.isOpen = true;
    },
    closeCarousel: (state, { payload }) => {
      state.isOpen = false;
      state.src = '';
    },
  },
});

export default carouselSlice.reducer;
export const { openCarousel, closeCarousel } = carouselSlice.actions;
