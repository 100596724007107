import { Typography } from '@mui/material';
import React from 'react';
import { getTimeWithoutSeconds } from '.';
import {
  DayOfTheWeek,
  OpenCloseHour,
  OpenCloseHourStatus,
} from '../../shared/models';

export const getOpeningHours = (
  dayOfTheWeek: DayOfTheWeek,
  openingHours?: OpenCloseHour[]
): JSX.Element | JSX.Element[] => {
  const schedules = openingHours?.filter(
    (openingHour) => openingHour.dayOfTheWeek === dayOfTheWeek
  );

  if (
    schedules?.some(
      (schedule) => schedule.status === OpenCloseHourStatus.activated
    )
  ) {
    return schedules?.map((schedule, index) => {
      if (schedule.status === OpenCloseHourStatus.activated) {
        if (index === schedules.length - 1) {
          return (
            <Typography
              key={schedule.guid}
              style={{
                whiteSpace: 'nowrap',
                alignSelf: 'flex-end',
                marginLeft: 'auto'
              }}
            >{`${getTimeWithoutSeconds(
              schedule.open
            )} às ${getTimeWithoutSeconds(schedule.close)}`}</Typography>
          );
        }

        return (
          <Typography
            key={schedule.guid}
            style={{
              whiteSpace: 'nowrap',
              alignSelf: 'flex-end',
              marginLeft: 'auto'
            }}
          >{`${getTimeWithoutSeconds(schedule.open)} às ${getTimeWithoutSeconds(
            schedule.close
          )} | `}</Typography>
        );
      }

      return <></>;
    });
  }

  return <Typography data-testid="dont-open" style={{marginLeft: 'auto'}}>Não abre</Typography>;
};
