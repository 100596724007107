import {
  Backdrop,
  Box,
  Card,
  LinearProgress,
  Theme,
  Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { makeStyles, withStyles } from '@mui/styles';
import Image from 'next/image';

interface ProgressIndicatorProps {
  ids?: Array<string>;
  children?: ReactElement<any, any>;
}

const useStyles = makeStyles<Theme>((theme) => ({
  fullPageLoader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    inset: 0,
  },
  fullPageLoaderImage: {
    animation: `$heartbeat 1.8s infinite`,
  },
  '@keyframes heartbeat': {
    '0%': {
      transform: 'scale(1)',
    },
    '25%': {
      transform: 'scale(1.05)',
    },
    '50%': {
      transform: 'scale(1)',
    },
    '75%': {
      transform: 'scale(1.05)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}));

const StyledLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'rgba(71, 176, 98, 0.5)',
  },
  barColorPrimary: {
    backgroundColor: '#47b062',
  },
})(LinearProgress);

const ProgressIndicator: React.FC<ProgressIndicatorProps> = (props) => {
  const classes = useStyles();

  const ids = useSelector(
    (state: RootState) => state.progressIndicatorReducer.onFetchingData
  );

  const { children } = props;

  return (
    <div>
      <Backdrop
        style={{ zIndex: 9999 }}
        open={(ids?.length ?? 0) > 0}
        data-testid="progress-indicator-backdrop"
      >
        <Card>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            pl={4}
            pr={4}
          >
            <div className={classes.fullPageLoader}>
              <div
                style={{
                  width: '100%',
                  height: 28,
                  position: 'relative',
                  overflow: 'inherit',
                }}
              >
                <Image
                  src={'/svg/ComprAqui-green-logo.svg'}
                  alt="ComprAqui"
                  layout="fill"
                  objectFit="contain"
                  className={classes.fullPageLoaderImage}
                />
              </div>
            </div>
            <Box pt={3} mb={1} ml={3} mr={3} mt={5}>
              <Typography variant="body2">Por favor aguarde...</Typography>
            </Box>
          </Box>
          <StyledLinearProgress />
        </Card>
      </Backdrop>
      {children}
    </div>
  );
};

export default ProgressIndicator;
