import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CategoryType, ItemType } from '@wls-solucoes/lets-eat-types';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEventHandler } from '../../../helpers/hooks';
import {
  removeItemDetails,
  setCatalog,
  setIsLoadingItemDetails,
  setItemDetails,
  setItemToEditInfo,
  setRecommendationGuid,
} from '../../../redux/reducers/catalog/reducer';
import {
  OrderCheckoutResetNewItemAddedIndicator,
  removeItemsByGuid,
} from '../../../redux/reducers/order/reducer';
import { itemsTotalSelector } from '../../../redux/reducers/order/selectors';
import { removeRecommendation } from '../../../redux/reducers/recommendation/reducer';
import { RootState } from '../../../redux/types';
import { CartItemRecommendation, ItemCheckout } from '../../models';
import { establishmentService } from '../../services/establishment';
import AvailableCouponDialogAlert from '../AvailableCouponDialogAlert';
import CartCouponBox from '../CartCouponBox';
import LoyaltyPointIndicator from '../LoyaltyPointIndicator';
import CartCouponSection from './components/CartCouponSection';
import CartItemsSection from './components/CartItemsSection';
import CartRecommendationsSection from './components/CartRecommendationsSection';
import CartSummarySection from './components/CartSummarySection';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    maxHeight: 400,
    overflowY: 'auto',
    margin: '20px 0',

    [theme.breakpoints.down('sm')]: {
      maxHeight: 'unset',
    },
  },
}));

interface CartContentProps {
  pageVariant?: boolean;
  onClose: () => void;
}

const CartContent: React.FC<CartContentProps> = ({ pageVariant, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const { eventHandler } = useEventHandler();
  const isXs = useMediaQuery('(min-width:600px)');

  const { catalog } = useSelector((state: RootState) => state.catalogReducer);
  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );
  const { recommendations } = useSelector(
    (state: RootState) => state.recommendationReducer
  );
  const { orderCoupon, items } = useSelector(
    (state: RootState) => state.orderReducer.order
  );

  const value = useSelector(itemsTotalSelector);
  const maxValue = orderCoupon?.minimumOrderValue ?? 0;
  const couponIsNotValid =
    value < maxValue && typeof orderCoupon?.minimumOrderValue === 'number';

  const handleEditItem = (item: ItemCheckout, index: number): void => {
    dispatch(setItemToEditInfo({ itemInfo: item, index }));

    dispatch(setIsLoadingItemDetails(true));

    establishmentService
      .getItemDetails(
        establishment?.guid ?? '',
        item.guid,
        item.type === ItemType.size ? CategoryType.pizza : undefined
      )
      .then((item) => {
        dispatch(setItemDetails(item));

        if (!isXs) {
          router.push({
            pathname: `/item-details/${item.guid}`,
            query: { type: item.type },
          });
        }
      })
      .catch(async () => {
        const Swal = (await import('sweetalert2')).default;

        const hasItemsInCart = items?.some((item) => item.guid === item.guid);

        if (hasItemsInCart) {
          dispatch(removeItemsByGuid(item.guid));
        }

        if (recommendations?.some((i) => i.itemGuid === item.guid)) {
          dispatch(removeRecommendation(item.guid));
        }

        if (establishment?.guid) {
          establishmentService
            .getEstablishmentCatalog(establishment.guid)
            .then((v) => {
              const updatedValues = v.map((category) => {
                const updatedCategory = {
                  ...category,
                  expanded: false,
                };

                return updatedCategory;
              });

              dispatch(setCatalog(updatedValues));
            });
        }

        Swal.fire({
          title: 'Ops!',
          text: `O produto ${item?.name} não está disponível no momento${
            hasItemsInCart ? `, as unidades em seu pedido foram REMOVIDAS` : ''
          }.`,
          icon: 'error',
        });
      })
      .finally(() => dispatch(setIsLoadingItemDetails(false)));
  };

  const handleAddRecommendedItemToCart = (
    recommendation: CartItemRecommendation
  ): void => {
    dispatch(setRecommendationGuid(recommendation.recommendationGuid));

    if (isXs) {
      dispatch(setIsLoadingItemDetails(true));

      establishmentService
        .getItemDetails(establishment?.guid ?? '', recommendation.itemGuid)
        .then((item) => {
          dispatch(setItemDetails(item));
        })
        .catch(async () => {
          const Swal = (await import('sweetalert2')).default;

          const item = catalog
            ?.find((category) =>
              category.items.some(
                (item) => item.guid === recommendation.itemGuid
              )
            )
            ?.items.find((item) => item.guid === recommendation.itemGuid);

          const hasItemsInCart = items?.some(
            (item) => item.guid === recommendation.itemGuid
          );

          if (hasItemsInCart) {
            dispatch(removeItemsByGuid(recommendation.itemGuid));
          }

          dispatch(removeRecommendation(recommendation.itemGuid));

          if (establishment?.guid) {
            establishmentService
              .getEstablishmentCatalog(establishment.guid)
              .then((v) => {
                const updatedValues = v.map((category) => {
                  const updatedCategory = {
                    ...category,
                    expanded: false,
                  };

                  return updatedCategory;
                });

                dispatch(setCatalog(updatedValues));
              });
          }

          Swal.fire({
            title: 'Ops!',
            text: `O produto ${item?.name} não está disponível no momento${
              hasItemsInCart
                ? `, as unidades em seu pedido foram REMOVIDAS`
                : ''
            }.`,
            icon: 'error',
          });
        })
        .finally(() => dispatch(setIsLoadingItemDetails(false)));
    } else {
      dispatch(removeItemDetails());
      router.push(`/item-details/${recommendation.itemGuid}`);
    }
  };

  return (
    <>
      <AvailableCouponDialogAlert dialogId="cart-coupon-dialog-home" />

      <Grid
        container
        data-testid="cart-content-container"
        flexDirection="column"
        justifyContent="space-between"
        flexWrap="nowrap"
        style={{ height: '100%' }}
      >
        <Grid
          item
          display="flex"
          flexDirection="column"
          style={{
            flex: '1 1 auto',
            overflowY: 'auto',
            padding: pageVariant ? '0 16px' : undefined,
          }}
        >
          {!pageVariant && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box width={40} height={40} />

              <Typography style={{ fontSize: '1.125rem', fontWeight: 600 }}>
                Meu carrinho
              </Typography>

              <Tooltip arrow title="Fechar">
                <IconButton
                  data-testid="close-cart-drawer"
                  size="small"
                  onClick={onClose}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </Box>
          )}

          {couponIsNotValid && (
            <CartCouponBox
              onClose={onClose}
              value={value}
              maxValue={maxValue}
            />
          )}

          <Box className={classes.container}>
            <CartItemsSection handleEditItem={handleEditItem} />

            <Divider />

            <LoyaltyPointIndicator />

            <Divider />

            {recommendations.length > 0 && (
              <>
                <CartRecommendationsSection
                  recommendations={recommendations}
                  onAddRecommendedItem={handleAddRecommendedItemToCart}
                />

                <Divider />
              </>
            )}

            <CartCouponSection />

            <Divider />

            <CartSummarySection />
          </Box>
        </Grid>

        <Grid
          item
          style={
            pageVariant
              ? {
                  flex: '0 0 auto',
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  backgroundColor: '#fff',
                  padding: 16,
                }
              : {
                  flex: '0 0 auto',
                }
          }
        >
          <Button
            data-testid="cart-content-checkout-button"
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            style={{ alignSelf: 'flex-end' }}
            onClick={() => {
              eventHandler.initiateCheckout();
              dispatch(OrderCheckoutResetNewItemAddedIndicator());
              router.push('/checkout');
            }}
          >
            {couponIsNotValid
              ? 'continuar sem desconto'
              : 'ir para o pagamento'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CartContent;
