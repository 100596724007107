import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  Radio,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTimeZone } from '../../../../../../../../../../helpers/hooks';
import {
  setOrderTiming,
  setScheduledDeliveryInfo,
} from '../../../../../../../../../../redux/reducers/order/reducer';
import { RootState } from '../../../../../../../../../../redux/types';
import {
  DeliveryType,
  OrderTimingType,
} from '../../../../../../../../../../shared/models';
import AvailableDayButton from '../AvailableDayButton';

const useStyles = makeStyles<Theme>((theme) => ({
  availableDaysContainer: {
    width: '100%',
    alignItems: 'flex-end',
    overflowX: 'auto',
    gap: 8,

    msOverflowStyle: 'none',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  listItem: {
    padding: '16px 8px 16px 32px',

    transition: 'all .2s ease-in-out',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F5F5F5',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 16px',
    },
  },
}));

interface DeliverySchedulingFormContentProps {
  onClose: () => void;
  onSubmitCallback?: () => void;
}

const DeliverySchedulingFormContent: React.FC<
  DeliverySchedulingFormContentProps
> = ({ onClose, onSubmitCallback }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const { dayjsWithOffset } = useTimeZone();

  const {
    order,
    deliverySchedulingAvailableDates,
    takeAwaySchedulingAvailableDates,
    scheduledDeliveryInfo,
  } = useSelector((state: RootState) => state.orderReducer);

  const schedulingAvailableDates =
    order.delivery?.type === DeliveryType.delivery
      ? deliverySchedulingAvailableDates
      : takeAwaySchedulingAvailableDates;

  const [selectedAvailableDay, setSelectedAvailableDay] = useState(
    scheduledDeliveryInfo?.selectedAvailableDayIndex ?? 0
  );

  const handleChangeAvailableDay = (index: number) => {
    setSelectedAvailableDay(index);
  };

  const formik = useFormik({
    initialValues: {
      deliveryDateTimeStart: scheduledDeliveryInfo?.deliveryDateTimeStart ?? '',
      deliveryDateTimeEnd: scheduledDeliveryInfo?.deliveryDateTimeEnd ?? '',
    },
    onSubmit: (values) => {
      dispatch(
        setScheduledDeliveryInfo({
          ...values,
          orderTimingType: OrderTimingType.scheduled,
          selectedAvailableDayIndex: selectedAvailableDay,
        })
      );
      dispatch(
        setOrderTiming({
          ...values,
          orderTimingType: OrderTimingType.scheduled,
        })
      );

      if (onSubmitCallback) {
        onSubmitCallback();
      } else {
        onClose();
      }
    },
  });

  const { values, setValues, handleSubmit } = formik;

  return (
    <>
      <Box padding={isXs ? 2 : '32px 32px 24px'}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" style={{ fontWeight: 600 }}>
            Agendar entrega
          </Typography>

          <Tooltip arrow title="Fechar">
            <IconButton data-testid="close-button" onClick={onClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>

        <Box
          data-testid="available-days-container"
          mt={3}
          display="flex"
          alignItems="center"
          className={classes.availableDaysContainer}
        >
          {schedulingAvailableDates?.map((day, index) => (
            <AvailableDayButton
              selected={index === selectedAvailableDay}
              key={day.date}
              date={day.date}
              dayOfWeek={day.dayOfWeek}
              onClick={() => handleChangeAvailableDay(index)}
            />
          ))}
        </Box>
      </Box>

      <DialogContent style={{ padding: 0 }}>
        <List data-testid="range-container">
          {(schedulingAvailableDates ?? [])[selectedAvailableDay].hours?.map(
            (range) => (
              <ListItem
                key={range.from}
                className={classes.listItem}
                secondaryAction={
                  <Radio
                    checked={
                      range.from === values.deliveryDateTimeStart &&
                      range.to === values.deliveryDateTimeEnd
                    }
                  />
                }
                onClick={() => {
                  setValues({
                    deliveryDateTimeStart: range.from,
                    deliveryDateTimeEnd: range.to,
                  });
                }}
              >
                <Typography>
                  {dayjsWithOffset(range.from).format('HH:mm')} à{' '}
                  {dayjsWithOffset(range.to).format('HH:mm')}
                </Typography>
              </ListItem>
            )
          )}
        </List>
      </DialogContent>

      <DialogActions
        style={{
          flexDirection: 'column',
          padding: isXs ? '8px 16px 16px' : '16px 32px 32px',
        }}
      >
        <Typography
          style={{ fontSize: '.875rem', color: '#7C837F', marginBottom: 16 }}
        >
          *Os horários são aproximados
        </Typography>

        <Button
          fullWidth
          disableElevation
          disabled={
            !values.deliveryDateTimeEnd || !values.deliveryDateTimeStart
          }
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          confirmar horário
        </Button>
      </DialogActions>
    </>
  );
};

export default DeliverySchedulingFormContent;
