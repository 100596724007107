import { Box } from '@mui/material';
import React from 'react';

interface PixIconProps {
  color?: string;
  size?: number;
}

const PixIcon: React.FC<PixIconProps> = ({ color = '#00bdae', size = 35 }) => {
  return (
    <Box width={size} height={size}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 1024 1024"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          style={{ transition: 'all .4s' }}
          fill={color}
          stroke={color}
          strokeWidth={1}
        >
          <path d="M496.5 960.8 c-23 -2.6 -45.8 -11.6 -66 -26.1 -4.2 -3.1 -42.5 -40.5 -96.3 -94.1 l-89.3 -89.1 22.8 -0.6 c24.4 -0.7 30.6 -1.6 46.4 -6.9 11.2 -3.7 20 -8.2 32 -16.1 8.2 -5.4 19.1 -15.9 83.4 -80 56.8 -56.6 75 -74.3 78.5 -75.8 5.5 -2.5 12 -2.6 18.6 -0.5 4.5 1.5 11.6 8.3 78.9 75.4 77.6 77.4 81.5 80.9 98.9 89.7 18 9.1 34.7 13 59.2 14.1 l15.5 0.7 -89.3 89.1 c-53.8 53.6 -92.1 91 -96.3 94.1 -29.2 21 -62.7 30 -97 26.1z" />
          <path d="M149.7 656 c-31.2 -31.3 -57.5 -58.6 -60.4 -62.5 -36.2 -50.2 -36 -113.9 0.5 -163.5 3.1 -4.3 28.9 -30.9 60.2 -62.4 l54.7 -54.8 37.3 0.1 c39.1 0.2 46.1 0.8 58.5 5.1 7.3 2.5 16.2 7.1 23.9 12.2 3.8 2.5 34.4 32.3 81.1 78.8 72.2 71.9 75.3 74.9 82.5 78.4 10.4 5 17.7 6.6 29.5 6.6 12.1 0 19.2 -1.6 30.5 -7.1 l8.5 -4.2 75.5 -75.2 c80.2 -79.9 81 -80.6 96.9 -87 14.5 -5.9 20 -6.6 56.1 -7.4 l33.5 -0.7 55.1 55.1 c32.2 32.1 57.4 58.1 60.6 62.5 36.6 49.8 36.6 114.2 0 164 -3.2 4.4 -28.4 30.4 -60.6 62.5 l-55.1 55.1 -33.5 -0.7 c-36.1 -0.8 -41.6 -1.5 -56.1 -7.4 -15.9 -6.4 -16.8 -7.2 -95.4 -85.6 -50.8 -50.6 -75.9 -74.9 -80.1 -77.6 -18.6 -11.9 -43.8 -13.3 -64.8 -3.6 l-8.1 3.7 -75 74.6 c-47.1 46.9 -77.3 76.2 -81.2 78.8 -13.1 8.8 -26 13.9 -40.3 16.1 -4.2 0.6 -23.1 1.2 -43.3 1.4 l-35.8 0.2 -55.2 -55.5z" />
          <path d="M509 452.7 c-2.7 -0.8 -20.9 -18.3 -78.5 -75.7 -65 -64.7 -76.3 -75.5 -84.4 -80.9 -11.6 -7.7 -20 -11.9 -31.1 -15.9 -15.2 -5.3 -22.6 -6.4 -47.3 -7.1 l-22.8 -0.6 89.3 -89.1 c53.8 -53.6 92.1 -91 96.3 -94.1 15.4 -11 31 -18.4 48.2 -22.8 39.8 -10 80.4 -1.9 114.8 22.8 4.2 3.1 42.5 40.5 96.3 94.1 l89.3 89.1 -15.3 0.6 c-24.2 1.1 -41.4 5.1 -59.4 14.2 -17.4 8.8 -21.3 12.3 -98.4 89.2 -56.5 56.4 -74.5 73.9 -78 75.4 -5 2.3 -13.1 2.6 -19 0.8z" />
        </g>
      </svg>
    </Box>
  );
};

export default PixIcon;
