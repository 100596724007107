import { UnityOfTime } from '../../shared/models';

/**
 * Receives a unity of time for delivery or take out and returns your translation.
 *
 * @param unity The unity you want to translate.
 * @param abbreviated If you want to abbreviate the unity.
 * @return The translated form in string.
 */
export const getUnityOfTime = (
  unity: UnityOfTime,
  abbreviated = false
): string => {
  switch (unity) {
    case UnityOfTime.day:
      return !abbreviated ? 'dias' : 'd';

    case UnityOfTime.hour:
      return !abbreviated ? 'horas' : 'hrs';

    default:
      return !abbreviated ? 'minutos' : 'min';
  }
};
