import { createSlice } from '@reduxjs/toolkit';
import { UserDiscountCodeState } from '../../types';
import { getDiscountCode } from './actions';

const initialState: UserDiscountCodeState = {
  isFetchingData: false,
};

export const userDiscountSlice = createSlice({
  name: 'userDiscountCode',
  initialState,
  reducers: {},
  extraReducers: {
    [getDiscountCode.pending.type]: (state, { payload }) => {
      state.isFetchingData = true;
    },
    [getDiscountCode.fulfilled.type]: (state, { payload }) => {
      state.discountCode = payload;
      state.isFetchingData = false;
    },
    [getDiscountCode.rejected.type]: (state, { payload }) => {
      state.isFetchingData = false;
    },
  },
});

export default userDiscountSlice.reducer;
