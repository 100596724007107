import { ArrowBack, Close } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grow,
  IconButton,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import {
  GenericStatus,
  IntegrationStatus,
  PaymentType,
} from '@wls-solucoes/lets-eat-types';
import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEventHandler } from '../../../../../../helpers/hooks';
import {
  setDelivery,
  setPaymentMethod,
} from '../../../../../../redux/reducers/order/reducer';
import { orderCheckoutTotalSelector } from '../../../../../../redux/reducers/order/selectors';
import { RootState } from '../../../../../../redux/types';
import ChangeMoneyValueDialog from './components/ChangeMoneyValueDialog';
import DocumentValueDialog from './components/DocumentValueDialog';
import NeedChangeMoneyDialog from './components/NeedChangeMoneyDialog';
import NeedFiscalDocumentDialog from './components/NeedFiscalDocumentDialog';
import PaymentLinkConfirmationDialog from './components/PaymentLinkConfirmationDialog';
import { useBooleanState } from '../../../../../../helpers/hooks/useBooleanState';
import PaymentDialogContent from './components/PaymentDialogContent';
import { useMercadoPagoPayment } from './hooks/useMercadoPagoPayment';
import { LoadingButton } from '@mui/lab';
import MPCardPayment from './components/MPCardPayment';
import { DialogDrawer } from '../../../../../../shared/components/DialogDrawer';

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      margin: 0,
    },
  },

  dialogContent: {
    padding: '0 24px',
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow in ref={ref} {...props} />;
});

interface PaymentMethodDialogProps {
  onSubmitCallback?: () => void;
  onClose: () => void;
  onOpen: () => void;
}

const PaymentMethodDialog: React.FC<PaymentMethodDialogProps> = ({
  onSubmitCallback,
  onClose,
  onOpen,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { eventHandler } = useEventHandler();
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );
  const { payment, delivery } = useSelector(
    (state: RootState) => state.orderReducer.order
  );

  const { user } = useSelector((state: RootState) => state.publicUserReducer);

  const orderTotal = useSelector(orderCheckoutTotalSelector);

  const acceptMercadoPago = establishment?.paymentSettings.acceptMercadoPago;
  const acceptPix = establishment?.paymentSettings.acceptPix;
  const acceptCash = establishment?.paymentSettings.acceptCash;
  const acceptCredit = establishment?.paymentSettings.acceptCredit;
  const acceptDebit = establishment?.paymentSettings.acceptDebit;
  const acceptVoucher = establishment?.paymentSettings.acceptVoucher;
  const acceptCardPaymentsWithMercadoPago =
    establishment?.paymentSettings.acceptCardPaymentsWithMercadoPago || false;

  const hasAutomaticPix =
    establishment?.paymentSettings.shipaySettings?.integrationStatus ===
      IntegrationStatus.approved &&
    establishment.paymentSettings.shipaySettings.status ===
      GenericStatus.activated;
  const hasOnlinePayments =
    acceptMercadoPago ||
    acceptPix ||
    acceptCardPaymentsWithMercadoPago ||
    hasAutomaticPix;

  const hasPaymentsOnDelivery =
    acceptCash || acceptCredit || acceptDebit || acceptVoucher;

  const [
    showPaymentLinkConfirmationDialog,
    handleOpenPaymentLinkConfirmationDialog,
    handleClosePaymentLinkConfirmationDialog,
  ] = useBooleanState(false);

  const [
    showNeedChangeMoneyDialog,
    handleOpenNeedChangeMoneyDialog,
    handleCloseNeedChangeMoneyDialog,
  ] = useBooleanState(false);

  const [
    showNeedFiscalDocumentDialog,
    handleOpenNeedFiscalDocumentDialog,
    handleCloseNeedFiscalDocumentDialog,
  ] = useBooleanState(false);

  const [
    showChangeMoneyValueDialog,
    handleOpenChangeMoneyValueDialog,
    handleCloseChangeMoneyValueDialog,
  ] = useBooleanState(false);

  const [
    showDocumentValueDialog,
    handleOpenDocumentValueDialog,
    handleCloseDocumentValueDialog,
  ] = useBooleanState(false);

  const [
    showMercadoPagoContent,
    handleOpenMercadoPagoContent,
    handleCloseMercadoPagoContent,
  ] = useBooleanState(false);

  const initialValues = {
    paymentType: payment?.paymentType,
    cardFlagGuid: payment?.cardFlagGuid,
    cardFlagName: payment?.cardFlagName,
    changeMoney: payment?.changeMoney,
    paymentAmount: payment?.paymentAmount,
    document: delivery?.document,
    documentType: delivery?.documentType,
    paymentMercadoPagoCard: payment?.paymentMercadoPagoCard,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const {
        paymentType,
        cardFlagGuid,
        cardFlagName,
        changeMoney,
        paymentAmount,
        document,
        documentType,
        paymentMercadoPagoCard,
      } = values;

      const isCashOrPix =
        paymentType === PaymentType.cash || paymentType === PaymentType.pix;

      if (!payment && paymentType) {
        eventHandler.addPaymentInfo(paymentType);
      }

      const paymentPayload = {
        paymentType,
        cardFlagGuid: !isCashOrPix ? cardFlagGuid : undefined,
        cardFlagName: !isCashOrPix ? cardFlagName : undefined,
        changeMoney: paymentType === PaymentType.cash ? changeMoney : undefined,
        paymentAmount,
        paymentMercadoPagoCard,
      };

      if (paymentType !== PaymentType.mercadoPagoCardPayment) {
        delete paymentPayload.paymentMercadoPagoCard;
      }

      dispatch(setPaymentMethod(paymentPayload));

      dispatch(
        setDelivery({
          ...delivery,
          document,
          documentType,
        })
      );

      if (onSubmitCallback) {
        onSubmitCallback();
      } else {
        onClose();
      }
    },
  });

  const { setFieldValue, handleSubmit } = formik;

  const { isLoading, handleMercadoPagoSubmit } = useMercadoPagoPayment();

  return (
    <>
      <PaymentLinkConfirmationDialog
        open={showPaymentLinkConfirmationDialog}
        formik={formik}
        onClose={handleClosePaymentLinkConfirmationDialog}
        onContinue={handleOpenNeedFiscalDocumentDialog}
      />

      <NeedChangeMoneyDialog
        open={showNeedChangeMoneyDialog}
        onClose={handleCloseNeedChangeMoneyDialog}
        onYes={() => {
          handleCloseNeedChangeMoneyDialog();
          handleOpenChangeMoneyValueDialog();
        }}
        onNo={() => {
          setFieldValue('changeMoney', undefined);
          handleSubmit();
        }}
      />

      <NeedFiscalDocumentDialog
        open={showNeedFiscalDocumentDialog}
        onClose={handleCloseNeedFiscalDocumentDialog}
        onYes={() => {
          handleCloseNeedFiscalDocumentDialog();
          handleOpenDocumentValueDialog();
        }}
        onNo={() => {
          setFieldValue('document', undefined);
          setFieldValue('documentType', undefined);
          handleSubmit();
        }}
      />

      {showChangeMoneyValueDialog && (
        <ChangeMoneyValueDialog
          paymentMethodFormik={formik}
          onClose={handleCloseChangeMoneyValueDialog}
          onContinue={handleOpenNeedFiscalDocumentDialog}
        />
      )}

      {showDocumentValueDialog && (
        <DocumentValueDialog
          paymentMethodFormik={formik}
          onClose={handleCloseDocumentValueDialog}
        />
      )}

      <DialogDrawer
        open
        onBackdropClick={onClose}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          className: classes.paper,
          sx: {
            maxWidth: showMercadoPagoContent ? 742 : 393,
            transition: 'max-width 0.3s ease',
          },
        }}
        breakIn="sm"
        onOpen={onOpen}
        data-testid="PaymentMethodDialog"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{
            padding: '24px 24px 16px',
            paddingTop: isSm ? 0 : 24,
          }}
        >
          {showMercadoPagoContent && (
            <IconButton size="small" onClick={handleCloseMercadoPagoContent}>
              <ArrowBack />
            </IconButton>
          )}
          <Typography
            variant="h1"
            style={{ fontSize: '1.25rem', fontWeight: 700 }}
          >
            Forma de pagamento
          </Typography>

          <Tooltip arrow title="Fechar">
            <IconButton
              data-testid="close-payment-dialog-btn"
              size="small"
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>

        <DialogContent
          data-testid="payment-methods-container"
          className={classes.dialogContent}
        >
          {showMercadoPagoContent ? (
            <MPCardPayment
              amount={orderTotal}
              email={user?.email}
              maxInstallments={
                establishment?.paymentSettings.mercadoPagoCardSettings
                  ?.mercadoPagoCardMaxInstallments || 12
              }
            />
          ) : (
            <PaymentDialogContent
              showOnlinePayments={hasOnlinePayments && orderTotal >= 1}
              hasAutomaticPix={hasAutomaticPix}
              hasPaymentsOnDelivery={!!hasPaymentsOnDelivery}
              paymentSettings={establishment?.paymentSettings}
              formik={formik}
              handleSubmit={(paymentType) => {
                if (paymentType === PaymentType.mercadoPagoCardPayment) {
                  handleOpenMercadoPagoContent();
                  return;
                }

                if (paymentType === PaymentType.mercadoPago) {
                  handleOpenPaymentLinkConfirmationDialog();
                  return;
                }

                if (paymentType === PaymentType.cash) {
                  handleOpenNeedChangeMoneyDialog();
                  return;
                }

                if (establishment?.settings.fiscalDocument) {
                  handleOpenNeedFiscalDocumentDialog();
                  return;
                }

                handleSubmit();
              }}
            />
          )}
        </DialogContent>

        <DialogActions style={{ padding: '16px 24px 24px' }}>
          {showMercadoPagoContent && (
            <LoadingButton
              fullWidth={isSm}
              loading={isLoading}
              variant="contained"
              disableElevation
              onClick={() => {
                handleMercadoPagoSubmit((data) => {
                  setFieldValue('paymentMercadoPagoCard', {
                    token: data.token,
                    installments: data.installments,
                    paymentMethodId: data.payment_method_id,
                    issuerId: data.issuer_id,
                    payer: {
                      email: data.payer.email,
                      identification: data.payer.identification,
                    },
                  });

                  if (establishment?.settings.fiscalDocument) {
                    handleOpenNeedFiscalDocumentDialog();
                    return;
                  }

                  handleSubmit();
                });
              }}
            >
              confirmar
            </LoadingButton>
          )}
        </DialogActions>
      </DialogDrawer>
    </>
  );
};

export default PaymentMethodDialog;
