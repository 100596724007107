import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEventHandler } from '.';
import {
  setDelivery,
  setOutLocalization,
} from '../../redux/reducers/order/reducer';
import { itemsTotalSelector } from '../../redux/reducers/order/selectors';
import { RootState } from '../../redux/types';
import { orderService } from '../../shared/services/order';
import { Address } from '@wls-solucoes/lets-eat-types';

interface UseFeeByDistanceValidationProvider {
  loading: boolean;
  handleValidateFeeByDistance: (
    address: Address,
    callback: () => void,
    setOutLocation?: boolean
  ) => void;
}

export const useFeeByDistanceValidation =
  (): UseFeeByDistanceValidationProvider => {
    const dispatch = useDispatch();
    const { eventHandler } = useEventHandler();

    const { establishment } = useSelector(
      (state: RootState) => state.establishmentReducer
    );
    const { delivery } = useSelector(
      (state: RootState) => state.orderReducer.order
    );

    const subtotal = useSelector(itemsTotalSelector);

    const [loading, setLoading] = useState(false);

    const handleStartLoading = (): void => {
      setLoading(true);
    };

    const handleStopLoading = (): void => {
      setLoading(false);
    };

    const handleValidateFeeByDistance = (
      address: Address,
      callback: () => void,
      setOutLocation: boolean = true
    ): void => {
      const isFreeDelivery =
        establishment?.deliverySettings?.freeDeliveryValue !== undefined &&
        subtotal >= (establishment?.deliverySettings?.freeDeliveryValue ?? 0);

      if (
        establishment?.deliverySettings?.deliveryFeeType &&
        address.coordinates
      ) {
        handleStartLoading();
        const { lat, lng } = address.coordinates;

        orderService
          .checkDeliveryFee(
            establishment?.deliverySettings.deliveryFeeType,
            lat,
            lng,
            establishment.guid
          )
          .then(({ error, fee }) => {
            console.log(error);
            if (!error) {
              dispatch(
                setDelivery({
                  ...delivery,
                  address,
                  fee: !isFreeDelivery ? fee?.fee : undefined,
                  averageTime: fee?.averageTime,
                  neighborhoodFeeGuid: undefined,
                })
              );

              eventHandler.addShippingInfo();

              if (setOutLocation) dispatch(setOutLocalization(false));
            } else {
              if (setOutLocation) dispatch(setOutLocalization(true));
            }
          })
          .finally(() => {
            handleStopLoading();
            callback();
          });
      }
    };

    return {
      loading,
      handleValidateFeeByDistance,
    };
  };
