import { Close, WhatsApp } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Image from 'next/image';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../redux/types';
import { sendWhatsAppMessage } from '../../../../../../../helpers/utils';

interface SendOrderMessageDialogContentProps {
  onClose: () => void;
}

const SendOrderMessageDialogContent: React.FC<
  SendOrderMessageDialogContentProps
> = ({ onClose }) => {
  const theme = useTheme();

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );
  const { orderDetails } = useSelector(
    (state: RootState) => state.orderReducer
  );

  const handleSendOrderMessage = () => {
    if (!establishment || !orderDetails) {
      return;
    }

    const { whatsApp } = establishment.settings;

    sendWhatsAppMessage(whatsApp, orderDetails.whatsAppPrint ?? '');
  };

  return (
    <>
      <Box padding="24px 24px 8px" display="flex" justifyContent="end">
        <Tooltip arrow title="Fechar">
          <IconButton data-testid="close-btn" onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Box>

      <DialogContent>
        <Box mb={1} display="flex" justifyContent="center">
          <Image
            width={165}
            height={140}
            src="/svg/hungry-cat.svg"
            alt="Na imagem, ilustração de um gato branco com listras pretas, sentado, comendo em um pote."
          />
        </Box>

        <Typography
          variant="h6"
          style={{
            fontSize: '1.125rem',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          Teve problemas para enviar seu pedido no WhatsApp?
        </Typography>

        <Typography
          style={{
            color: theme.palette.grey[600],
            textAlign: 'center',
            marginTop: 16,
          }}
        >
          Clique no botão abaixo para enviar o pedido novamente
        </Typography>
      </DialogContent>

      <DialogActions disableSpacing>
        <Button
          disableElevation
          fullWidth
          variant="contained"
          startIcon={<WhatsApp />}
          onClick={handleSendOrderMessage}
        >
          enviar pedido no whatsapp
        </Button>

        <Button fullWidth variant="outlined" onClick={onClose}>
          cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default SendOrderMessageDialogContent;
