import { useEffect, useState } from 'react';

/**
 * A simple hook to check if the user has an internet connection.
 *
 * @returns `true` if the browser has a connection, `false` otherwise.
 */
export const useHasConnection = (): boolean => {
  const [isOnLine, setIsOLine] = useState(window.navigator.onLine);

  useEffect(() => {
    const hasConnection = (): void => setIsOLine(true);
    const isOffline = (): void => setIsOLine(false);

    window.addEventListener('online', hasConnection);
    window.addEventListener('offline', isOffline);

    return () => {
      window.removeEventListener('online', hasConnection);
      window.removeEventListener('offline', isOffline);
    };
  }, []);

  return isOnLine;
};
