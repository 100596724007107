export enum LoyaltyPlanStatus {
  activated = 'activated',
  deactivated = 'deactivated',
}

export interface LoyaltyPlan {
  guid: string;
  status: LoyaltyPlanStatus;
  orderQuantity: number;
  daysToCompleteProgram: number;
  orderMinValue: number;
  couponDiscountValue: number;
  couponDeadLineInDays?: number;
  description?: string;
}
