import { Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CartContent from '../../../CartContent';

const useStyles = makeStyles((theme) => ({}));

interface CartDialogProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const CartDialog: React.FC<CartDialogProps> = ({ open, anchorEl, onClose }) => {
  const classes = useStyles();

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          padding: 2,
          width: 420,
          display: 'flex',

          mt: 1.5,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },

          '& .MuiList-root': {
            width: '100%',
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <CartContent onClose={onClose} />
    </Menu>
  );
};

export default CartDialog;
