import { useEffect, useState } from 'react';

const hasFocus = (): boolean =>
  typeof document !== 'undefined' && document.hasFocus();

/**
 * A hook to check if the user is on the application tab.
 *
 * @returns `true` if the application is on focus, `false` otherwise.
 */
export const useWindowFocus = (): boolean => {
  const [focused, setFocused] = useState(hasFocus);

  useEffect(() => {
    setFocused(hasFocus());

    const onFocus = (): void => setFocused(true);
    const onBlur = (): void => setFocused(false);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return focused;
};
