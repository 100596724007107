import { createAsyncThunk } from '@reduxjs/toolkit';
import { discountCodeService } from '../../../shared/services/discountCode';
import { authService } from '../../../shared/services/authService';
import { publicUserService } from '../../../shared/services/publicUser';

export const getDiscountCodeByCode = createAsyncThunk(
  'discountCode/getByCode',
  async (params: {
    Code: string;
    establishmentGuid: string;
    hideErrorMessage?: boolean;
  }) => {
    const { Code, establishmentGuid, hideErrorMessage } = params;

    const token = authService.getAccessToken();

    const methodToUse = token
      ? publicUserService.getDiscountCode
      : discountCodeService.getDiscountCode;

    return methodToUse(Code, establishmentGuid, hideErrorMessage).catch(
      (err: any) => {
        if (
          err.response?.data?.errorName ===
          'DiscountCodeNeedsPublicUserException'
        ) {
          return {
            needsPublicUserAuth: true,
          };
        }
        if (err.response?.data?.errorName === 'DiscountCodeNotFoundException') {
          return {
            couponNotFound: true,
          };
        }
      }
    );
  }
);
