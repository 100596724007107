import { createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from '../../../shared/services/authService';
import { discountCodeService } from '../../../shared/services/discountCode';
import { publicUserService } from '../../../shared/services/publicUser';

export const getDiscountCode = createAsyncThunk(
  'userDiscountCode/getDiscountCode',
  async (params: {
    establishmentGuid: string;
    pageIndex: number;
    pageSize?: number;
  }) => {
    const { establishmentGuid, pageIndex, pageSize } = params;

    const token = authService.getAccessToken();

    const methodToUse = token
      ? publicUserService.discountCode
      : discountCodeService.getPublicDiscountCode;

    return methodToUse(pageIndex, pageSize ?? 10, establishmentGuid);
  }
);
