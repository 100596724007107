import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/types';

export const useTimeZone = () => {
  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const offset = establishment?.timeZone?.offSet ?? 0;

  const dayjsWithOffset = (
    date: string | number | Date | dayjs.Dayjs | null | undefined
  ) => dayjs(date).add(offset, 'h');

  const correctCurrentTime = () => {
    const dateOffset = dayjs().utcOffset() / 60;

    if (dateOffset === -3) return dayjs().add(offset, 'h');

    return dayjs().utcOffset(-3).add(offset, 'h');
  };

  return { dayjsWithOffset, correctCurrentTime };
};
