import { Login, Person } from '@mui/icons-material';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/types';
import UserAccountDialog from '../UserAccountDialog';

const UserAccountButton: React.FC = () => {
  const { isFetchingData, user } = useSelector(
    (state: RootState) => state.publicUserReducer
  );

  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);

    if (currentAdIndex === 1) {
      setCurrentAdIndex(0);
    } else {
      setCurrentAdIndex(currentAdIndex + 1);
    }
  };

  return (
    <>
      {isFetchingData && !user ? (
        <Skeleton
          data-testid="skeleton-button"
          variant="circular"
          animation="wave"
          width={51}
          height={51}
        />
      ) : (
        <Tooltip arrow title={!user ? 'Entrar' : 'Seu perfil'}>
          <IconButton data-testid="account-button" onClick={handleClick}>
            {!user ? (
              <Login data-testid="login-icon" fontSize="large" />
            ) : (
              <Person data-testid="user-icon" fontSize="large" />
            )}
          </IconButton>
        </Tooltip>
      )}

      <UserAccountDialog
        open={open}
        adIndex={currentAdIndex}
        anchorEl={anchorEl}
        onClose={onClose}
      />
    </>
  );
};

export default UserAccountButton;
