import { createSlice } from '@reduxjs/toolkit';
import { EstablishmentState } from '../../types';
import { fetchEstablishment } from './actions';

const initialState: EstablishmentState = {};

export const establishmentSlice = createSlice({
  name: 'establishment',
  initialState,
  reducers: {
    setEstablishmentData(state, { payload }) {
      state.establishment = payload;
    },
  },
  extraReducers: {
    [fetchEstablishment.fulfilled.type]: (state, { payload }) => {
      state.establishment = payload;
    },
  },
});

export default establishmentSlice.reducer;
export const { setEstablishmentData } = establishmentSlice.actions;
