import {
  Box,
  Button,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Cat from '../modules/assets/Cat';
import { grey } from '@mui/material/colors';
import { Home } from '@mui/icons-material';
import { useRouter } from 'next/router';
import Head from 'next/head';
import GreenLogoAvatar from '../modules/assets/GreenLogoAvatar';
import { useEffect } from 'react';
import { setEstablishmentData } from '../redux/reducers/establishment/reducer';
import { useDispatch } from 'react-redux';
import { Establishment } from '@wls-solucoes/lets-eat-types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 48,
    fontWeight: 600,
    lineHeight: '62,4px',
    marginBottom: 8,
  },
  description: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '27 px',
    color: grey[800],
    marginBottom: 32,
  },
  logo: {
    position: 'absolute',
    bottom: 56,
  },
}));

function Custom404({
  establishmentData,
  subdomainNotFound,
}: {
  subdomainNotFound?: boolean;
  establishmentData?: Establishment;
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useRouter();
  const isSm = useMediaQuery('(max-width:600px)');

  const handleClick = () => {
    push('/');
  };

  useEffect(() => {
    if (establishmentData !== undefined) {
      dispatch(setEstablishmentData(establishmentData));
    }
  }, [establishmentData]); // eslint-disable-line

  return (
    <>
      <Head>
        <title>Ooops!</title>
      </Head>
      <Box className={classes.root}>
        <Grid container className={classes.container}>
          <Grid item>
            <Cat />
          </Grid>
          <Grid
            item
            style={{
              marginLeft: isSm ? 0 : 64,
              maxWidth: 280,
              textAlign: isSm ? 'center' : 'initial',
            }}
          >
            <Typography className={classes.title}>Ooops!</Typography>

            <Typography className={classes.description}>
              A página que você procura não foi encontrada.
            </Typography>

            {!subdomainNotFound && (
              <Button
                data-testid="go-back-button"
                color="success"
                onClick={handleClick}
                style={{ width: 197 }}
                variant="contained"
                disableElevation
                startIcon={<Home />}
              >
                página inicial
              </Button>
            )}
          </Grid>
        </Grid>
        <Box className={classes.logo}>
          <GreenLogoAvatar />
        </Box>
      </Box>
    </>
  );
}

export function getStaticProps() {
  return {
    props: {
      hideHeader: true,
    },
  };
}

export default Custom404;
