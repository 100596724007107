import { City } from '.';

export enum DeliveryFeeType {
  fixedFee = 'fixedFee',
  neighborhoodFee = 'neighborhoodFee',
  mapRoute = 'mapRoute',
  mapStraight = 'mapStraight',
  mapArea = 'mapArea',
}

export enum UnityOfTime {
  minute = 'minute',
  hour = 'hour',
  day = 'day',
}

export interface NeighborhoodFee {
  guid: string;
  status: string;
  fee: number;
  neighborhood: string;
}

export interface CityNeighborhoodFee {
  city: City;
  neighborhoodFees: NeighborhoodFee[];
}

export interface AverageTime {
  unityOfTime?: UnityOfTime;
  from?: number;
  until?: number;
}

export interface OrderSchedulingDeliveryRules {
  enabled: boolean;
  min: number;
  minType: UnityOfTime;
  max: number;
  maxType: UnityOfTime;
}

export interface OrderSchedulingSettings {
  guid: string;
  acceptsOnlyScheduling: boolean;
  deliverySettings: OrderSchedulingDeliveryRules;
  takeAwaySettings: OrderSchedulingDeliveryRules;
}

export interface DeliverySettings {
  enableDelivery: boolean;
  freeDeliveryValue?: number;
  fixedDeliveryFee: number;
  minDeliveryAmount: number;
  enableTakeOut: boolean;
  deliveryFeeType: DeliveryFeeType;
  neighborhoodFees: CityNeighborhoodFee[];
  deliveryAverageTime?: AverageTime;
  takeOutAverageTime?: AverageTime;
  orderSchedulingSettings: OrderSchedulingSettings;
}
