import { createSlice } from '@reduxjs/toolkit';
import { CatalogSession } from '@wls-solucoes/lets-eat-types';

interface SessionState {
  session?: CatalogSession;
}

const initialState: SessionState = {};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, { payload }) => {
      state.session = payload;
    },
  },
});

export default sessionSlice.reducer;
export const { setSession } = sessionSlice.actions;
