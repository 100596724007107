/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { OpeningHoursSettingsState } from '../../types';
import { getOpeningHoursSettingsWithGuid } from './actions';

const initialState: OpeningHoursSettingsState = {};

const openingHoursSettingsSlice = createSlice({
  name: 'openingHoursSettings',
  initialState,
  reducers: {
    setOpeningHoursSettings(state, { payload }) {
      state.openingHoursSettings = payload;
    },
  },
  extraReducers: {
    [getOpeningHoursSettingsWithGuid.fulfilled.type]: (state, { payload }) => {
      state.openingHoursSettings = payload;
    },
  },
});

export default openingHoursSettingsSlice.reducer;
export const { setOpeningHoursSettings } = openingHoursSettingsSlice.actions;
