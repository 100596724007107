import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Pagination,
  Slide,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Discount from '../../../../../../../containers/DiscountContainer/components/Discount';
import DiscountBanner from '../../../../../../../containers/DiscountContainer/components/DiscountBanner';
import DiscountSkeleton from '../../../../../../../containers/DiscountContainer/components/DiscountSkeleton';
import CouponWithRequiredAuthAlert from '../../../../../CouponWithRequiredAuthAlert';
import NoCoupon from './components/NoCoupon';
import NoCouponFound from './components/NoCouponFound';
import useCouponCode from './hooks/useCouponCode';
import useCouponsList from './hooks/useCouponsList';

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderRadius: '0.5rem',
    maxWidth: 392,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      minHeight: '100%',
      minWidth: '100%',
    },
  },

  dialogTitle: {
    padding: '32px 32px 24px 32px',
  },

  noCouponButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: 16,
    borderRadius: 4,
    border: '1px solid #969C98',
    fontSize: '1rem',
    fontWeight: 600,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CouponDialogProps {
  onClose: () => void;
}

const CouponDialog: React.FC<CouponDialogProps> = ({ onClose }) => {
  const classes = useStyles();

  const [inputParams, controller] = useCouponCode({ onClose });
  const { submit, handleOpenCouponWithRequiredAuth, isSubmitting } = controller;

  const [coupons, couponsController] = useCouponsList();
  const {
    selectedCouponGuid,
    setNewSelectedCoupon,
    submitANewCoupon,
    pagination,
    isLoading,
  } = couponsController;

  return (
    <>
      <Dialog
        open
        data-testid="coupon-dialog"
        onBackdropClick={onClose}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{ className: classes.paper }}
      >
        <Box className={classes.dialogTitle}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography style={{ fontSize: '1.125rem', fontWeight: 600 }}>
              Adicionar cupom
            </Typography>

            <Tooltip arrow title="Fechar">
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Tooltip>
          </Box>

          <Box mt={2} display="flex" gap="8px" alignItems="start">
            <TextField
              variant="outlined"
              label="Código do cupom"
              name="discountCode"
              {...inputParams}
            />
            <LoadingButton
              variant="text"
              onClick={submit}
              style={{ height: '56px' }}
              loading={isSubmitting}
            >
              aplicar
            </LoadingButton>
          </Box>
        </Box>

        <Divider />

        <DialogContent
          style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
        >
          {isLoading ? (
            <DiscountSkeleton />
          ) : coupons.length ? (
            <>
              <DiscountBanner smallVariant text="Temos cupons disponíveis" />
              <NoCoupon
                selected={selectedCouponGuid === ''}
                onSelect={() => setNewSelectedCoupon('')}
              />
            </>
          ) : (
            <NoCouponFound />
          )}

          {!isLoading &&
            coupons.map((coupon) => {
              return (
                <Discount
                  key={coupon.guid}
                  title={coupon.title}
                  selected={coupon.guid === selectedCouponGuid}
                  onSelect={() => setNewSelectedCoupon(coupon.guid ?? '')}
                  minimumOrderValue={coupon.minimumOrderValue ?? 0}
                  restrictions={coupon.restrictions}
                  endDate={coupon.endDate as string}
                  disabled={!!coupon.disabled}
                  disclaimer={
                    coupon.disclaimer && {
                      text: coupon.disclaimer.text,
                      variant: coupon.disclaimer.variant,
                    }
                  }
                />
              );
            })}

          {pagination.totalPages > 1 ? (
            <Pagination
              data-testid="coupon-dialog-pagination"
              count={pagination.totalPages}
              page={pagination.currentPage}
              onChange={(_, page) => pagination.goToPage(page)}
              color="primary"
              style={{ margin: '0 auto' }}
            />
          ) : (
            <></>
          )}
        </DialogContent>

        <DialogActions style={{ padding: '24px 32px 32px 32px' }}>
          <Button
            style={{ height: 40 }}
            data-testid="coupon-dialog-button"
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            onClick={() =>
              submitANewCoupon(onClose, handleOpenCouponWithRequiredAuth)
            }
          >
            salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CouponDialog;
