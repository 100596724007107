import { useSelector } from 'react-redux';
import { fbEvent } from '../../lib/fbpixel';
import { gaEvent } from '../../lib/ga';
import {
  itemsTotalSelector,
  orderCheckoutTotalSelector,
} from '../../redux/reducers/order/selectors';
import { RootState } from '../../redux/types';
import { GAItem } from '../../shared/models';
import { checkoutItemsToGAItems } from '../utils';

interface ItemOptions {
  value: number;
  item: GAItem;
}

interface UseEventHandlerProvider {
  eventHandler: {
    addPaymentInfo: (payment_type: string) => void;
    addShippingInfo: () => void;
    addToCart: (options: ItemOptions) => void;
    initiateCheckout: () => void;
    purchase: (transaction_id: string) => void;
    removeFromCart: (options: ItemOptions) => void;
    selectPromotion: (item: GAItem) => void;
    search: (search_term: string) => void;
    viewCart: () => void;
    viewItem: (options: ItemOptions) => void;
  };
}

export const useEventHandler = (): UseEventHandlerProvider => {
  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );
  const { items, delivery, discountCode } = useSelector(
    (state: RootState) => state.orderReducer.order
  );

  const orderTotal = useSelector(orderCheckoutTotalSelector);
  const itemsTotal = useSelector(itemsTotalSelector);

  const defaultOptions = {
    establishment_guid: establishment?.guid,
    currency: 'BRL',
    value: orderTotal,
    items: checkoutItemsToGAItems(items ?? []),
  };

  function addPaymentInfo(payment_type: string) {
    fbEvent('AddPaymentInfo');
    gaEvent('add_payment_info', {
      ...defaultOptions,
      payment_type,
    });
  }

  function addShippingInfo() {
    gaEvent('add_shipping_info', {
      ...defaultOptions,
      value: delivery?.fee ?? 0,
    });
  }

  function addToCart(options: ItemOptions) {
    const { value, item } = options;

    fbEvent('AddToCart');
    gaEvent('add_to_cart', {
      ...defaultOptions,
      value,
      items: [item],
    });
  }

  function initiateCheckout() {
    fbEvent('InitiateCheckout');
    gaEvent('begin_checkout', {
      ...defaultOptions,
      coupon: discountCode,
    });
  }

  function purchase(transaction_id: string) {
    fbEvent('Purchase', { currency: 'BRL', value: orderTotal });
    gaEvent('purchase', {
      ...defaultOptions,
      transaction_id,
      value: itemsTotal,
      shipping: delivery?.fee ?? 0,
    });
  }

  function removeFromCart(options: ItemOptions) {
    const { value, item } = options;

    gaEvent('remove_from_cart', {
      ...defaultOptions,
      value,
      items: [item],
    });
  }

  function selectPromotion(item: GAItem) {
    gaEvent('select_promotion', {
      establishment_guid: establishment?.guid,
      items: [item],
    });
  }

  function search(search_term: string) {
    gaEvent('search', {
      establishment_guid: establishment?.guid,
      search_term,
    });
  }

  function viewCart() {
    gaEvent('view_item', defaultOptions);
  }

  function viewItem(options: ItemOptions) {
    const { value, item } = options;

    gaEvent('view_item', {
      ...defaultOptions,
      value,
      items: [item],
    });
  }

  const eventHandler = {
    addPaymentInfo,
    addShippingInfo,
    addToCart,
    initiateCheckout,
    purchase,
    removeFromCart,
    selectPromotion,
    search,
    viewCart,
    viewItem,
  };

  return { eventHandler };
};
