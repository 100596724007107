import { CheckCircleRounded } from '@mui/icons-material';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

type CheckedIconProps = {
  selected: boolean;
};

const CheckedIcon: React.FC<CheckedIconProps> = ({ selected }) => {
  return (
    <AnimatePresence>
      {selected && (
        <motion.div
          style={{
            position: 'absolute',
            top: 16,
            right: 16,
          }}
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
          }}
          exit={{
            scale: 0,
          }}
        >
          <CheckCircleRounded data-testid="checked-icon" color="primary" />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CheckedIcon;
