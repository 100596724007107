import { TypeOption } from '@wls-solucoes/lets-eat-types';
import { useState } from 'react';
import { OptionalErrors } from '../../shared/components/ItemOptionals/ItemOptionals';
import { ItemCheckout, ItemOptionalCheckout } from '../../shared/models';

interface UsePromoCalcsProvider {
  optionalErrors: OptionalErrors[];
  handleChangeOptionalErrors: (optionalErrorsArr: OptionalErrors[]) => void;
  getOptionalsValidationError: (
    optionals: ItemOptionalCheckout[]
  ) => string | undefined;
  handleOptionalValidation: (
    item: ItemCheckout,
    opIndex: number,
    selIndex: number,
    newValue: number
  ) => void;
}

export const useOptionalValidation = (): UsePromoCalcsProvider => {
  const [optionalErrors, setOptionalErrors] = useState<OptionalErrors[]>([]);

  const handleChangeOptionalErrors = (
    optionalErrorsArr: OptionalErrors[]
  ): void => {
    setOptionalErrors(optionalErrorsArr);
  };

  const getOptionalsValidationError = (
    optionals: ItemOptionalCheckout[]
  ): string | undefined => {
    for (let i = 0; i < optionals.length; i++) {
      const optional = optionals[i];

      if (
        optional.type === TypeOption.selector ||
        optional.type === TypeOption.pizza ||
        optional.type === TypeOption.dough ||
        optional.type === TypeOption.edge ||
        optional.type === TypeOption.size
      ) {
        const { optionsMin, optionsMax, options } = optional;

        const optionsTotal = (options ?? []).reduce(
          (prev: number, op: any) => prev + op.quantity,
          0
        );

        if (
          optionsTotal < (optionsMin ?? 0) ||
          optionsTotal > (optionsMax ?? 1)
        ) {
          return `Preencha o opcional "${optional.name}"  corretamente antes de continuar.`;
        }
      }
    }
    return undefined;
  };

  const validateOptional = (optional: ItemOptionalCheckout): boolean => {
    const { optionsMin, optionsMax, options } = optional;

    const optionsTotal = (options ?? []).reduce(
      (prev: number, op: any) => prev + (op.quantity ?? 0),
      0
    );

    if (optionsTotal < (optionsMin ?? 0) || optionsTotal > (optionsMax ?? 1)) {
      return false;
    }

    return true;
  };

  const handleOptionalValidation = (
    item: ItemCheckout,
    opIndex: number,
    selIndex: number,
    newValue: number
  ): void => {
    const optionalErrorsArr = [...optionalErrors];

    const optional = item.optionals[opIndex];

    const index = optionalErrorsArr.findIndex((x) => x.guid === optional.guid);

    const toValidate = item.optionals[opIndex];
    (toValidate.options ?? [])[selIndex].quantity = newValue;

    optionalErrorsArr[index].isValid = validateOptional(toValidate);

    if (!optionalErrorsArr[index].isTouched) {
      optionalErrorsArr[index].isTouched = true;
    }

    setOptionalErrors(optionalErrorsArr);
  };

  return {
    optionalErrors,
    getOptionalsValidationError,
    handleChangeOptionalErrors,
    handleOptionalValidation,
  };
};
