import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import activeOrdersReducer from './activeOrders/reducer';
import carouselReducer from './carousel/reducer';
import catalogReducer from './catalog/reducer';
import discountCodeReducer from './discountCode/reducer';
import establishmentReducer from './establishment/reducer';
import localAddressesReducer from './localAddresses/reducer';
import loyaltyPlanReducer from './loyaltyPlan/reducer';
import openingHoursSettingsReducer from './openingHoursSettings/reducer';
import orderReducer from './order/reducer';
import progressIndicatorReducer from './progressIndicator/reducer';
import publicUserReducer from './publicUser/reducer';
import recommendationReducer from './recommendation/reducer';
import searchReducer from './search/reducer';
import sessionReducer from './session/reducer';
import userDiscountReducer from './userDiscountCode/reducer';
import userInfoReducer from './userInfo/reducer';

const persistConfig = {
  key: 'root_v3',
  storage,
  whitelist: [
    'userInfoReducer',
    'localAddressesReducer',
    'activeOrdersReducer',
    'publicUserReducer',
  ],
};

const rootReducer = combineReducers({
  loyaltyPlanReducer,
  progressIndicatorReducer,
  catalogReducer,
  userDiscountReducer,
  establishmentReducer,
  discountCodeReducer,
  localAddressesReducer,
  openingHoursSettingsReducer,
  orderReducer,
  recommendationReducer,
  searchReducer,
  userInfoReducer,
  carouselReducer,
  activeOrdersReducer,
  publicUserReducer,
  sessionReducer,
});

export default persistReducer(persistConfig, rootReducer);
