import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPublicUserAddresses } from '../../../redux/reducers/publicUser/actions';
import {
  setIsFetchingUserData,
  setPublicUserInfo,
} from '../../../redux/reducers/publicUser/reducer';
import { RootState } from '../../../redux/types';
import { authService } from '../../services/authService';
import { publicUserService } from '../../services/publicUser';

const FetchUserData: React.FC = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.publicUserReducer);

  const token = authService.getAccessToken();

  useEffect(() => {
    if (token && !user) {
      dispatch(setIsFetchingUserData(true));

      publicUserService
        .signIn()
        .then((res) => {
          authService.setAccessToken(res.token ?? '');

          dispatch(setPublicUserInfo(res.person));
          dispatch(setIsFetchingUserData(false));
        })
        .catch(() => {
          authService.removeAccessToken();

          dispatch(setIsFetchingUserData(false));
        });
    } else if (user) {
      dispatch(fetchPublicUserAddresses());
    }
  }, []);

  return null;
};

export default FetchUserData;
