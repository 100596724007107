/**
 * A simple helper to facilitate the use of time strings without seconds.
 * 
 * @param time string in format HH:mm:ss to remove seconds.
 * @return string in format HH:mm.
 */
export const getTimeWithoutSeconds = (time: string): string => {
    const timeWithoutSeconds = `${time.split(':')[0]}:${time.split(':')[1]}`;

    return timeWithoutSeconds;
};