import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slide,
  Theme,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import { FormikProps } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { getPaymentTypeProps } from '../../../../../../../../helpers/utils';
import { RootState } from '../../../../../../../../redux/types';
import DoneIcon from '../../../../../../../../shared/components/DoneIcon';

const descriptions = {
  shipay: (
    <>
      Após <strong>Fazer o pedido</strong>, o link de pagamento pix será exibido
      e você terá 5 minutos para realizar o pagamento.
    </>
  ),
  mercadoPago:
    'Após enviar o pedido será necessário realizar o pagamento no Mercado Pago para garantir que o mesmo seja efetuado. Lembre-se de realizar o pagamento o quanto antes para evitar que seu pedido seja cancelado.',
};

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    width: 390,
    padding: 32,
  },

  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    border: `2px solid ${theme.palette.primary.main}`,
  },

  textContainer: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    padding: 24,
    borderRadius: 4,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface PaymentLinkConfirmationDialogProps {
  open: boolean;
  formik: FormikProps<any>;
  onClose: () => void;
  onContinue: () => void;
}

const PaymentLinkConfirmationDialog: React.FC<
  PaymentLinkConfirmationDialogProps
> = ({ open, formik, onClose, onContinue }) => {
  const classes = useStyles();

  const { values, handleSubmit } = formik;

  const { icon, name } = getPaymentTypeProps(values.paymentType);

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const handleConfirmClick = () => {
    if (establishment?.settings.fiscalDocument) {
      onContinue();
    } else {
      handleSubmit();
    }

    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{ className: classes.paper }}
    >
      <Box mb={3} display="flex" justifyContent="space-between">
        <Typography style={{ fontSize: '1.125rem', fontWeight: 700 }}>
          Link de pagamento
        </Typography>

        <Tooltip title="Fechar">
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Box>

      <DialogContent style={{ padding: 0 }}>
        <Card variant="outlined" className={classes.card}>
          <Box display="flex">
            {icon}

            <Typography style={{ marginLeft: 16 }}>{name}</Typography>
          </Box>

          <DoneIcon />
        </Card>

        <Box mt={2} className={classes.textContainer}>
          <Typography>
            {descriptions[values.paymentType as keyof typeof descriptions]}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions style={{ padding: '24px 0 0' }}>
        <Button
          data-testid="confirm-payment-link-button"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleConfirmClick}
        >
          {establishment?.settings.fiscalDocument ? 'continuar' : 'salvar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentLinkConfirmationDialog;
