import { ShoppingCart } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEventHandler } from '../../../../../helpers/hooks';
import { RootState } from '../../../../../redux/types';
import CartDialog from '../CartDialog';

const CartButton: React.FC = () => {
  const { eventHandler } = useEventHandler();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);

    eventHandler.viewCart();
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const { items } = useSelector((state: RootState) => state.orderReducer.order);

  return (
    <>
      <IconButton onClick={handleClick} data-testid="CartButton-buttom">
        {items && items.length > 0 ? (
          <Badge badgeContent={items.length} color="secondary">
            <ShoppingCart fontSize="large" />
          </Badge>
        ) : (
          <ShoppingCart fontSize="large" />
        )}
      </IconButton>

      <CartDialog open={open} anchorEl={anchorEl} onClose={onClose} />
    </>
  );
};

export default CartButton;
