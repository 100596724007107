import { createSlice } from '@reduxjs/toolkit';
import { CatalogState } from '../../types';

const initialState: CatalogState = {};

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setCatalog(state, { payload }) {
      state.catalog = payload;
    },

    setItemDetails(state, { payload }) {
      state.itemDetails = payload;
    },

    setItemToEditInfo(state, { payload }) {
      state.itemToEditInfo = payload;
    },

    setIsLoadingItemDetails(state, { payload }) {
      state.isLoadingItemDetails = payload;
    },

    setRecommendationGuid(state, { payload }) {
      state.recommendationGuid = payload;
    },

    setItemRecommendationData(state, { payload }) {
      state.itemRecommendationData = payload;
    },

    changeExpandedCategory(state, { payload }) {
      const { guid, value } = payload;

      if (state.catalog) {
        const index = state.catalog.findIndex(
          (category) => category.guid === guid
        );

        state.catalog[index].expanded = value;
      }
    },

    removeRecommendationGuid(state) {
      state.recommendationGuid = undefined;
    },

    removeItemDetails(state) {
      state.itemDetails = undefined;
    },

    removeItemToEditInfo(state) {
      state.itemToEditInfo = undefined;
    },
  },
});

export default catalogSlice.reducer;
export const {
  setCatalog,
  setItemDetails,
  setItemToEditInfo,
  setIsLoadingItemDetails,
  setRecommendationGuid,
  setItemRecommendationData,
  changeExpandedCategory,
  removeItemDetails,
  removeItemToEditInfo,
  removeRecommendationGuid,
} = catalogSlice.actions;
