import { createSlice } from '@reduxjs/toolkit';
import { OrderSummary } from '../../../shared/models';
import { authService } from '../../../shared/services/authService';
import { ActiveOrdersState } from '../../types';
import { fetchActiveOrdersInfo } from './actions';

const initialState: ActiveOrdersState = {
  isFetchingData: false,
  activeOrdersInfo: [],
  activeOrders: [],
};

export const activeOrdersSlice = createSlice({
  name: 'activeOrders',
  initialState,
  reducers: {
    addActiveOrderGuid: (state, { payload }) => {
      state.activeOrders.push(payload);
    },
  },
  extraReducers: {
    [fetchActiveOrdersInfo.pending.type]: (state) => {
      state.isFetchingData = true;
    },
    [fetchActiveOrdersInfo.fulfilled.type]: (state, { payload }) => {
      const userIsLogged = authService.getAccessToken();

      if (userIsLogged) {
        state.loggedUserOrders = payload;
      } else {
        state.activeOrdersInfo = payload;

        state.activeOrders = payload.map((order: OrderSummary) => order.guid);
      }

      state.isFetchingData = false;
    },
    [fetchActiveOrdersInfo.rejected.type]: (state) => {
      state.isFetchingData = false;
    },
  },
});

export default activeOrdersSlice.reducer;
export const { addActiveOrderGuid } = activeOrdersSlice.actions;
