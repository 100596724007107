import React from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';

const PaymentMethodsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
}));

interface PaymentSectionContainerProps {
  icon: JSX.Element;
  title: string;
  children?: React.ReactNode;
  testId?: string;
}

const PaymentSectionContainer: React.FC<PaymentSectionContainerProps> = ({
  icon,
  title,
  children,
  testId,
}) => {
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        {icon}
        <Typography
          variant="h1"
          style={{ fontSize: '1.125rem', fontWeight: 700 }}
        >
          {title}
        </Typography>
      </Stack>
      <PaymentMethodsContainer data-testid={testId} mt={2}>
        {children}
      </PaymentMethodsContainer>
    </Box>
  );
};

export default PaymentSectionContainer;
