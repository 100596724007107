import {
  CatalogCategory,
  CategoryType,
  Product,
} from '@wls-solucoes/lets-eat-types';
import { AxiosResponse } from 'axios';
import { Establishment } from '../models';
import Api from './api';

const baseUrl = '/api/establishment';

function getEstablishmentByPath(
  path: string
): Promise<AxiosResponse<Establishment>> {
  return Api.get(`${baseUrl}/${path}`, {
    headers: {
      'x-api-version': '2',
    },
  })
    .then((r) => r.data)
    .catch((r) => {
      console.error(r);
    });
}

function getEstablishmentCatalog(
  establishmentGuid: string
): Promise<CatalogCategory[]> {
  return Api.get(`${baseUrl}/${establishmentGuid}/catalog`, {
    headers: {
      'x-api-version': '3',
    },
  }).then((r) => r.data);
}

function getItemDetails(
  establishmentGuid: string,
  itemGuid: string,
  categoryType?: CategoryType
): Promise<Product> {
  return Api.get(`${baseUrl}/${establishmentGuid}/${itemGuid}`, {
    params: {
      categoryType,
    },
    headers: {
      hideErrorMessage: 'true',
      'x-api-version': '3',
    },
  }).then((r) => r.data);
}

export const establishmentService = {
  getEstablishmentByPath,
  getEstablishmentCatalog,
  getItemDetails,
};
