import create from 'zustand';

export interface HistoryStoreProps {
  history: string[];
  addRoute: (route: string) => void;
}

export const useHistoryStore = create<HistoryStoreProps>((set) => ({
  history: [],
  addRoute: (route) => set((state) => ({ history: [...state.history, route] })),
}));
