interface UsePromoCalcsProvider {
  calculatePromoDiscountCash: (priceValue: number, discountPercentage?: number,
    promotionalValue?: number, ) => number | undefined;
  calculatePromoDiscountPercentage: (priceValue: number, discountCash?: number, ) => number;
  calculatePromoValue: ( priceValue: number, discountPercentage?: number,
    discountCash?: number,) => number | undefined;
}

export const usePromoCalcs = (): UsePromoCalcsProvider => {
  const calculatePromoDiscountCash = (
    priceValue: number,
    discountPercentage?: number,
    promotionalValue?: number,
  ): number | undefined => {
    if (discountPercentage === undefined && promotionalValue === undefined) {
      return undefined;
    }

    return Number(
      (discountPercentage !== undefined
        ? discountPercentage * priceValue
        : priceValue - Number(promotionalValue)
      ).toFixed(2)
    );
  };

  const calculatePromoDiscountPercentage = (priceValue: number, discountCash?: number, ): number => {
    return Number(
      (Number(discountCash) / priceValue).toFixed(
        4
      )
    );
  };

  const calculatePromoValue = (
    priceValue: number,
    discountPercentage?: number,
    discountCash?: number,
  ): number | undefined => {
    if (discountPercentage === undefined && discountCash === undefined) {
      return undefined;
    }

    return Number(
      (discountPercentage !== undefined
        ? priceValue -
          Number(calculatePromoDiscountCash(priceValue, discountPercentage / 100))
        : priceValue - Number(discountCash)
      ).toFixed(2)
    );
  };

  return {
    calculatePromoDiscountCash,
    calculatePromoDiscountPercentage,
    calculatePromoValue
  }
};