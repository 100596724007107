import { MutableRefObject, useEffect, useState } from 'react';

interface useScrollTriggerProps {
  target: MutableRefObject<any>;
  marginTop?: number;
  marginBottom?: number;
  defaultValue?: boolean;
  disabled?: boolean;
}

export const useScrollTrigger = ({
  target,
  defaultValue,
  marginBottom,
  marginTop,
  disabled,
}: useScrollTriggerProps): any => {
  const [returnValue, setReturnValue] = useState(defaultValue ?? false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (
        window.scrollY >
        target.current?.offsetTop +
          (marginBottom ? 64 : 0) -
          (marginTop ? 64 : 0)
      ) {
        setReturnValue(true);
      } else {
        setReturnValue(false);
      }
    });
    return () => {
      window.removeEventListener('scroll', () => {
        setReturnValue(false);
      });
    };
  }, [target.current]);

  if (disabled) {
    return defaultValue ?? false;
  }

  if (!target.current) {
    return defaultValue ?? false;
  }

  return returnValue;
};
