import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import { FormikProps, useFormik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { toCurrencyValue } from '../../../../../../../../helpers/utils';
import { orderCheckoutTotalSelector } from '../../../../../../../../redux/reducers/order/selectors';
import { RootState } from '../../../../../../../../redux/types';
import CurrencyInput from '../../../../../../../../shared/components/CurrencyInput';

const useStyles = makeStyles(() => ({
  paper: {
    width: 390,
    padding: 32,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ChangeMoneyValueDialogProps {
  paymentMethodFormik: FormikProps<any>;
  onClose: () => void;
  onContinue: () => void;
}

const ChangeMoneyValueDialog: React.FC<ChangeMoneyValueDialogProps> = ({
  paymentMethodFormik,
  onClose,
  onContinue,
}) => {
  const classes = useStyles();

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const orderTotal = useSelector(orderCheckoutTotalSelector);

  const validationSchema = Yup.object().shape({
    changeMoney: Yup.number()
      .max(999999999999, 'Máximo de 12 dígitos')
      .test(
        'changeMoneyTest',
        `Precisa ser maior que ${toCurrencyValue(orderTotal ?? 0)}`,
        (value) => {
          if (value !== undefined && value !== null && value < orderTotal) {
            return false;
          }

          return true;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      changeMoney: paymentMethodFormik.values.changeMoney,
    },
    validationSchema,
    onSubmit: (values) => {
      paymentMethodFormik.setFieldValue('changeMoney', values.changeMoney);

      if (establishment?.settings.fiscalDocument) {
        onContinue();
      } else {
        paymentMethodFormik.handleSubmit();
      }

      onClose();
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <Dialog
      open
      onBackdropClick={onClose}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{ className: classes.paper }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ paddingBottom: 24 }}
      >
        <Typography style={{ fontSize: '1.125rem', fontWeight: 600 }}>
          Troco para quanto?
        </Typography>
      </Box>

      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          padding: 0,
        }}
      >
        <Typography>
          Seu pedido deu {toCurrencyValue(orderTotal ?? 0)}
        </Typography>

        <CurrencyInput
          fullWidth
          disableDefaultMargin
          variant="outlined"
          name="changeMoney"
          label="Troco para"
          value={values.changeMoney}
          handleChange={handleChange}
          handleBlur={handleBlur}
          helperText={
            errors.changeMoney && touched.changeMoney && errors.changeMoney
          }
          error={touched.changeMoney && errors.changeMoney !== undefined}
          InputLabelProps={{
            shrink:
              values.changeMoney !== undefined && values.changeMoney !== null,
          }}
        />
      </DialogContent>

      <DialogActions style={{ paddingTop: 24 }}>
        <Button
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          {establishment?.settings.fiscalDocument ? 'continuar' : 'salvar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeMoneyValueDialog;
