import { createAsyncThunk } from '@reduxjs/toolkit';
import { openingHoursSettingsService } from '../../../shared/services/openingHoursSettings';

export const getOpeningHoursSettingsWithGuid = createAsyncThunk(
  'openingHoursSettings/get',
  async (establishmentGuid: string) => {
    return openingHoursSettingsService.getOpeningHoursSettingsWithGuid(
      establishmentGuid
    );
  }
);
