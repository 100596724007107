import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import Coupon from '../../../../modules/assets/Coupon';
interface useStylesProps {
  isSmall: boolean;
}

const useStyles = makeStyles<Theme, useStylesProps>((theme) => ({
  root: {
    width: '100%',
    height: ({ isSmall }) => (isSmall ? '4.8125rem' : '7.625rem'),
    background: '#E4E6E580',
    borderRadius: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  coupon: {
    position: ({ isSmall }) => (isSmall ? 'relative' : 'absolute'),
    left: ({ isSmall }) => (isSmall ? 12.39 : 40),
    height: ({ isSmall }) => (isSmall ? '100%' : 'auto'),
  },
  title: {
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '130%',
  },
  titleSmall: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '150%',
    margin: '0 auto',
    maxWidth: '14rem',
    width: '100%',
    padding: '0 1rem',
  },
}));

type DiscountBannerProps = {
  smallVariant?: boolean;
  text?: string;
};

const DiscountBanner: React.FC<DiscountBannerProps> = ({
  text,
  smallVariant,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmall = smallVariant || isSm;
  const classes = useStyles({ isSmall });

  return (
    <Box
      className={classes.root}
      style={
        smallVariant
          ? {
              height: '3.625rem',
              minHeight: '3.625rem',
              overflow: 'hidden',
            }
          : {}
      }
    >
      <Coupon
        className={classes.coupon}
        style={
          smallVariant
            ? {
                height: '140%',
                top: '10px',
              }
            : {}
        }
      />
      <Typography
        variant="h6"
        className={`${classes.title} ${isSmall && classes.titleSmall}`}
        data-testid="discount-banner-text"
      >
        {text ?? (
          <>
            Esta loja está com <br />
            cupons disponíveis, aproveite!
          </>
        )}
      </Typography>
    </Box>
  );
};

export default DiscountBanner;
