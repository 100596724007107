import React, { useRef, useState } from 'react';
import Swal from 'sweetalert2';
import SelectAddressDialog from '../../containers/CheckoutContainer/components/DeliverySection/components/DeliveryTab/components/SelectAddressDialog';
import DeliverySchedulingDialogForm from '../../containers/CheckoutContainer/components/DeliverySection/components/DeliveryTimingTypeSection/components/DeliverySchedulingDialogForm';
import UserInformationDialog from '../../containers/CheckoutContainer/components/InformationSection/components/UserInformationDialog';
import PaymentMethodDialog from '../../containers/CheckoutContainer/components/PaymentSection/components/PaymentMethodDialog';
import MinDeliveryAmountAlert from '../../shared/components/MinDeliveryAmountAlert';
import { OrderCheckoutErrors } from '../../shared/models';

type UseHandleOrderErrorActionsProvider = {
  handleOrderError: (error: string, submitButton: HTMLButtonElement) => void;
  ActionDialogs: React.FC;
};

export const useHandleOrderErrorActions =
  (): UseHandleOrderErrorActionsProvider => {
    const [showSelectAddressDialogForm, setShowSelectAddressDialogForm] =
      useState(false);
    const [showPaymentMethodDialog, setShowPaymentMethodDialog] =
      useState(false);
    const [showUserInformationDialog, setShowUserInformationDialog] =
      useState(false);
    const [showMinDeliveryAmountAlert, setShowMinDeliveryAmountAlert] =
      useState(false);
    const [
      showDeliverySchedulingDialogForm,
      setShowDeliverySchedulingDialogForm,
    ] = useState(false);

    const handleOpenDeliverySchedulingDialogForm = () => {
      setShowDeliverySchedulingDialogForm(true);
    };

    const handleCloseDeliverySchedulingDialogForm = () => {
      setShowDeliverySchedulingDialogForm(false);
    };

    const submitButtonRef = useRef<HTMLButtonElement>();

    const handleOpenSelectAddressDialogForm = (): void => {
      setShowSelectAddressDialogForm(true);
    };

    const handleCloseSelectAddressDialogForm = (): void => {
      setShowSelectAddressDialogForm(false);

      Swal.fire({
        title: 'Alerta!',
        text: OrderCheckoutErrors.missingAddress,
        icon: 'warning',
      });
    };

    const handleOpenPaymentMethodDialog = (): void => {
      setShowPaymentMethodDialog(true);
    };

    const handleClosePaymentMethodDialog = (): void => {
      setShowPaymentMethodDialog(false);

      Swal.fire({
        title: 'Alerta!',
        text: OrderCheckoutErrors.missingPaymentType,
        icon: 'warning',
      });
    };

    const handleOpenUserInformationDialog = (): void => {
      setShowUserInformationDialog(true);
    };

    const handleCloseUserInformationDialog = (): void => {
      setShowUserInformationDialog(false);

      Swal.fire({
        title: 'Alerta!',
        text: 'Nome e/ou telefone não preenchidos.',
        icon: 'warning',
      });
    };

    const handleOpenMinDeliveryAmountAlert = (): void => {
      setShowMinDeliveryAmountAlert(true);
    };

    const handleCloseMinDeliveryAmountAlert = (): void => {
      setShowMinDeliveryAmountAlert(false);
    };

    const handleOrderError = (
      error: string,
      submitButton: HTMLButtonElement
    ): void => {
      submitButtonRef.current = submitButton;

      if (
        error.match(/^Esta loja não realiza entregas para pedidos abaixo de/)
      ) {
        handleOpenMinDeliveryAmountAlert();
      } else {
        switch (error) {
          case OrderCheckoutErrors.missingAddress:
            handleOpenSelectAddressDialogForm();
            break;
          case OrderCheckoutErrors.missingPaymentType:
            handleOpenPaymentMethodDialog();
            break;
          case OrderCheckoutErrors.missingBuyerName:
            handleOpenUserInformationDialog();
            break;
          case OrderCheckoutErrors.missingBuyerPhone:
            handleOpenUserInformationDialog();
            break;
          case OrderCheckoutErrors.missingScheduleDate:
            handleOpenDeliverySchedulingDialogForm();
            break;
          default:
            break;
        }
      }
    };

    const ActionDialogs: React.FC = () => (
      <>
        <MinDeliveryAmountAlert
          open={showMinDeliveryAmountAlert}
          onClose={handleCloseMinDeliveryAmountAlert}
        />

        {showSelectAddressDialogForm && (
          <SelectAddressDialog
            onClose={handleCloseSelectAddressDialogForm}
            onSubmitCallback={() => {
              if (submitButtonRef.current) {
                submitButtonRef.current.click();
              }

              setShowSelectAddressDialogForm(false);
            }}
          />
        )}

        {showPaymentMethodDialog && (
          <PaymentMethodDialog
            onClose={handleClosePaymentMethodDialog}
            onSubmitCallback={() => {
              if (submitButtonRef.current) {
                submitButtonRef.current.click();
              }

              setShowPaymentMethodDialog(false);
            }}
          />
        )}

        {showUserInformationDialog && (
          <UserInformationDialog
            onClose={handleCloseUserInformationDialog}
            onSubmitCallback={() => {
              if (submitButtonRef.current) {
                submitButtonRef.current.click();
              }

              setShowUserInformationDialog(false);
            }}
          />
        )}

        {showDeliverySchedulingDialogForm && (
          <DeliverySchedulingDialogForm
            onClose={handleCloseDeliverySchedulingDialogForm}
            onSubmitCallback={() => {
              if (submitButtonRef.current) {
                submitButtonRef.current.click();
              }

              setShowDeliverySchedulingDialogForm(false);
            }}
          />
        )}
      </>
    );

    return {
      handleOrderError,
      ActionDialogs,
    };
  };
