import { OrderCheckout } from '@wls-solucoes/lets-eat-types';
import {
  AddressRequestForm,
  Coordinates,
  DiscountValueType,
  DocumentType,
  StatusType,
  TypeOption,
} from '.';
import { Address } from './address';

export enum OrderStatus {
  pending = 'pending',
  accepted = 'accepted',
  finished = 'finished',
  canceled = 'canceled',
  delivered = 'delivered',
  takeout = 'takeout',
  preparing = 'preparing',
}

export enum OrderTimingType {
  immediate = 'immediate',
  scheduled = 'scheduled',
}

export interface OrderStatusHistory {
  previousStatus: OrderStatus;
  status: OrderStatus;
  startDate: Date;
  endDate?: Date;
  description?: string;
}

export interface OrderOption {
  guid: string;
  name: string;
  status: StatusType;
  unitPrice: number;
  quantity: number;
  total: number;
  parts?: number;
}

export interface OrderOptional {
  guid: string;
  options?: OrderOption[];
  isRequired: boolean;
  numberOfParts?: number;
  type: TypeOption;
  optionsMin?: number;
  optionsMax?: number;
  optionsRepeat?: boolean;
  quantity: number | null;
  price: number;
  name: string;
}

export interface OrderItem {
  guid: string;
  name: string;
  quantity: number;
  subtotal: number;
  total: number;
  unitPrice: number;
  fullUnitPrice: number;
  priceName: string;
  optionals: OrderOptional[];
  discounts?: OrderDiscount[];
  buyerAnnotation?: string;
}

export enum PaymentType {
  cash = 'cash',
  credit = 'credit',
  debit = 'debit',
  voucher = 'voucher',
  pix = 'pix',
  mercadoPago = 'mercadoPago',
}

export enum PaymentStatus {
  pending = 'pending',
  finalized = 'finalized',
  canceled = 'canceled',
}

export interface OrderPayment {
  transactionAmount: number;
  changeMoney?: number;
  type: PaymentType;
  cardFlagName?: string;
  cardFlagGuid?: string;
  mercadoPagoInitPoint?: string;
  guid: string;
  paymentStatus: PaymentStatus;
}

export enum DeliveryType {
  delivery = 'delivery',
  takeout = 'takeOut',
}

interface AddressBase {
  guid?: string;
  cep?: string;
  street: string;
  complement?: string;
  neighborhood: string;
  number: string;
}

export interface OrderDelivery {
  addressee?: string;
  whatsApp?: string;
  type: DeliveryType;
  fee: number;
  address: Address;
}

export interface OrderTiming {
  orderTimingType: OrderTimingType;
  deliveryDateTimeStart?: string;
  deliveryDateTimeEnd?: string;
}

export interface OrderDiscountRequest {
  priority: number;
  discountType: DiscountValueType;
  discountReference: number;
}

export interface OrderDiscount extends OrderDiscountRequest {
  appliedDiscountCode: string;
  discountAmount: number;
  beforeDiscount: number;
  afterDiscount: number;
  guid: string;
}

export interface OrderRequestStatus {
  description?: string;
  status: OrderStatus;
}

export interface OrderOptionRequest {
  guid: string;
  quantity: number;
}

export interface OrderOptionalRequest {
  guid: string;
  quantity?: number;
  options?: OrderOptionRequest[];
}

export interface OrderItemRequest {
  guid: string;
  priceGuid: string;
  categoryGuid: string;
  quantity: number;
  annotation?: string;
  optionals?: OrderOptionalRequest[];
  discounts?: OrderDiscountRequest[];
}

export interface OrderPaymentRequest {
  paymentType: PaymentType;
  cardFlagGuid?: string;
  changeMoney?: number;
  paymentAmount?: number;
}

export interface OrderDeliveryRequest {
  neighborhoodFeeGuid?: string;
  type: DeliveryType;
  addressee?: string;
  document?: string;
  whatsApp?: string;
  documentType?: DocumentType;
  address: AddressRequestForm;
  coordinates: Coordinates;
}

export interface OrderRequest {
  status: OrderRequestStatus;
  items: OrderItemRequest[];
  payment: OrderPaymentRequest;
  payments?: OrderPaymentRequest[];
  delivery: OrderDeliveryRequest;
  discountCode?: string;
  clientGuid?: string;
  discounts?: OrderDiscountRequest[];
}

export interface PaginatedData<T> {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  result: Array<T>;
  query?: string;
}

export interface OrderPaginatedData extends PaginatedData<Order> {
  status?: OrderStatus;
  query?: string;
}

export interface fetchOrderParams {
  establishmentGuid: string;
  orderGuid: string;
  token: string;
}

export interface PostOrderParams {
  establishmentGuid: string;
  order: OrderCheckout;
}

export interface updateOrderParams {
  orderGuid: string;
  status: OrderStatus;
  description?: string;
}

export interface Order {
  status: OrderStatusHistory;
  statusHistory?: OrderStatusHistory[];
  date?: Date;
  total: number;
  documentNumber?: string;
  items?: OrderItem[];
  payments?: OrderPayment[];
  deliveries?: OrderDelivery[];
  discounts?: OrderDiscount[];
  subTotal: number;
  deliveriesFeeTotal: number;
  discountTotal: number;
  whatsAppPrint?: string;
  userGuid?: string;
  userName?: string;
  guid: string;
  observations?: string;
  timing: OrderTiming;
}
