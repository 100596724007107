import { AttachMoney, CreditCard } from '@mui/icons-material';
import { blue, green, grey, red } from '@mui/material/colors';
import { PaymentType } from '@wls-solucoes/lets-eat-types';
import MercadoPagoIcon from '../../../public/svg/MercadoPagoIcon';
import PixIcon from '../../../public/svg/PixIcon';

type PaymentTypeProps = {
  icon: JSX.Element;
  name: string;
  type: PaymentType;
};

export const getPaymentTypeProps = (
  type: PaymentType,
  color?: string,
  defaultSize = true
): PaymentTypeProps => {
  switch (type) {
    case PaymentType.mercadoPagoCardPayment:
      return {
        type,
        icon: (
          <CreditCard
            fontSize={!defaultSize ? 'large' : undefined}
            style={{ color: !color ? green[500] : color }}
          />
        ),
        name: 'Cartão de crédito',
      };
    case PaymentType.cash:
      return {
        type,
        icon: (
          <AttachMoney
            fontSize={!defaultSize ? 'large' : undefined}
            style={{ color: !color ? green[500] : color }}
          />
        ),
        name: 'Dinheiro',
      };
    case PaymentType.credit:
      return {
        type,
        icon: (
          <CreditCard
            fontSize={!defaultSize ? 'large' : undefined}
            style={{ color: !color ? red[400] : color }}
          />
        ),
        name: 'Cartão de crédito',
      };
    case PaymentType.debit:
      return {
        type,
        icon: (
          <CreditCard
            fontSize={!defaultSize ? 'large' : undefined}
            style={{ color: !color ? blue[400] : color }}
          />
        ),
        name: 'Cartão de débito',
      };
    case PaymentType.voucher:
      return {
        type,
        icon: (
          <CreditCard
            fontSize={!defaultSize ? 'large' : undefined}
            style={{ color: !color ? grey[600] : color }}
          />
        ),
        name: 'Voucher (vale)',
      };
    case PaymentType.pix:
      return {
        type,
        icon: (
          <PixIcon
            color={color ? color : undefined}
            size={defaultSize ? 24 : undefined}
          />
        ),
        name: 'Pix',
      };
    case PaymentType.shipay:
      return {
        type,
        icon: (
          <PixIcon
            color={color ? color : undefined}
            size={defaultSize ? 24 : undefined}
          />
        ),
        name: 'Pix',
      };
    case PaymentType.mercadoPago:
      return {
        type,
        icon: <MercadoPagoIcon />,
        name: 'Mercado Pago',
      };
    default:
      return {
        type,
        icon: <></>,
        name: type,
      };
  }
};
