import { Done } from '@mui/icons-material';
import { Box, Grow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
}));

interface DoneIconProps {
  small?: boolean;
}

const DoneIcon: React.FC<DoneIconProps> = ({ small = false }) => {
  const classes = useStyles();

  return (
    <Grow in>
      <Box
        width={!small ? 28 : 24}
        height={!small ? 28 : 24}
        className={classes.root}
      >
        <Done style={{ color: 'white', fontSize: small ? 16 : undefined }} />
      </Box>
    </Grow>
  );
};

export default DoneIcon;
