import { DayOfTheWeek } from '../../shared/models';

export function getTranslatedDayOfWeek(
  dayOfWeek: DayOfTheWeek,
  abbreviated?: boolean
): string {
  switch (dayOfWeek) {
    case DayOfTheWeek.sunday:
      return abbreviated ? 'Dom' : 'Domingo';
    case DayOfTheWeek.monday:
      return abbreviated ? 'Seg' : 'Segunda-Feira';
    case DayOfTheWeek.tuesday:
      return abbreviated ? 'Ter' : 'Terça-Feira';
    case DayOfTheWeek.wednesday:
      return abbreviated ? 'Qua' : 'Quarta-Feira';
    case DayOfTheWeek.thursday:
      return abbreviated ? 'Qui' : 'Quinta-Feira';
    case DayOfTheWeek.friday:
      return abbreviated ? 'Sex' : 'Sexta-Feira';
    case DayOfTheWeek.saturday:
      return abbreviated ? 'Sáb' : 'Sábado';
  }
}
