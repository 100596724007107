import { isDev } from '../../shared/services/env';

export const gaEvent = (event: string, options = {}) => {
  const { gtag } = window as any;

  gtag?.('event', event, options);

  if (isDev) {
    console.log(`Google Analytics event captured: ${event}`);
  }
};

export const gaPageview = (url: string): void => {
  const { gtag } = window as any;

  gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  });
};
