import React from 'react';

const GreenLogoAvatar: React.FC = () => {
  return (
    <svg
      width="178"
      height="30"
      viewBox="0 0 178 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M111.457 20.0163C110.449 20.4762 108.892 20.1789 108.25 18.9059L107.181 16.7664H97.833L96.7333 18.9059C96.0913 20.1522 94.5945 20.4228 93.5257 20.0163C92.3035 19.5551 91.7543 18.2554 92.3035 17.1729L100.215 1.49022C100.46 0.975669 100.949 0.56914 101.498 0.3531C102.109 0.135898 102.873 0.135898 103.484 0.3531C104.064 0.570302 104.492 0.975669 104.766 1.49022L112.678 17.1729C113.23 18.2554 112.65 19.475 111.457 20.0163ZM104.982 12.4595L102.506 7.53006L100.032 12.4595H104.982Z"
        fill="#3DA865"
      />
      <path
        d="M113.657 13.3806C113.657 9.56153 117.17 6.44637 121.477 6.44637C122.851 6.44637 124.165 6.77159 125.296 7.31285C125.724 6.90632 126.304 6.66241 126.976 6.66241C128.258 6.66241 129.297 7.58349 129.297 8.69389V22.3985C129.297 23.5089 128.258 24.4033 127.036 24.4567H126.822C125.478 24.4567 124.44 23.4822 124.44 22.3172V19.7979C123.523 20.1231 122.515 20.3125 121.477 20.3125C117.17 20.3148 113.657 17.1996 113.657 13.3806ZM118.514 13.3806C118.514 14.8429 119.828 16.0079 121.478 16.0079C123.128 16.0079 124.441 14.8429 124.441 13.3806C124.441 11.9182 123.127 10.7532 121.478 10.7532C119.828 10.7532 118.514 11.9182 118.514 13.3806Z"
        fill="#3DA865"
      />
      <path
        d="M138.493 20.4228C134.766 20.4228 131.742 17.742 131.742 14.4375V8.80423C131.742 7.63924 132.811 6.66473 134.186 6.66473C135.531 6.66473 136.599 7.63924 136.599 8.80423V14.4375C136.599 15.3586 137.455 16.1171 138.493 16.1171C139.532 16.1171 140.387 15.3586 140.387 14.4375V8.80423C140.387 7.63924 141.456 6.66473 142.801 6.66473C144.175 6.66473 145.245 7.63924 145.245 8.80423V14.4375C145.244 17.7409 142.219 20.4228 138.493 20.4228Z"
        fill="#3DA865"
      />
      <path
        d="M149.826 20.2056C148.481 20.2056 147.381 19.2299 147.381 18.0382V8.66717C147.381 7.47546 148.481 6.4998 149.826 6.4998C151.17 6.4998 152.27 7.47546 152.27 8.66717V18.0382C152.27 19.2311 151.17 20.2056 149.826 20.2056Z"
        fill="#3DA865"
      />
      <path
        d="M168.966 23.8458C170.243 23.8458 171.279 22.8343 171.279 21.5866C171.279 20.3389 170.243 19.3275 168.966 19.3275C167.689 19.3275 166.654 20.3389 166.654 21.5866C166.654 22.8343 167.689 23.8458 168.966 23.8458Z"
        fill="#3DA865"
      />
      <path
        d="M162.399 23.8458C163.676 23.8458 164.711 22.8343 164.711 21.5866C164.711 20.3389 163.676 19.3275 162.399 19.3275C161.122 19.3275 160.087 20.3389 160.087 21.5866C160.087 22.8343 161.122 23.8458 162.399 23.8458Z"
        fill="#3DA865"
      />
      <path
        d="M177.86 5.49626C177.675 5.07231 177.328 4.74708 176.885 4.57867C176.679 4.49968 176.462 4.46019 176.24 4.46019H165.401C164.431 4.46019 163.64 5.22214 163.64 6.15948C163.64 7.09565 164.43 7.85876 165.401 7.85876H173.626L172.309 10.9879C171.276 13.4677 168.78 15.0706 165.951 15.0706C163.112 15.0624 160.533 13.3655 159.533 10.852L156.251 2.52396C155.993 1.86887 155.332 1.42866 154.606 1.42866H149.142C148.172 1.42866 147.381 2.1906 147.381 3.12794C147.381 4.06528 148.171 4.82723 149.142 4.82723H153.392L156.242 12.0599C157.753 15.8941 161.654 18.4715 165.949 18.4715C167.187 18.4715 168.408 18.2601 169.577 17.8431C172.303 16.8721 174.489 14.8383 175.571 12.2632L177.869 6.79947C178.047 6.37668 178.043 5.9144 177.86 5.49626Z"
        fill="#3DA865"
      />
      <path
        d="M11.4868 20.3682C5.16324 20.3682 0 15.7907 0 10.1841C0 4.5775 5.16324 0 11.4868 0C14.0227 0 16.4361 0.703874 18.4833 2.11278C19.5521 2.84453 19.7364 4.17097 18.9422 5.14664C18.0862 6.09443 16.5894 6.25704 15.5206 5.52646C14.3603 4.74128 12.9551 4.30803 11.488 4.30803C7.82274 4.30803 4.85889 6.96208 4.85889 10.1853C4.85889 13.4084 7.82274 16.0625 11.488 16.0625C12.9242 16.0625 14.3294 15.6293 15.4897 14.8441C16.5894 14.1402 18.0862 14.3016 18.9113 15.2506C19.7364 16.1984 19.5224 17.5527 18.4524 18.2845C16.4349 19.6643 14.0227 20.3682 11.4868 20.3682Z"
        fill="#3DA865"
      />
      <path
        d="M37.0558 13.4886C37.0558 17.3076 33.5427 20.4228 29.2354 20.4228C24.9281 20.4228 21.415 17.3076 21.415 13.4886C21.415 9.66955 24.9281 6.55439 29.2354 6.55439C33.5427 6.55439 37.0558 9.66955 37.0558 13.4886ZM26.2716 13.4886C26.2716 14.9509 27.5853 16.1159 29.2354 16.1159C30.8855 16.1159 32.1992 14.9509 32.1992 13.4886C32.1992 12.0263 30.8855 10.8613 29.2354 10.8613C27.5853 10.8613 26.2716 12.0263 26.2716 13.4886Z"
        fill="#3DA865"
      />
      <path
        d="M61.2813 12.4862V18.0382C61.2813 19.2299 60.1507 20.2056 58.837 20.2056C57.5233 20.2056 56.3927 19.2299 56.3927 18.0382V12.4862C56.3927 11.5651 55.5676 10.8334 54.5297 10.8334C53.5215 10.8334 52.6668 11.5651 52.6668 12.4862V18.0382C52.6668 19.2299 51.5671 20.2056 50.2224 20.2056C48.9088 20.2056 47.7781 19.2299 47.7781 18.0382V12.4862C47.7781 11.5651 46.9233 10.8334 45.9152 10.8334C44.8761 10.8334 44.051 11.5651 44.051 12.4862V18.0382C44.051 19.2299 42.9513 20.2056 41.6067 20.2056C40.293 20.2056 39.1933 19.2299 39.1933 18.0382V8.7206C39.1933 7.55561 40.2324 6.66241 41.4843 6.66241C42.0644 6.66241 42.5531 6.85173 42.9513 7.15024C43.837 6.74371 44.8452 6.52768 45.9152 6.52768C47.5344 6.52768 49.0621 7.06894 50.2224 7.90871C51.384 7.06894 52.9105 6.52768 54.5297 6.52768C58.2259 6.52768 61.2813 9.2096 61.2813 12.4862Z"
        fill="#3DA865"
      />
      <path
        d="M71.2392 20.3148C70.2001 20.3148 69.192 20.1254 68.2754 19.8002V22.3195C68.2754 23.4845 67.2066 24.459 65.862 24.459H65.648C64.4258 24.4044 63.4177 23.5112 63.4177 22.4008V8.69505C63.4177 7.58465 64.4567 6.66357 65.7086 6.66357C66.4112 6.66357 66.9914 6.90749 67.4194 7.31402C68.55 6.77275 69.8637 6.44753 71.238 6.44753C75.5453 6.44753 79.0584 9.56269 79.0584 13.3817C79.0608 17.1996 75.5477 20.3148 71.2392 20.3148ZM71.2392 10.7532C69.5891 10.7532 68.2754 11.9182 68.2754 13.3806C68.2754 14.8429 69.5891 16.0079 71.2392 16.0079C72.8894 16.0079 74.2031 14.8429 74.2031 13.3806C74.2031 11.9182 72.8894 10.7532 71.2392 10.7532Z"
        fill="#3DA865"
      />
      <path
        d="M90.0875 8.82979C90.0269 10.0215 88.8653 10.8613 87.5207 10.888C86.4519 10.9147 85.565 11.6197 85.565 12.5408V18.0661C85.565 19.2578 84.4963 20.2056 83.1516 20.2056C81.807 20.2056 80.7383 19.2578 80.7383 18.0661V8.69505C80.7383 7.58465 81.7464 6.66357 83.0292 6.66357C83.6094 6.66357 84.1289 6.8529 84.5569 7.17812C85.81 6.60898 87.1843 6.58227 87.7954 6.58227C89.1412 6.60898 90.1493 7.63808 90.0875 8.82979Z"
        fill="#3DA865"
      />
      <path
        d="M39.0471 23.819C40.771 22.5971 40.0386 23.1163 41.7625 21.8944C44.0962 24.7122 46.9388 26.3221 50.5993 26.2385C54.1623 26.1572 57.0239 24.6031 59.4861 21.9873C60.2244 22.8503 60.9139 23.6541 61.7045 24.5787C59.1045 27.8425 55.4963 29.8101 50.9857 29.9866C45.8878 30.1876 41.8493 28.1201 39.0471 23.819Z"
        fill="#3DA865"
      />
    </svg>
  );
};

export default GreenLogoAvatar;
