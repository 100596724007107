import { createSlice } from '@reduxjs/toolkit';
import { recommendationState } from '../../types';

const initialState: recommendationState = {
  recommendations: [],
};

export const recommendationSlice = createSlice({
  name: 'recommendation',
  initialState,
  reducers: {
    clearRecommendations() {
      return { ...initialState };
    },
    addRecommendation(state, { payload }) {
      if (
        state.recommendations &&
        !state.recommendations?.some(
          (item) => item.itemGuid === payload.itemGuid
        )
      ) {
        state.recommendations = [...state.recommendations, payload];
      }
    },
    removeRecommendation(state, { payload }) {
      state.recommendations = state.recommendations.filter(
        (item) => item.itemGuid !== payload
      );
    },
  },
});

export default recommendationSlice.reducer;
export const { clearRecommendations, addRecommendation, removeRecommendation } =
  recommendationSlice.actions;
