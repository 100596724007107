import { createSlice } from '@reduxjs/toolkit';
import { DiscountCodeState } from '../../types';
import { getDiscountCodeByCode } from './actions';

const initialState: DiscountCodeState = {};

export const discountCodeSlice = createSlice({
  name: 'discountCode',
  initialState,
  reducers: {
    clearDiscountCode() {
      return { ...initialState };
    },
    setDiscountCodeData(state, { payload }) {
      state.coupon = payload;
    },

    setCouponAwaitingAuth(state, { payload }) {
      state.couponAwaitingAuth = payload;
    },
  },
  extraReducers: {
    [getDiscountCodeByCode.fulfilled.type]: (state, { payload }) => {
      state.coupon = payload;
    },
  },
});

export default discountCodeSlice.reducer;
export const { clearDiscountCode, setDiscountCodeData, setCouponAwaitingAuth } =
  discountCodeSlice.actions;
