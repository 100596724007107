import { SvgIcon, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const TicketPercentOutline: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> = (
  props: any
) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
      <path d="M12.3334 6.66732L13.3334 7.66732L7.66669 13.334L6.66669 12.334L12.3334 6.66732ZM3.33335 3.33398H16.6667C17.5917 3.33398 18.3334 4.07565 18.3334 5.00065V8.33398C17.8913 8.33398 17.4674 8.50958 17.1548 8.82214C16.8423 9.1347 16.6667 9.55862 16.6667 10.0007C16.6667 10.4427 16.8423 10.8666 17.1548 11.1792C17.4674 11.4917 17.8913 11.6673 18.3334 11.6673V15.0007C18.3334 15.9256 17.5917 16.6673 16.6667 16.6673H3.33335C2.89133 16.6673 2.4674 16.4917 2.15484 16.1792C1.84228 15.8666 1.66669 15.4427 1.66669 15.0007V11.6673C2.59169 11.6673 3.33335 10.9257 3.33335 10.0007C3.33335 9.55862 3.15776 9.1347 2.8452 8.82214C2.53264 8.50958 2.10871 8.33398 1.66669 8.33398V5.00065C1.66669 4.55862 1.84228 4.1347 2.15484 3.82214C2.4674 3.50958 2.89133 3.33398 3.33335 3.33398ZM3.33335 5.00065V7.11732C3.83975 7.40932 4.26032 7.82954 4.55273 8.33569C4.84515 8.84185 4.9991 9.4161 4.9991 10.0007C4.9991 10.5852 4.84515 11.1594 4.55273 11.6656C4.26032 12.1718 3.83975 12.592 3.33335 12.884V15.0007H16.6667V12.884C16.1603 12.592 15.7397 12.1718 15.4473 11.6656C15.1549 11.1594 15.0009 10.5852 15.0009 10.0007C15.0009 9.4161 15.1549 8.84185 15.4473 8.33569C15.7397 7.82954 16.1603 7.40932 16.6667 7.11732V5.00065H3.33335ZM7.91669 6.66732C8.60835 6.66732 9.16669 7.22565 9.16669 7.91732C9.16669 8.60898 8.60835 9.16732 7.91669 9.16732C7.22502 9.16732 6.66669 8.60898 6.66669 7.91732C6.66669 7.22565 7.22502 6.66732 7.91669 6.66732ZM12.0834 10.834C12.775 10.834 13.3334 11.3923 13.3334 12.084C13.3334 12.7757 12.775 13.334 12.0834 13.334C11.3917 13.334 10.8334 12.7757 10.8334 12.084C10.8334 11.3923 11.3917 10.834 12.0834 10.834Z" />
    </SvgIcon>
  );
};

export default TicketPercentOutline;
