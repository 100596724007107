import {
  Coupon,
  DiscountCode,
  DiscountCodeType,
  DiscountTarget,
  DiscountValueType,
} from '@wls-solucoes/lets-eat-types';
import { DisclaimerProps } from '../../containers/DiscountContainer/components/Discount/components/Disclaimer/Disclaimer';
import { toCurrencyValue } from './numberFormat';

export interface CouponListItem {
  guid: string;
  title: string;
  restrictions: string;
  minimumOrderValue: number;
  endDate?: string;
  disabled?: boolean;
  disclaimer?: DisclaimerProps;
}

export const couponFactory = (
  coupon: Coupon | DiscountCode,
  orderValue: number,
  isLoyalty?: boolean
): CouponListItem => {
  const defaultTitle = `Cupom de ${
    coupon.discountValueType === DiscountValueType.percentage
      ? `${coupon.discount}%`
      : toCurrencyValue(coupon.discount as number)
  }`;

  const couponState: CouponListItem = {
    guid: coupon.guid ?? '',
    title: defaultTitle,
    minimumOrderValue: coupon.minimumOrderValue ?? 0,
    restrictions: 'Válido para qualquer pedido',
    endDate: coupon.endDate as string,
  };

  if (coupon.discountTarget === DiscountTarget.deliveryFee) {
    couponState.title = `${defaultTitle} no Frete`;
  }

  if (coupon.minimumOrderValue) {
    couponState.restrictions = `Válido para pedidos acima de ${toCurrencyValue(
      coupon.minimumOrderValue
    )}`;
  }

  if (coupon.needsPublicUserAuth) {
    couponState.restrictions = `Válido apenas para clientes logados no cardápio em ${
      coupon.minimumOrderValue
        ? `pedidos acima de ${toCurrencyValue(coupon.minimumOrderValue)}`
        : 'qualquer pedido'
    }`;
  }

  if (coupon.discountCodeType === DiscountCodeType.firstPurchase) {
    couponState.title = `${defaultTitle} no ${
      coupon.discountTarget === DiscountTarget.deliveryFee ? 'frete para ' : ' '
    }Primeiro pedido`;
    couponState.restrictions = `Válido apenas para novos clientes, em ${
      coupon.minimumOrderValue
        ? `pedidos acima de ${toCurrencyValue(coupon.minimumOrderValue)}`
        : 'qualquer pedido'
    }`;
  }

  if (isLoyalty) {
    couponState['disclaimer'] = {
      text: 'Cupom ganho no Programa de Fidelidade',
      variant: 'loyalty',
    };
  }

  if (coupon.minimumOrderValue && orderValue <= coupon.minimumOrderValue) {
    couponState['disabled'] = true;
    couponState['disclaimer'] = {
      text: `Válido para pedidos acima de ${toCurrencyValue(
        coupon.minimumOrderValue
      )}`,
      variant: 'blocked',
    };
  }

  return couponState;
};
