import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/types';
import { ItemCheckout } from '../../../../models';
import CartItem from '../CartItem';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 20,
  },
}));

interface CartItemsSectionProps {
  disableInteractions?: boolean;
  handleEditItem?: (item: ItemCheckout, index: number) => void;
}

const CartItemsSection: React.FC<CartItemsSectionProps> = ({
  disableInteractions,
  handleEditItem,
}) => {
  const classes = useStyles();

  const { items } = useSelector((state: RootState) => state.orderReducer.order);

  return (
    <Box className={classes.root}>
      {items && items.length > 0 ? (
        <Grid container style={{ gap: 16 }}>
          {items.map((item, index) => (
            <Grid item xs={12} key={`${item.guid}-${index}`}>
              <CartItem
                disableInteractions={disableInteractions}
                item={item}
                index={index}
                handleEditItem={() => handleEditItem?.(item, index)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ marginBottom: 20 }}
        >
          <Typography style={{ color: '#636965' }}>
            Adicione produtos ao seu carrinho!
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CartItemsSection;
