import Close from '@mui/icons-material/Close';
import Stars from '@mui/icons-material/Stars';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { DiscountCode } from '@wls-solucoes/lets-eat-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTimeZone } from '../../../helpers/hooks';
import { toCurrencyValue } from '../../../helpers/utils';
import TicketPercentOutline from '../../../modules/assets/TicketPercentOutline';
import { setDiscountCode } from '../../../redux/reducers/order/reducer';
import { RootState } from '../../../redux/types';
import { DiscountValueType } from '../../models/publicUser';

const DialogPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  margin: '8px !important',
  overflowX: 'hidden',
  overflowY: 'hidden !important' as any,
  borderRadius: 8,

  '&::before': {
    content: '""',
    position: 'absolute',
    top: -295,
    right: -220,
    width: 400,
    height: 400,
    borderRadius: '50%',
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    zIndex: 0,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -205,
    left: -165,
    width: 400,
    height: 400,
    borderRadius: '50%',
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    zIndex: 0,
  },

  '& .MuiDialogContent-root': {
    padding: '0 16px 16px',
    zIndex: 1,
  },

  '& .MuiDialogActions-root': {
    flexDirection: 'column',
    padding: '16px 16px 24px',
    gap: 8,
    zIndex: 1,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  span: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

const CouponCard = styled(Box)(({ theme }) => ({
  padding: 16,
  margin: '16px 12px 0 0',
  borderRadius: 20,
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  boxShadow: `12px 12px 0 ${alpha(theme.palette.primary.main, 0.25)}`,

  span: {
    fontSize: '.75rem',
    fontWeight: 700,
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    padding: '0 8px',
    borderRadius: 7,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

interface AvailableCouponDialogAlertProps {
  dialogId: string;
}

const AvailableCouponDialogAlert: React.FC<AvailableCouponDialogAlertProps> = ({
  dialogId,
}) => {
  const dispatch = useDispatch();

  const { dayjsWithOffset } = useTimeZone();

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );
  const { discountCode } = useSelector(
    (state: RootState) => state.userDiscountReducer
  );
  const { order } = useSelector((state: RootState) => state.orderReducer);

  const hasAlreadyBeenShown = sessionStorage.getItem(dialogId);

  const [couponToShow, setCouponToShow] = useState<DiscountCode>();
  const [showAvailableCouponDialogAlert, setShowAvailableCouponDialogAlert] =
    useState(false);

  const handleOpenAvailableCouponDialogAlert = () => {
    setShowAvailableCouponDialogAlert(true);
  };

  const handleCloseAvailableCouponDialogAlert = () => {
    setShowAvailableCouponDialogAlert(false);
  };

  const handleApplyCoupon = () => {
    dispatch(setDiscountCode(couponToShow));
    handleCloseAvailableCouponDialogAlert();
  };

  useEffect(() => {
    if (hasAlreadyBeenShown) return;

    const coupon = discountCode?.result?.filter(
      (coupon: any) => !coupon.isPublic
    )[0];

    if (coupon && !order.discountCode) {
      setCouponToShow(coupon as DiscountCode);
      handleOpenAvailableCouponDialogAlert();

      sessionStorage.setItem(dialogId, 'true');
    }
  }, [discountCode, order.discountCode, hasAlreadyBeenShown]);

  return (
    <Dialog
      open={showAvailableCouponDialogAlert}
      TransitionComponent={Transition}
      PaperComponent={DialogPaper}
      onBackdropClick={handleCloseAvailableCouponDialogAlert}
      onClose={handleCloseAvailableCouponDialogAlert}
    >
      <Stack alignItems="end" padding="16px 16px 0">
        <Tooltip arrow title="Fechar">
          <IconButton onClick={handleCloseAvailableCouponDialogAlert}>
            <Close />
          </IconButton>
        </Tooltip>
      </Stack>

      <DialogContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <Stars color="primary" />

          <Typography
            variant="h6"
            style={{ fontSize: '1rem', fontWeight: 700 }}
          >
            PROGRAMA DE FIDELIDADE
          </Typography>
        </Stack>

        <Text style={{ marginTop: 8, textAlign: 'center' }}>
          <strong>Parabéns!!</strong> Você completou o Programa de Fidelidade{' '}
          <span>{establishment?.name}</span>{' '}
          <span role="img" aria-label="cone de festa">
            🎉
          </span>
        </Text>

        <CouponCard>
          <Stack direction="row" alignItems="center" gap={1}>
            <span>CUPOM:</span>

            <TicketPercentOutline style={{ fontSize: 20 }} />
          </Stack>

          <Typography style={{ fontSize: '1.125rem', fontWeight: 700 }}>
            {couponToShow?.discountValueType === DiscountValueType.percentage
              ? `${couponToShow?.discount}%`
              : toCurrencyValue(couponToShow?.discount ?? 0)}{' '}
            de desconto
          </Typography>

          <Typography style={{ fontWeight: 600 }}>
            para pedidos acima de{' '}
            {toCurrencyValue(couponToShow?.minimumOrderValue ?? 0)}
          </Typography>
        </CouponCard>

        {couponToShow?.endDate && (
          <Typography
            style={{
              fontSize: '.75rem',
              fontWeight: 700,
              textAlign: 'center',
              marginTop: 20,
            }}
          >
            Válido até{' '}
            {dayjsWithOffset(couponToShow?.endDate).format('DD/MM/YYYY')}
          </Typography>
        )}

        <Text style={{ marginTop: 16, fontWeight: 600, textAlign: 'center' }}>
          Você <strong>ganhou um cupom de desconto</strong> para seu próximo
          pedido! Seus cupons estão disponíveis em{' '}
          <span>Seu perfil &gt; Cupons.</span>{' '}
          <span role="img" aria-label="rosto com olho piscando">
            😉
          </span>
        </Text>
      </DialogContent>

      <DialogActions disableSpacing>
        <Button
          fullWidth
          disableElevation
          variant="contained"
          onClick={handleApplyCoupon}
        >
          aplicar cupom agora
        </Button>

        <Button fullWidth onClick={handleCloseAvailableCouponDialogAlert}>
          mais tarde
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvailableCouponDialogAlert;
