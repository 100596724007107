import { Box, Button, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 12,
    borderRadius: 4,
    backgroundColor: '#F2F2F2',
  },

  progress: {
    borderRadius: 2,
    backgroundColor: '#CACECC',

    '& .MuiLinearProgress-bar': {
      borderRadius: 2,
    },
  },
}));

interface MinValueIndicatorProps {
  value: number;
  maxValue: number;
  actionLabel?: string;
  onActionClick?: () => void;
}

const MinValueIndicator: React.FC<MinValueIndicatorProps> = ({
  value,
  maxValue,
  actionLabel,
  onActionClick,
  children,
}) => {
  const classes = useStyles();

  const normalizedValue =
    (value * 100) / maxValue > 100 ? 100 : (value * 100) / maxValue;

  return (
    <Box className={classes.root}>
      {children}

      <LinearProgress
        color={normalizedValue === 100 ? 'success' : 'primary'}
        variant="determinate"
        className={classes.progress}
        value={normalizedValue}
      />

      {actionLabel && onActionClick && (
        <Button
          fullWidth
          color="primary"
          style={{ marginTop: 16 }}
          onClick={onActionClick}
        >
          {actionLabel}
        </Button>
      )}
    </Box>
  );
};

export default MinValueIndicator;
