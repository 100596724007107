import { Link, Typography, alpha, styled } from '@mui/material';
import React from 'react';
import ComprAquiGreenIcon from '../../../../public/svg/ComprAquiGreenIcon';
import { defaultColors } from '../../../helpers/utils';

const Footer = styled('footer')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 65,
  padding: 5,
  gap: 8,

  [theme.breakpoints.down('sm')]: {
    marginBottom: 55,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 5,
    backgroundColor: defaultColors.primary,
    borderRadius: '5px 5px 0 0',
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',

  [theme.breakpoints.down('sm')]: {
    fontSize: '.875rem',
  },

  a: {
    color: defaultColors.primary,
    textDecorationColor: alpha(defaultColors.primary, 0.4),
  },
}));

const CatalogFooter: React.FC = () => {
  const displayText = 'WLS Cardápio Digital';

  return (
    <Footer>
      <ComprAquiGreenIcon style={{ fontSize: 16 }} />

      <Text>
        Criado com{' '}
        <Link
          href={`${process.env.NEXT_PUBLIC_LANDING_PAGE_URL}/?utm_source=catalog&footer=${displayText}`}
          target="_blank"
        >
          {displayText}
        </Link>
      </Text>
    </Footer>
  );
};

export default CatalogFooter;
