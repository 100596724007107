import React from 'react';
import {
  Box,
  Dialog,
  Drawer,
  Slide,
  SwipeableDrawer,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

type BreakPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogDrawerProps extends React.ComponentProps<typeof Dialog> {
  children?: React.ReactNode;
  breakIn: BreakPoint;
  fullHeight?: boolean;
  onOpen?: () => void;
}

export const DialogDrawer: React.FC<DialogDrawerProps> = ({
  children,
  onOpen,
  fullHeight,
  ...rest
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(rest.breakIn)
  );

  const Container = isMobile
    ? onOpen
      ? (SwipeableDrawer as any)
      : Drawer
    : Dialog;

  const SwipeableData = () => {
    if (isMobile && onOpen) {
      return {
        onOpen,
      };
    }
    return {};
  };

  return (
    <Container
      TransitionComponent={isMobile ? Transition : undefined}
      anchor={'bottom'}
      {...SwipeableData()}
      {...rest}
      PaperProps={{
        ...rest.PaperProps,
        sx: fullHeight
          ? {
              ...rest.PaperProps?.sx,
              height: '100%',
              top: 0,
            }
          : rest.PaperProps?.sx,
      }}
    >
      {isMobile && (
        <Box
          style={{ padding: '1rem', display: 'flex', justifyContent: 'center' }}
        >
          <Box
            style={{
              width: 32,
              height: 4,
              borderRadius: 2,
              backgroundColor: '#7C837F',
            }}
          />
        </Box>
      )}

      {children}
    </Container>
  );
};
