import { OpeningHoursSettings } from '../models';
import Api from './api';

const baseURL = 'api/OpeningHoursSettings';

function getOpeningHoursSettingsWithGuid(
  establishmentGuid: string
): Promise<OpeningHoursSettings> {
  return Api.get(`${baseURL}/${establishmentGuid}`, {
    headers: {
      'x-api-version': '3',
    },
  }).then((res) => res.data);
}

export const openingHoursSettingsService = {
  getOpeningHoursSettingsWithGuid,
};
