import { Box, Typography } from '@mui/material';
import { PaymentType } from '@wls-solucoes/lets-eat-types';
import { FormikProps } from 'formik';
import React from 'react';
import {
  getCardFlags,
  getPaymentTypeProps,
} from '../../../../../../../../helpers/utils';
import { CardFlag } from '../../../../../../../../shared/models';
import PaymentMethodButton from '../PaymentMethodButton';

interface PaymentMethodSectionProps {
  hideTitle?: boolean;
  formik: FormikProps<any>;
  paymentType: PaymentType;
  flags?: CardFlag[];
  handleSubmit: (paymentType: PaymentType) => void;
}

const PaymentMethodSection: React.FC<PaymentMethodSectionProps> = ({
  hideTitle,
  formik,
  paymentType,
  flags,
  handleSubmit,
}) => {
  const { values, setFieldValue } = formik;

  const { icon, name } = getPaymentTypeProps(paymentType);

  const handleSelectCardFlag = (flag: CardFlag): void => {
    setFieldValue('cardFlagGuid', flag.guid);
    setFieldValue('cardFlagName', flag.name);
    setFieldValue('paymentType', paymentType);
    handleSubmit(paymentType);
  };

  const handleSelectPaymentMethod = (): void => {
    setFieldValue('cardFlagGuid', undefined);
    setFieldValue('cardFlagName', undefined);
    setFieldValue('paymentType', paymentType);
    handleSubmit(paymentType);
  };

  return (
    <Box>
      {!hideTitle && (
        <Typography
          data-testid="payment-method-name"
          style={{ fontWeight: 600, color: '#636965', marginBottom: 16 }}
        >
          {name}
        </Typography>
      )}

      {flags && flags.length > 0 ? (
        <Box
          data-testid="flags-container"
          display="flex"
          flexDirection="column"
          style={{ gap: 16 }}
        >
          {flags.map((flag) => (
            <PaymentMethodButton
              key={flag.guid}
              isSelected={
                values.paymentType === paymentType &&
                values.cardFlagGuid === flag.guid
              }
              icon={getCardFlags(flag.name).icon ?? ''}
              label={flag.name}
              onClick={() => handleSelectCardFlag(flag)}
            />
          ))}
        </Box>
      ) : (
        <PaymentMethodButton
          isSelected={values.paymentType === paymentType}
          icon={icon}
          label={name}
          onClick={handleSelectPaymentMethod}
          showFlags={paymentType === PaymentType.mercadoPagoCardPayment}
        />
      )}
    </Box>
  );
};

export default PaymentMethodSection;
