import { env } from '../../shared/services/env';

export const newTab = (url: string): void => {
  window.open(encodeURI(url), '_blank');
};

const googleMapsBase = 'https://maps.google.com/?q=';
export const googleMaps = (address: string): void => {
  const url = `${googleMapsBase}${address}`;
  newTab(url);
  // analytics.eventsGA(OPEN_MAPS_URL);
};

const getWhatsAppUrl = (number: string, text: string): string => {
  const isCorrectLenght = number.length === 10 || number.length === 11;
  const numberToUse = isCorrectLenght ? `55${number}` : number;
  return `https://wa.me/${numberToUse}?text=${text}`;
};

export const sendWhatsAppMessage = (number: string, text: string): void => {
  newTab(getWhatsAppUrl(number, text));
};

export const getSendWhatsAppMessageUrl = (
  number: string,
  text: string
): string => {
  return encodeURI(getWhatsAppUrl(number, text));
};

export const goToRegister = (): void => {
  if (window.location.href.includes('localhost')) {
    // eslint-disable-next-line no-alert
    alert(
      'Certifique-se de que a página de registro foi inicializada:\n\nyarn register dev'
    );
    window.location.href = `http://localhost:3000`;
  } else if (env.isDev) {
    window.location.href = 'https://narnia.register.compraqui.app';
  } else {
    window.location.href = 'https://register.compraqui.app';
  }
};
