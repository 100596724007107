import React from 'react';

const Coupon: React.FC<{ style?: any; className?: any }> = ({
  style,
  className,
}) => {
  return (
    <svg
      className={className}
      style={style}
      width="80"
      height="122"
      viewBox="0 0 80 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-0.000965118 115.717H24.4961V115.902H-0.000965118V115.717Z"
        fill="#EBEBEB"
      />
      <path
        d="M25.2359 108.854H39.4297V109.038H25.2359V108.854Z"
        fill="#EBEBEB"
      />
      <path
        d="M55.5449 130L42.823 32.1819H40.5449H38.178H38.0449H36.5064H35.4413L13.4443 130H15.8481L35.678 43.1434L29.1174 130H31.8615L39.6055 42.781L52.4532 130H55.5449Z"
        fill="#121212"
      />
      <path
        opacity="0.6"
        d="M55.5449 130L42.823 32.1819H40.5449H38.178H38.0449H36.5064H35.4413L13.4443 130H15.8481L35.678 43.1434L29.1174 130H31.8615L39.6055 42.781L52.4532 130H55.5449Z"
        fill="white"
      />
      <path
        d="M79.1191 113.062H24.8513L12.2478 0H66.523L79.1191 113.062Z"
        fill="#121212"
      />
      <path
        opacity="0.6"
        d="M79.1191 113.062H24.8513L12.2478 0H66.523L79.1191 113.062Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M60.185 29.6894V18.1879L55.5475 23.2619L43.3137 7.45563L36.2131 23.2619L24.682 14.7115L31.7087 31.5903L24.682 37.929L36.2131 41.4571L32.9143 55.2663L45.0963 49.6302L52.7738 62.574L57.4557 51.3092L65.259 54.8817L62.5297 43.9719L67.9883 40.895L62.9217 37.929L66.043 31.5903L60.185 29.6894Z"
        fill="white"
      />
      <path
        d="M56.2115 30.2514C55.6789 27.9068 53.9555 26.7307 52.1804 27.1375C50.4052 27.5443 49.4437 29.2603 49.9614 31.5014C50.494 33.8535 52.1064 35.0739 53.9999 34.6375C55.8934 34.2011 56.7218 32.5148 56.2115 30.2514ZM54.5842 30.6213C54.9393 32.1967 54.4437 32.9511 53.6301 33.1361C52.8165 33.321 51.9511 32.8328 51.5665 31.139C51.2115 29.5858 51.781 28.7721 52.5354 28.602C53.2899 28.4319 54.1996 28.9497 54.5842 30.6213ZM54.2366 42.0266L56.3594 41.5458L44.4437 28.9719L42.3283 29.4526L54.2366 42.0266ZM48.2233 39.4526C47.6907 37.108 45.9674 35.9319 44.1922 36.3387C42.4171 36.7455 41.4555 38.4615 41.9733 40.7026C42.491 42.9438 44.1182 44.2677 46.0117 43.8387C47.9052 43.4097 48.741 41.6938 48.2233 39.4526ZM46.5887 39.8225C46.9511 41.3905 46.4555 42.1523 45.6419 42.3373C44.8283 42.5222 43.9629 42.0266 43.5783 40.3402C43.2233 38.7869 43.7928 37.9733 44.5472 37.8032C45.3017 37.6331 46.2114 38.1509 46.5887 39.8225Z"
        fill="#121212"
      />
      <g opacity="0.2">
        <path
          d="M56.2115 30.2514C55.6789 27.9068 53.9555 26.7307 52.1804 27.1375C50.4052 27.5443 49.4437 29.2603 49.9614 31.5014C50.494 33.8535 52.1064 35.0739 53.9999 34.6375C55.8934 34.2011 56.7218 32.5148 56.2115 30.2514ZM54.5842 30.6213C54.9393 32.1967 54.4437 32.9511 53.6301 33.1361C52.8165 33.321 51.9511 32.8328 51.5665 31.139C51.2115 29.5858 51.781 28.7721 52.5354 28.602C53.2899 28.4319 54.1996 28.9497 54.5842 30.6213ZM54.2366 42.0266L56.3594 41.5458L44.4437 28.9719L42.3283 29.4526L54.2366 42.0266ZM48.2233 39.4526C47.6907 37.108 45.9674 35.9319 44.1922 36.3387C42.4171 36.7455 41.4555 38.4615 41.9733 40.7026C42.491 42.9438 44.1182 44.2677 46.0117 43.8387C47.9052 43.4097 48.741 41.6938 48.2233 39.4526ZM46.5887 39.8225C46.9511 41.3905 46.4555 42.1523 45.6419 42.3373C44.8283 42.5222 43.9629 42.0266 43.5783 40.3402C43.2233 38.7869 43.7928 37.9733 44.5472 37.8032C45.3017 37.6331 46.2114 38.1509 46.5887 39.8225Z"
          fill="white"
        />
      </g>
      <path
        d="M60.9648 72.0784L36.9781 72.3817V76.2574H60.9648V72.0784Z"
        fill="white"
      />
      <path
        d="M67.9857 81.0873H29.11V86.1021L69.7461 85.9468L67.9857 81.0873Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M69.7474 94.7855L70.916 104.963H60.9678V94.7855H69.7474Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M54.7017 94.7855L55.5449 104.963H45.0937L45.0863 96.3091L54.7017 94.7855Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M36.981 96.3092L38.7266 104.963H31.0121V96.3092H36.981Z"
        fill="black"
      />
    </svg>
  );
};

export default Coupon;
