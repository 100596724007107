import { useTheme } from '@mui/material';
import NextNProgress from 'nextjs-progressbar';
import React from 'react';

const ProgressBar: React.FC = () => {
  const theme = useTheme();

  return (
    <NextNProgress
      color={theme.palette.secondary.main}
      height={8}
      showOnShallow={true}
    />
  );
};

export default ProgressBar;
