import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { useConfirm } from 'material-ui-confirm';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeAddress } from '../../../../../../../../../../redux/reducers/localAddresses/reducer';
import { setDelivery } from '../../../../../../../../../../redux/reducers/order/reducer';
import { RootState } from '../../../../../../../../../../redux/types';

interface AddressCardActionsProps {
  addressGuid: string;
  handleEditAddress: () => void;
}

const AddressCardActions: React.FC<AddressCardActionsProps> = ({
  addressGuid,
  handleEditAddress,
}) => {
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const { delivery } = useSelector(
    (state: RootState) => state.orderReducer.order
  );

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    setMenuAnchor(null);
  };

  const handleRemoveAddress = (): void => {
    confirm({
      title: 'Remover endereço?',
      description: 'Esta ação não poderá ser desfeita',
      confirmationText: 'Remover',
    }).then(() => {
      dispatch(removeAddress(addressGuid));

      if (addressGuid === delivery?.address?.guid) {
        dispatch(setDelivery({ ...delivery, address: undefined }));
      }
    });
  };

  return (
    <>
      <IconButton size="small" onClick={handleOpenMenu}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            handleEditAddress();
            setMenuAnchor(null);
          }}
        >
          <Edit color="action" />
          <Typography color="textSecondary">Editar</Typography>
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveAddress();
          }}
        >
          <Delete style={{ color: red[500] }} />
          <Typography style={{ color: red[500] }}>Remover</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AddressCardActions;
