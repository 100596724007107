import dynamic from 'next/dynamic';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import BackdropProgressIndicator from '../BackdropProgressIndicator';

const NotLoggedUser = dynamic(() => import('./components/NotLoggedUser'), {
  loading: () => <BackdropProgressIndicator />,
});
const UserProfile = dynamic(() => import('./components/UserProfile'), {
  loading: () => <BackdropProgressIndicator />,
});

interface UserAccountContentProps {
  pageVariant?: boolean;
  adIndex: number;
  onClose?: () => void;
}

const UserAccountContent: React.FC<UserAccountContentProps> = ({
  pageVariant,
  adIndex,
  onClose,
}) => {
  const { user } = useSelector((state: RootState) => state.publicUserReducer);

  return (
    <>
      {!user ? (
        <NotLoggedUser
          pageVariant={pageVariant}
          adIndex={adIndex}
          onClose={onClose}
        />
      ) : (
        <UserProfile pageVariant={pageVariant} onClose={onClose} />
      )}
    </>
  );
};

export default UserAccountContent;
