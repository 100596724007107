import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CartItemRecommendation } from '../../../../models';
import ItemRecommendationCard from './components/ItemRecommendationCard';

const useStyles = makeStyles(() => ({
  container: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
    marginTop: 16,
    paddingRight: 16,
    width: '100%',

    msOverflowStyle: 'none',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  gridItem: {
    width: 142,
    minWidth: 142,

    '& + &': {
      marginLeft: 8,
    },
  },
}));

interface CartRecommendationsSectionProps {
  recommendations: CartItemRecommendation[];
  onAddRecommendedItem: (recommendation: CartItemRecommendation) => void;
}

const CartRecommendationsSection: React.FC<CartRecommendationsSectionProps> = ({
  recommendations,
  onAddRecommendedItem,
}) => {
  const classes = useStyles();

  return (
    <Box paddingY="20px">
      <Typography style={{ fontSize: '1.125rem', fontWeight: 600 }}>
        Peça também
      </Typography>

      <Grid container className={classes.container}>
        {recommendations.map((recommendation) => (
          <Grid key={recommendation.itemGuid} className={classes.gridItem}>
            <ItemRecommendationCard
              recommendedItem={recommendation}
              onAddRecommendedItem={onAddRecommendedItem}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CartRecommendationsSection;
