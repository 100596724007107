import { OrderSummary } from '../models';
import Api from './api';

const baseUrl = '/api/PublicUser';

function getActiveOrders(
  establishmentGuid: string,
  orders: string[]
): Promise<OrderSummary[]> {
  const url = `${baseUrl}/Orders?establishmentGuid=${establishmentGuid}&orderGuid=${orders.join(
    '&orderGuid='
  )}`;

  return Api.get(url, { headers: { 'x-api-version': '3' } }).then(
    (res) => res.data
  );
}

export const publicOrderService = {
  getActiveOrders,
};
