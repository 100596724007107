import { Add, ExpandMore } from '@mui/icons-material';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/types';
import BackdropProgressIndicator from '../../../BackdropProgressIndicator';
import UserAddressesDialog from '../UserAddressesDialog';

const UserAddressDialogForm = dynamic(
  () => import('../../../UserAddressDialogForm'),
  { loading: () => <BackdropProgressIndicator /> }
);

const useStyles = makeStyles(() => ({
  button: {
    color: '#4A4F4C',
    fontSize: '1.125rem',
    textTransform: 'none',

    '& .MuiButton-endIcon': {
      width: 24,
      height: 24,
    },
  },
}));

const UserAddressesButton: React.FC = () => {
  const classes = useStyles();

  const { user } = useSelector((state: RootState) => state.publicUserReducer);
  const { delivery } = useSelector(
    (state: RootState) => state.orderReducer.order
  );

  const defaultAddress = user?.addresses?.find(
    (address) => address.isDefault === true
  );

  const [showUserAddressDialogForm, setShowUserAddressDialogForm] =
    useState(false);
  const [showUserAddressesDialog, setShowUserAddressesDialog] = useState(false);

  const handleOpenUserAddressDialogForm = () => {
    setShowUserAddressDialogForm(true);
  };

  const handleCloseUserAddressDialogForm = () => {
    setShowUserAddressDialogForm(false);
  };

  const handleOpenUserAddressesDialog = () => {
    setShowUserAddressesDialog(true);
  };

  const handleCloseUserAddressesDialog = () => {
    setShowUserAddressesDialog(false);
  };

  return (
    <>
      {showUserAddressDialogForm && (
        <UserAddressDialogForm onClose={handleCloseUserAddressDialogForm} />
      )}

      <UserAddressesDialog
        open={showUserAddressesDialog}
        onClose={handleCloseUserAddressesDialog}
      />

      <Button
        data-testid="user-addresses-btn"
        className={classes.button}
        endIcon={
          !defaultAddress && !delivery?.address ? (
            <Add style={{ width: 24, height: 24 }} />
          ) : (
            <ExpandMore
              data-testid="expand-icon"
              style={{ width: 24, height: 24 }}
            />
          )
        }
        onClick={() => {
          if (!user?.addresses || user?.addresses?.length === 0) {
            handleOpenUserAddressDialogForm();
          } else {
            handleOpenUserAddressesDialog();
          }
        }}
      >
        {delivery?.address ? (
          `${delivery.address.street}, ${delivery.address.number}`
        ) : (
          <>
            {!defaultAddress
              ? 'Adicione um endereço'
              : `${defaultAddress.street}, ${defaultAddress.number}`}
          </>
        )}
      </Button>
    </>
  );
};

export default UserAddressesButton;
