import { Delete, Edit, MoreVert, Star } from '@mui/icons-material';
import {
  Box,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { useConfirm } from 'material-ui-confirm';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addIndicator,
  removeIndicator,
} from '../../../../../../../redux/reducers/progressIndicator/reducer';
import { fetchPublicUserAddresses } from '../../../../../../../redux/reducers/publicUser/actions';
import { RootState } from '../../../../../../../redux/types';
import { publicUserService } from '../../../../../../services/publicUser';
import { v4 as uuidv4 } from 'uuid';
import { PublicUserAddress } from '@wls-solucoes/lets-eat-types';

interface AddressCardActionsProps {
  address: PublicUserAddress;
  handleEditAddress: () => void;
}

const AddressCardActions: React.FC<AddressCardActionsProps> = ({
  address,
  handleEditAddress,
}) => {
  const dispatch = useDispatch<any>();
  const confirm = useConfirm();

  const { user } = useSelector((state: RootState) => state.publicUserReducer);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();

    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = (
    event?: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event?.stopPropagation();

    setMenuAnchor(null);
  };

  const handleSetAsDefault = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.stopPropagation();

    publicUserService.setAddressAsDefault(address.guid ?? '').then(() => {
      dispatch(fetchPublicUserAddresses());
    });

    handleCloseMenu();
  };

  const handleDeleteAddress = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.stopPropagation();

    confirm({
      title: 'Excluir endereço?',
      description: 'Esta ação não poderá ser desfeita',
      confirmationText: 'excluir',
      dialogProps: {
        PaperProps: {
          style: {
            width: 330,
          },
        },
      },
      cancellationButtonProps: {
        variant: 'outlined',
      },
      confirmationButtonProps: {
        disableElevation: true,
        variant: 'contained',
        color: 'error',
      },
    }).then(() => {
      const indicatorId = uuidv4();
      dispatch(addIndicator(indicatorId));
      publicUserService
        .deleteAddress(address.guid ?? '')
        .then(() => dispatch(fetchPublicUserAddresses()))
        .finally(() => {
          dispatch(removeIndicator(indicatorId));
        });

      handleCloseMenu();
    });
  };

  return (
    <>
      <Hidden smDown>
        <Box display="flex" alignItems="center" style={{ gap: 8 }}>
          <Tooltip arrow title="Editar endereço">
            <IconButton
              data-testid="edit-btn"
              size="small"
              onClick={(e) => {
                e.stopPropagation();

                handleEditAddress();
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Favoritar endereço">
            <IconButton
              data-testid="fav-btn"
              size="small"
              disabled={address.isDefault}
              onClick={handleSetAsDefault}
            >
              <Star color={address.isDefault ? 'primary' : undefined} />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Excluir endereço">
            <IconButton
              data-testid="delete-btn"
              size="small"
              onClick={handleDeleteAddress}
            >
              <Delete style={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Hidden>

      <Hidden smUp>
        <IconButton data-testid="open-menu-btn" onClick={handleOpenMenu}>
          <MoreVert />
        </IconButton>

        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={(e: React.MouseEvent<HTMLButtonElement>) =>
            handleCloseMenu(e)
          }
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            style={{ color: '#4A4F4C' }}
            onClick={(e) => {
              e.stopPropagation();

              handleEditAddress();
              handleCloseMenu();
            }}
          >
            <Edit />
            <Typography style={{ marginLeft: 8 }}>Editar endereço</Typography>
          </MenuItem>

          <MenuItem
            disabled={address.isDefault}
            style={{ color: '#4A4F4C' }}
            onClick={handleSetAsDefault}
          >
            <Star />
            <Typography style={{ marginLeft: 8 }}>
              Favoritar endereço
            </Typography>
          </MenuItem>

          <MenuItem style={{ color: red[500] }} onClick={handleDeleteAddress}>
            <Delete />
            <Typography style={{ marginLeft: 8 }}>Excluir endereço</Typography>
          </MenuItem>
        </Menu>
      </Hidden>
    </>
  );
};

export default AddressCardActions;
