import { Home, Place, Star, Work } from '@mui/icons-material';
import { Box, Card, Hidden, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useFeeByDistanceValidation,
  useMapDialog,
} from '../../../../../helpers/hooks';
import { setDelivery } from '../../../../../redux/reducers/order/reducer';
import {
  addIndicator,
  removeIndicator,
} from '../../../../../redux/reducers/progressIndicator/reducer';
import { RootState } from '../../../../../redux/types';
import AddressCardActions from './components/AddressCardActions';
import { v4 as uuidv4 } from 'uuid';
import {
  DeliveryFeeType,
  PublicUserAddress,
  PublicUserAddressRequest,
  PublicUserAddressType,
} from '@wls-solucoes/lets-eat-types';
import { publicUserService } from '../../../../services/publicUser';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 12px',
    marginTop: 24,

    transition: 'all ease .2s',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f7f7f7',
    },
  },
}));

interface AddressCardProps {
  address: PublicUserAddress;
  handleOpenUserAddressDialogForm: (addressToEdit?: PublicUserAddress) => void;
}

const AddressCard: React.FC<AddressCardProps> = ({
  address,
  handleOpenUserAddressDialogForm,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { handleValidateFeeByDistance } = useFeeByDistanceValidation();
  const [MapDialog, mapControls] = useMapDialog();

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const { delivery } = useSelector(
    (state: RootState) => state.orderReducer.order
  );

  const handleSetDelivery = async () => {
    const hasMap =
      establishment?.deliverySettings?.deliveryFeeType !==
        DeliveryFeeType.fixedFee &&
      establishment?.deliverySettings?.deliveryFeeType !==
        DeliveryFeeType.neighborhoodFee;

    const stateAddress = { ...address };

    if (!address.coordinates && hasMap) {
      const coordinates = await mapControls.open({
        street: stateAddress.street,
        city: stateAddress.city?.name ?? '',
        hideSearchBar: true,
        closeInX: true,
        number: stateAddress.number,
        neighborhood: stateAddress.neighborhood,
      });
      stateAddress.coordinates = coordinates[0];
      const indicatorId = uuidv4();
      dispatch(addIndicator(indicatorId));
      await publicUserService.updateAddress(
        stateAddress as PublicUserAddressRequest
      );
      dispatch(removeIndicator(indicatorId));
    }

    if (
      hasMap &&
      stateAddress &&
      stateAddress.guid !== delivery?.address?.guid
    ) {
      const indicatorId = uuidv4();
      dispatch(addIndicator(indicatorId));
      handleValidateFeeByDistance(
        stateAddress as any,
        () => {
          dispatch(removeIndicator(indicatorId));
        },
        false
      );
    } else {
      dispatch(setDelivery({ ...delivery, address: stateAddress }));
    }
  };

  return (
    <>
      <MapDialog />

      <Card
        data-testid="address-card"
        variant="outlined"
        className={classes.root}
        style={
          delivery?.address?.guid === address.guid
            ? {
                border: `2px solid ${theme.palette.primary.main}`,
              }
            : undefined
        }
        onClick={handleSetDelivery}
      >
        <Box
          display="flex"
          alignItems="center"
          style={
            delivery?.address?.guid === address.guid
              ? {
                  color: theme.palette.primary.main,
                }
              : undefined
          }
        >
          {!address.addressType ? (
            <Place data-testid="pin-icon" style={{ color: '#7C837F' }} />
          ) : (
            <>
              {address.addressType === PublicUserAddressType.residential ? (
                <Home data-testid="home-icon" style={{ color: '#7C837F' }} />
              ) : (
                <Work data-testid="work-icon" style={{ color: '#7C837F' }} />
              )}
            </>
          )}

          <Box ml={1}>
            <Box display="flex" alignItems="center">
              {address.addressType && (
                <Typography
                  data-testid="address-type"
                  style={{ fontSize: '.875rem', fontWeight: 700 }}
                >
                  {address.addressType === PublicUserAddressType.residential
                    ? 'Casa'
                    : 'Trabalho'}
                </Typography>
              )}

              <Hidden smUp>
                <>
                  {address.isDefault && (
                    <Star
                      data-testid="fav-icon"
                      color="primary"
                      style={{ marginLeft: 4 }}
                    />
                  )}
                </>
              </Hidden>
            </Box>

            <Typography
              data-testid="address-first-row"
              style={{
                fontSize: '.875rem',
                color: address.addressType ? '#636965' : undefined,
                fontWeight: !address.addressType ? 700 : undefined,
              }}
            >
              {`${address.street}, ${address.number}`}
            </Typography>

            <Typography
              style={{
                fontSize: '.875rem',
                color: '#636965',
              }}
            >
              {`${address.neighborhood} - ${address.city?.name}/${address.city?.state.uf}`}
            </Typography>
          </Box>
        </Box>

        <AddressCardActions
          address={address}
          handleEditAddress={() => handleOpenUserAddressDialogForm(address)}
        />
      </Card>
    </>
  );
};

export default AddressCard;
