import { GenericStatus, Inventory } from '@wls-solucoes/lets-eat-types';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

interface InitialValues {
  inventory: Inventory;
  existingQuantity?: number;
  disableErrorOnStart?: boolean;
}

interface UseStockValidationReturn {
  quantityLimit?: number;
  updateQuantityLimit: (
    inventory: Inventory,
    existingQuantity?: number
  ) => void;
  validateQuantity: (
    value: number,
    onSuccess: () => void,
    onLimitExceeded?: (maxValue: number) => void
  ) => void;
}

export const useStockValidation = (
  initialValues?: InitialValues
): UseStockValidationReturn => {
  const [quantityLimit, setQuantityLimit] = useState<number>();
  const [cartQuantity, setCartQuantity] = useState<number>();

  const updateQuantityLimit = (
    inventory: Inventory,
    existingQuantity?: number,
    disableErrorMessage?: boolean
  ): void => {
    if (inventory.status === GenericStatus.activated) {
      const remainingQuantity = inventory.quantity - (existingQuantity ?? 0);

      if (existingQuantity) {
        setCartQuantity(existingQuantity);
      } else if (cartQuantity !== undefined) {
        setCartQuantity(undefined);
      }

      if (remainingQuantity === 0 && !disableErrorMessage) {
        Swal.fire({
          title: 'Ops!',
          text: 'Não é possível adicionar mais unidades dessa variação.',
          icon: 'error',
        });
      }

      setQuantityLimit(remainingQuantity);

      return;
    }

    setQuantityLimit(undefined);
  };

  const validateQuantity = (
    value: number,
    onSuccess: () => void,
    onLimitExceeded?: (maxValue: number) => void
  ): void => {
    if (quantityLimit === 0) {
      onLimitExceeded?.(1);

      Swal.fire({
        title: 'Ops!',
        text: 'Não é possível adicionar mais unidades dessa variação.',
        icon: 'error',
      });

      return;
    }

    if (!quantityLimit) {
      onSuccess();

      return;
    }

    if (value > quantityLimit) {
      onLimitExceeded?.(quantityLimit);

      Swal.fire({
        title: 'Ops!',
        text:
          quantityLimit > 0 && quantityLimit < 1
            ? `Não é possível adicionar mais unidades${
                cartQuantity ? ` além das já selecionadas.` : '.'
              }`
            : `O estabelecimento possui apenas mais ${quantityLimit} ${
                quantityLimit === 1
                  ? 'unidade disponível'
                  : 'unidades disponíveis'
              }${
                cartQuantity
                  ? ` além ${
                      cartQuantity === 1
                        ? 'da unidade'
                        : `das ${cartQuantity} unidades`
                    } no carrinho.`
                  : '.'
              }`,
        icon: 'error',
      });

      return;
    }

    onSuccess();
  };

  useEffect(() => {
    if (initialValues) {
      updateQuantityLimit(
        initialValues.inventory,
        initialValues.existingQuantity,
        initialValues.disableErrorOnStart
      );
    }
  }, []);

  return {
    quantityLimit,
    updateQuantityLimit,
    validateQuantity,
  };
};
