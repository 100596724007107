import { AxiosResponse } from 'axios';
import { LoyaltyPlan } from '../models/loyaltyPlan';
import Api from './api';

const baseUrl = '/api/loyalty-plan';

function getLoyaltyPlanByGuid(loyaltyPlanGuid: string): Promise<LoyaltyPlan> {
  return Api.get(`${baseUrl}/${loyaltyPlanGuid}`, {
    headers: {
      'x-api-version': '3',
    },
  }).then((r) => r.data);
}

export const loyaltyPlanService = {
  getLoyaltyPlanByGuid,
};
