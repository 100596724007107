import { Box, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CheckedIcon from '../../../../../../../../../containers/DiscountContainer/components/Discount/components/CheckedIcon';
import TicketPercent from '../../../../../../../../../modules/assets/TicketPercent';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem',
    paddingRight: '1.8125rem',
    borderRadius: 4,
    border: '1px solid #CACECC',
    position: 'relative',

    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  text: {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '150%',
  },
}));

type NoCouponProps = {
  selected: boolean;
  onSelect: () => void;
};

const NoCoupon: React.FC<NoCouponProps> = ({ selected, onSelect }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      data-testid="no-coupon-container"
      className={classes.root}
      role="button"
      onClick={onSelect}
      style={{
        border: selected
          ? `2px solid ${theme.palette.primary.main}`
          : '1px solid #CACECC',
        margin: selected ? 0 : '1px',
      }}
    >
      <Box display="flex" gap="0.5rem">
        <TicketPercent style={{ fill: '#7C837F' }} />
        <Typography className={classes.text}>Sem cupom</Typography>
      </Box>
      <CheckedIcon selected={selected} />
    </Box>
  );
};

export default NoCoupon;
