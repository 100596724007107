import { ItemCheckout } from '@wls-solucoes/lets-eat-types';
import { GAItem } from '../../shared/models';

export function checkoutItemsToGAItems(items: ItemCheckout[]): GAItem[] {
  return items.map((item) => ({
    item_id: item.guid,
    item_name: item.name,
    item_category: item.categoryGuid,
  }));
}
