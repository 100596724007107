import { createSlice } from '@reduxjs/toolkit';
import { loyaltyPlanState } from '../../types';
import { getLoyaltyPlan } from './actions';

const initialState: loyaltyPlanState = {
  isLoading: false,
};

const loyaltyPlanSlice = createSlice({
  name: 'loyaltyPlan',
  initialState,
  reducers: {
    clearLoyaltyPlan(state, { payload }) {
      state.loyaltyPlan = undefined;
      state.myLoyaltyPlanState = undefined;
    },
  },
  extraReducers: {
    [getLoyaltyPlan.pending.type]: (state, { payload }) => {
      state.isLoading = true;
    },
    [getLoyaltyPlan.fulfilled.type]: (state, { payload }) => {
      state.loyaltyPlan = payload[0];
      state.myLoyaltyPlanState = payload[1];
      state.isLoading = false;
    },
    [getLoyaltyPlan.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

export default loyaltyPlanSlice.reducer;
export const { clearLoyaltyPlan } = loyaltyPlanSlice.actions;
