import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  alpha,
  Box,
  LinearProgress,
  Skeleton,
  Typography,
} from '@mui/material';
import { Stars } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { getRandomArbitrary } from '../../../helpers/utils/random';
import { LoyaltyPlanStatus } from '../../models/loyaltyPlan';
import { orderCheckoutTotalSelector } from '../../../redux/reducers/order/selectors';
import { toCurrencyValue } from '../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1.25rem 0',
  },
  starIcon: {
    fontSize: 24,
    color: '#FFBE5C',
    backgroundColor: alpha('#FFBE5C', 0.1),
    padding: '0.5rem',
    borderRadius: '0.25rem',
    boxSizing: 'content-box',
  },
  title: {
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '1.3125rem',
    color: '#191A19',
  },
  subtitle: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.125rem',
    color: '#7C837F',
  },
  missingValueTitle: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    color: '#636965',
    margin: '16px 0 8px',
  },
  progress: {
    backgroundColor: '#CACECC',

    '& .MuiLinearProgress-bar': {
      backgroundColor: '#FFBE5C',
    },
  },
}));

type LoyaltyPointIndicatorProps = {};

const LoyaltyPointIndicator: React.FC<LoyaltyPointIndicatorProps> = ({}) => {
  const classes = useStyles();

  const { user } = useSelector((state: RootState) => state.publicUserReducer);
  const orderTotal = useSelector(orderCheckoutTotalSelector);

  const { loyaltyPlan, isLoading } = useSelector(
    (state: RootState) => state.loyaltyPlanReducer
  );

  if (
    (!loyaltyPlan || loyaltyPlan?.status === LoyaltyPlanStatus.deactivated) &&
    !isLoading
  ) {
    return <></>;
  }

  const missingValue = orderTotal - (loyaltyPlan?.orderMinValue ?? 0);

  const missingValuePercentage =
    (100 * orderTotal) / (loyaltyPlan?.orderMinValue ?? 0);

  const showRenderMissingValue = missingValue < 0 && user;

  const RenderCorrectlyTexts: React.FC<{}> = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton
            data-testid="loyalty-point-indicator-skeleton"
            variant="text"
            animation="wave"
            width={getRandomArbitrary(250, 350)}
            height={22}
          />
          <Skeleton
            variant="text"
            animation="wave"
            width={getRandomArbitrary(100, 200)}
            height={22}
          />
        </>
      );
    }

    if (!user) {
      return (
        <>
          <Typography
            className={classes.title}
            data-testid="loyalty-point-indicator-no-logged"
          >
            Você precisa estar logado para este pedido contabilizar no Programa
            de Fidelidade
          </Typography>
        </>
      );
    }

    if (missingValue < 0) {
      return (
        <Typography
          data-testid="loyalty-point-indicator-missing-value"
          className={classes.title}
        >
          Adicione mais produtos no carrinho para conseguir 1 ponto no Programa
          de Fidelidade.
        </Typography>
      );
    }

    return (
      <>
        <Typography
          className={classes.title}
          data-testid={'loyalty-point-indicator-card'}
        >
          Esse pedido irá contabilizar 1 ponto no Programa de Fidelidade
        </Typography>
        <Typography className={classes.subtitle}>
          Será confirmado após conclusão do pedido
        </Typography>
      </>
    );
  };

  return (
    <Box className={classes.root}>
      <Box display="flex" gap="12px" alignItems="center">
        <Stars className={classes.starIcon} />
        <Box style={{ flex: 1 }}>
          <RenderCorrectlyTexts />
        </Box>
      </Box>
      {showRenderMissingValue ? (
        <>
          <Typography className={classes.missingValueTitle}>
            Faltam apenas {toCurrencyValue(missingValue * -1)}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={missingValuePercentage}
            className={classes.progress}
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default LoyaltyPointIndicator;
