import { configureStore } from '@reduxjs/toolkit';
import { compose } from 'redux';
import { persistStore } from 'redux-persist';
import { isDev } from '../shared/services/env';
import rootReducer from './reducers';

// eslint-disable-next-line
// @ts-ignore
const reduxDevtools =
  typeof window !== 'undefined'
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: isDev,
});

const persistor = persistStore(store);

export { store, persistor };
