import React from 'react';

const PaymentDollar: React.FC<{ style?: any; className?: any }> = ({
  style,
  className,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        d="M18.9998 11.0003H16.5C16.1022 11.0003 15.7206 11.1584 15.4393 11.4397C15.158 11.721 15 12.1025 15 12.5003C15 12.8981 15.158 13.2797 15.4393 13.561C15.7206 13.8423 16.1022 14.0003 16.5 14.0003H17.4998C17.8977 14.0003 18.2792 14.1584 18.5605 14.4397C18.8418 14.721 18.9998 15.1025 18.9998 15.5003C18.9998 15.8981 18.8418 16.2797 18.5605 16.561C18.2792 16.8423 17.8977 17.0003 17.4998 17.0003H15M17.0002 18.0002V17.0003V18.0002Z"
        fill="#121212"
      />
      <path
        d="M18.9998 11.0003H16.5C16.1022 11.0003 15.7206 11.1584 15.4393 11.4397C15.158 11.721 15 12.1025 15 12.5003C15 12.8981 15.158 13.2797 15.4393 13.561C15.7206 13.8423 16.1022 14.0003 16.5 14.0003H17.4998C17.8977 14.0003 18.2792 14.1584 18.5605 14.4397C18.8418 14.721 18.9998 15.1025 18.9998 15.5003C18.9998 15.8981 18.8418 16.2797 18.5605 16.561C18.2792 16.8423 17.8977 17.0003 17.4998 17.0003H15M17.0002 18.0002V17.0003M17.0002 11.0003V10"
        stroke="#121212"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0002 21H5.99999C5.73482 21 5.48051 20.8947 5.293 20.7072C5.10549 20.5196 5.00015 20.2653 5.00015 20.0002V3.99984C5.00015 3.73467 5.10549 3.48035 5.293 3.29285C5.48051 3.10534 5.73482 3 5.99999 3H15.9998C16.1312 2.99994 16.2612 3.02575 16.3826 3.07597C16.504 3.12619 16.6142 3.19983 16.7071 3.29268C16.8 3.38553 16.8737 3.49577 16.924 3.61711C16.9743 3.73844 17.0002 3.8685 17.0002 3.99984V6"
        stroke="#121212"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9998 3H9L9.50016 5.00016H12.5002L12.9998 3Z"
        stroke="#121212"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaymentDollar;
