import React from 'react';

const AccountPayment: React.FC<{ style?: any; className?: any }> = ({
  style,
  className,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        d="M7 12C9.2 12 11 10.2 11 8C11 5.8 9.2 4 7 4C4.8 4 3 5.8 3 8C3 10.2 4.8 12 7 12ZM11 20V14.7C9.9 14.3 8.5 14 7 14C3.1 14 0 15.8 0 18V20H11ZM22 4H15C13.9 4 13 4.9 13 6V18C13 19.1 13.9 20 15 20H22C23.1 20 24 19.1 24 18V6C24 4.9 23.1 4 22 4ZM18 18H16V6H18V18Z"
        fill="#121212"
      />
    </svg>
  );
};

export default AccountPayment;
