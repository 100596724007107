import { OrderStatus } from '../../shared/models';

export function getTranslatedOrderStatus(status: OrderStatus) {
  const translations = {
    [OrderStatus.pending]: 'pendente',
    [OrderStatus.accepted]: 'aceito',
    [OrderStatus.preparing]: 'em preparo',
    [OrderStatus.delivered]: 'enviado',
    [OrderStatus.takeout]: 'pronto para retirada',
    [OrderStatus.finished]: 'concluído',
    [OrderStatus.canceled]: 'cancelado',
  };

  return translations[status];
}
