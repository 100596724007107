import { ArrowBack, Help, Receipt } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Fade,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOpeningHoursSettingsWithGuid } from '../../../redux/reducers/openingHoursSettings/actions';
import { RootState } from '../../../redux/types';
import CartButton from './components/CartButton';
import HeaderSearchBar from './components/HeaderSearchBar';
import SendOrderMessageDialog from './components/SendOrderMessageDialog';
import UserAccountButton from './components/UserAccountButton';
import UserAddressesButton from './components/UserAddressesButton';

const useStyles = makeStyles(() => ({
  root: {
    height: 64,
    padding: '11px 24px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid #CACECC',
  },

  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  logo: {
    height: 42,

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface HeaderProps {
  title?: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname, push, query } = useRouter();
  const isSm = useMediaQuery('(max-width:600px)');

  const { readOnly } = query;

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );
  const { search } = useSelector((state: RootState) => state.searchReducer);
  const { openingHoursSettings } = useSelector(
    (state: RootState) => state.openingHoursSettingsReducer
  );
  const { user } = useSelector((state: RootState) => state.publicUserReducer);

  const isHomePage = pathname === '/';
  const isOrdersPage = pathname === '/orders';
  const isClientOrderPage = pathname.startsWith('/ClientOrder/');

  const [searchOpen, setSearchOpen] = useState(!!search);
  const [showSendOrderMessageDialog, setShowSendOrderMessageDialog] =
    useState(false);

  const handleOpenSendOrderMessageDialog = () => {
    setShowSendOrderMessageDialog(true);
  };

  const handleCloseSendOrderMessageDialog = () => {
    setShowSendOrderMessageDialog(false);
  };

  useEffect(() => {
    if (establishment && !openingHoursSettings) {
      dispatch(getOpeningHoursSettingsWithGuid(establishment.guid));
    }
  }, [establishment]);

  return (
    <>
      <SendOrderMessageDialog
        open={showSendOrderMessageDialog}
        onClose={handleCloseSendOrderMessageDialog}
      />

      <AppBar
        position="fixed"
        className={classes.root}
        style={
          title
            ? {
                padding: '20px 16px',
              }
            : undefined
        }
      >
        {isHomePage ? (
          <>
            <Fade in={isSm ? !searchOpen : true}>
              <Box display="flex" alignItems="center" style={{ gap: 24 }}>
                <div>
                  <Image
                    src={'/svg/ComprAqui-green-logo.svg'}
                    alt="ComprAqui"
                    height={42}
                    width={215}
                  />
                </div>
              </Box>
            </Fade>

            <Hidden smDown>
              <Box display="flex" alignItems="center" style={{ gap: 20 }}>
                <Hidden mdDown>
                  <>{user && <UserAddressesButton />}</>
                </Hidden>

                {readOnly !== 'true' && <CartButton />}

                <Tooltip arrow title="Seus pedidos">
                  <IconButton
                    data-testid="orders-page-button"
                    onClick={() => push('/orders')}
                  >
                    <Receipt fontSize="large" />
                  </IconButton>
                </Tooltip>

                <UserAccountButton />
              </Box>
            </Hidden>

            <Hidden smUp>
              <HeaderSearchBar
                searchOpen={searchOpen}
                setSearchOpen={(v) => setSearchOpen(v)}
              />
            </Hidden>
          </>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              maxWidth={1216}
            >
              <IconButton
                size="small"
                data-testid="arrow-back"
                onClick={() => push('/')}
              >
                <ArrowBack style={{ color: '#4A4F4C' }} />
              </IconButton>

              {!title ? (
                <Typography
                  style={{
                    fontSize: '.875rem',
                    color: '#636965',
                    textAlign: 'center',
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {isOrdersPage ? 'Meus pedidos em' : 'Meu pedido em'}{' '}
                  <Hidden smUp>
                    <br />
                  </Hidden>
                  <strong style={{ fontSize: '1.125rem', color: 'black' }}>
                    {establishment?.name}
                  </strong>
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  style={{
                    color: '#000',
                    fontSize: '1.125rem',
                    fontWeight: 700,
                  }}
                >
                  {title}
                </Typography>
              )}

              {isClientOrderPage ? (
                <>
                  {establishment?.settings.acceptsOrderType ===
                    'panelPlusWhatsapp' ||
                  establishment?.settings.acceptsOrderType === 'whatsapp' ? (
                    <Tooltip arrow title="Precisa de ajuda?">
                      <IconButton
                        data-testid="order-help-btn"
                        onClick={handleOpenSendOrderMessageDialog}
                      >
                        <Help />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Box width={40} height={40} />
                  )}
                </>
              ) : (
                <>
                  <Hidden smDown>
                    <Image
                      src={'/svg/ComprAqui-green-logo.svg'}
                      alt="ComprAqui"
                      height={42}
                      width={215}
                    />
                  </Hidden>

                  <Hidden smUp>
                    <Box width={51} height={51} />
                  </Hidden>
                </>
              )}
            </Box>
          </Box>
        )}
      </AppBar>
    </>
  );
};

export default Header;
