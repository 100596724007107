import { Add, Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slide,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useEventHandler,
  useFeeByDistanceValidation,
} from '../../../../../../../../helpers/hooks';
import { setDelivery } from '../../../../../../../../redux/reducers/order/reducer';
import { RootState } from '../../../../../../../../redux/types';
import BackdropProgressIndicator from '../../../../../../../../shared/components/BackdropProgressIndicator';
import {
  Address,
  DeliveryFeeType,
} from '../../../../../../../../shared/models';
import AddressCard from '../AddressCard';

const AddressDialogForm = dynamic(() => import('../AddressDialogForm'), {
  loading: () => <BackdropProgressIndicator />,
});

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    width: 390,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      borderRadius: 0,
      margin: 0,
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SelectAddressDialogProps {
  onSubmitCallback?: () => void;
  onClose: () => void;
}

const SelectAddressDialog: React.FC<SelectAddressDialogProps> = ({
  onSubmitCallback,
  onClose,
}) => {
  const classes = useStyles();
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { loading, handleValidateFeeByDistance } = useFeeByDistanceValidation();
  const { eventHandler } = useEventHandler();

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );
  const { delivery } = useSelector(
    (state: RootState) => state.orderReducer.order
  );
  const { addresses } = useSelector(
    (state: RootState) => state.localAddressesReducer
  );

  const [showAddressDialogForm, setShowAddressDialogForm] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState<Address | undefined>();

  const handleOpenAddressDialogForm = (): void => {
    setShowAddressDialogForm(true);
  };

  const handleCloseAddressDialogForm = (): void => {
    if (addressToEdit) {
      setAddressToEdit(undefined);
    }

    setShowAddressDialogForm(false);
  };

  const handleEditAddress = (address: Address): void => {
    setAddressToEdit(address);
  };

  const formik = useFormik({
    initialValues: {
      address: delivery?.address,
    },
    onSubmit: (values) => {
      const hasMap =
        establishment?.deliverySettings?.deliveryFeeType !==
          DeliveryFeeType.fixedFee &&
        establishment?.deliverySettings?.deliveryFeeType !==
          DeliveryFeeType.neighborhoodFee;

      if (
        hasMap &&
        values.address &&
        values.address.guid !== delivery?.address?.guid
      ) {
        handleValidateFeeByDistance(
          values.address,
          onSubmitCallback ?? onClose
        );
      } else {
        dispatch(setDelivery({ ...delivery, address: values.address }));

        eventHandler.addShippingInfo();

        if (onSubmitCallback) {
          onSubmitCallback();
        } else {
          onClose();
        }
      }
    },
  });

  const { values, handleSubmit } = formik;

  return (
    <>
      {showAddressDialogForm || addressToEdit ? (
        <AddressDialogForm
          selectAddressFormik={formik}
          onClose={handleCloseAddressDialogForm}
          addressToEdit={addressToEdit}
        />
      ) : null}

      <Dialog
        open
        onBackdropClick={onClose}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{ className: classes.paper }}
        data-testid="SelectAddressDialog"
      >
        <Box style={{ padding: isSm ? 16 : '32px 32px 16px 32px' }}>
          <Box
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography style={{ fontSize: '1.125rem', fontWeight: 600 }}>
              Endereço de entrega
            </Typography>

            <Tooltip arrow title="Fechar">
              <IconButton
                data-testid="close-button"
                size="small"
                onClick={onClose}
              >
                <Close />
              </IconButton>
            </Tooltip>
          </Box>

          <Button
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<Add />}
            onClick={handleOpenAddressDialogForm}
          >
            Adicionar endereço
          </Button>
        </Box>

        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: isSm ? 16 : '0 32px',
          }}
        >
          {addresses.length > 0 ? (
            <>
              {addresses?.map((address) => {
                if (
                  establishment?.deliverySettings?.deliveryFeeType ===
                  DeliveryFeeType.neighborhoodFee
                ) {
                  const isAddressOnNeighborhood =
                    establishment.deliverySettings.neighborhoodFees.some(
                      ({ city, neighborhoodFees }) =>
                        city.name?.toLowerCase() ===
                          address.city?.name?.toLocaleLowerCase() &&
                        neighborhoodFees.find(
                          ({ neighborhood }) =>
                            neighborhood.toLocaleLowerCase() ===
                            address.neighborhood.toLocaleLowerCase()
                        )
                    );

                  if (!isAddressOnNeighborhood) return null;

                  return (
                    <AddressCard
                      key={address.guid}
                      address={address}
                      formik={formik}
                      handleEditAddress={handleEditAddress}
                    />
                  );
                }

                return (
                  <AddressCard
                    key={address.guid}
                    address={address}
                    formik={formik}
                    handleEditAddress={handleEditAddress}
                  />
                );
              })}
            </>
          ) : (
            <Typography
              style={{ textAlign: 'center', color: '#636965', fontWeight: 400 }}
            >
              Você ainda não possui endereços cadastrados.
            </Typography>
          )}
        </DialogContent>

        <DialogActions style={{ padding: isSm ? 16 : '24px 32px 32px 32px' }}>
          <LoadingButton
            fullWidth
            disableElevation
            disabled={!values.address}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
            loading={loading}
          >
            salvar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectAddressDialog;
