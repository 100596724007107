import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Skeleton, Theme } from '@mui/material';
import TicketPercent from '../../../../modules/assets/TicketPercent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/types';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    border: '1px solid #CACECC',
    borderRadius: 8,
    padding: 16,
  },
  titleLine: {
    display: 'flex',
    gap: 8,
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.3125rem',
    color: '#636965',
    marginTop: 8,
  },
  rulesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    marginTop: 16,
  },
  validThru: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.3125rem',
    color: '#969C98',
  },
  ruleButton: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontFamily: theme.typography.h6.fontFamily,
    textTransform: 'uppercase',
  },
}));

type DiscountSkeletonProps = {};

const DiscountSkeleton: React.FC<DiscountSkeletonProps> = ({ children }) => {
  const classes = useStyles();

  const { discountCode } = useSelector(
    (state: RootState) => state.userDiscountReducer
  );

  const renderCorrectlyNumberOrItens = () => {
    if (discountCode?.result?.length) {
      return Array.from(Array(discountCode.result.length).keys());
    }

    return Array.from(Array(3).keys());
  };

  return (
    <>
      {renderCorrectlyNumberOrItens().map((index) => (
        <Box className={classes.root} data-testid="discount-card" key={index}>
          <Box className={classes.titleLine}>
            <TicketPercent />
            <Skeleton
              variant="text"
              animation="wave"
              width={Math.random() * 200 + 100}
              height={24}
            />
          </Box>
          <Skeleton
            variant="text"
            animation="wave"
            width={Math.random() * 300 + 100}
            height={20}
            style={{ marginTop: 8 }}
          />
          <Box className={classes.rulesContainer}>
            <Skeleton variant="text" animation="wave" width={60} height={22} />
            <Skeleton variant="text" animation="wave" width={180} height={22} />
          </Box>
        </Box>
      ))}
    </>
  );
};

export default DiscountSkeleton;
