import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { PublicUserAddress } from '@wls-solucoes/lets-eat-types';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { DeliveryFeeType } from '../../models';
import BackdropProgressIndicator from '../BackdropProgressIndicator';
import AddressCard from './components/AddressCard';

const UserAddressDialogForm = dynamic(
  () => import('../UserAddressDialogForm'),
  { loading: () => <BackdropProgressIndicator /> }
);

interface UserAddressContentProps {
  pageVariant?: boolean;
}

const UserAddressesContent: React.FC<UserAddressContentProps> = ({
  pageVariant,
}) => {
  const { user } = useSelector((state: RootState) => state.publicUserReducer);
  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const [showUserAddressDialogForm, setShowUserAddressDialogForm] =
    useState(false);
  const [addressToEdit, setAddressToEdit] = useState<
    PublicUserAddress | undefined
  >();

  const handleOpenUserAddressDialogForm = (
    addressToEdit?: PublicUserAddress
  ) => {
    setShowUserAddressDialogForm(true);
    setAddressToEdit(addressToEdit);
  };

  const handleCloseUserAddressDialogForm = () => {
    setShowUserAddressDialogForm(false);
    setAddressToEdit(undefined);
  };

  return (
    <>
      {showUserAddressDialogForm && (
        <UserAddressDialogForm
          addressToEdit={addressToEdit}
          onClose={handleCloseUserAddressDialogForm}
        />
      )}

      {(!user?.addresses || user.addresses.length === 0) && (
        <Box paddingTop={6} mb={2} display="flex" justifyContent="center">
          <Box maxWidth={232}>
            <Image
              width={232}
              height={180}
              src="/svg/address-illustration.svg"
              alt="Ilustração em tons de cinza e preto de uma pessoa ao lado de um ícone de localização."
            />

            <Typography
              variant="h6"
              style={{ fontWeight: 700, textAlign: 'center', marginTop: 32 }}
            >
              Nenhum endereço foi adicionado ainda!
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        data-testid="container"
        style={pageVariant ? { padding: 16 } : undefined}
      >
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          startIcon={<Add />}
          onClick={() => handleOpenUserAddressDialogForm()}
        >
          adicionar endereço
        </Button>

        {user?.addresses?.map((address) => {
          if (
            establishment?.deliverySettings?.deliveryFeeType ===
            DeliveryFeeType.neighborhoodFee
          ) {
            const isAddressOnNeighborhood =
              establishment.deliverySettings.neighborhoodFees.some(
                ({ city, neighborhoodFees }) =>
                  city.name?.toLowerCase() ===
                    address.city?.name?.toLocaleLowerCase() &&
                  neighborhoodFees.find(
                    ({ neighborhood }) =>
                      neighborhood.toLocaleLowerCase() ===
                      address.neighborhood.toLocaleLowerCase()
                  )
              );

            if (!isAddressOnNeighborhood) return null;

            return (
              <AddressCard
                key={address.guid}
                address={address}
                handleOpenUserAddressDialogForm={
                  handleOpenUserAddressDialogForm
                }
              />
            );
          }

          return (
            <AddressCard
              key={address.guid}
              address={address}
              handleOpenUserAddressDialogForm={handleOpenUserAddressDialogForm}
            />
          );
        })}
      </Box>
    </>
  );
};

export default UserAddressesContent;
