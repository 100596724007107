import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

export const successMessageResponseInterceptor = (
  response: AxiosResponse
): AxiosResponse => {
  const successMessage = response.config.headers?.successMessage;
  const hasSuccessMessage = successMessage !== undefined;

  if (hasSuccessMessage) {
    toast.success(decodeURI(successMessage), { theme: 'colored' });
  }

  return response;
};

export const successMessageRequestInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const { headers } = config;
  const successMessage = headers?.successMessage;

  if (headers !== undefined && successMessage !== undefined) {
    headers.successMessage = encodeURI(successMessage);
  }

  return config;
};
