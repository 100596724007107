import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChargeType, TypeOption } from '@wls-solucoes/lets-eat-types';
import React from 'react';
import { round, toCurrencyValue } from '../../../../../helpers/utils';
import { ItemCheckout } from '../../../../models';
import ItemActions from './components/ItemActions';

const useStyles = makeStyles((theme) => ({
  optionalText: {
    color: '#636965',
    fontSize: '.875rem',
  },
}));

interface CartItemProps {
  disableInteractions?: boolean;
  item: ItemCheckout;
  index: number;
  handleEditItem: (item: ItemCheckout, index: number) => void;
}

const CartItem: React.FC<CartItemProps> = ({
  disableInteractions,
  item,
  index,
  handleEditItem,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="flex-start" style={{ gap: 12 }}>
      <Grid container style={{ gap: 8, paddingTop: 8 }}>
        <Grid
          item
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          xs={12}
        >
          <Typography style={{ fontWeight: 600 }}>
            {`${item.quantity}x ${item.name}`}
          </Typography>

          <Typography style={{ fontWeight: 600 }}>
            {toCurrencyValue(item.price)}
          </Typography>
        </Grid>

        {item.optionals.map((optional) => {
          const highestValue =
            optional.options !== undefined
              ? Math.max(
                  ...optional.options?.map((op) =>
                    op.promotionPrice && op.promotionPrice < op.price
                      ? op.promotionPrice
                      : op.price
                  )
                )
              : 0;

          const pizzaOpProps = optional.options?.reduce(
            (acc, op) => {
              acc.numberOfParts += op.quantity;

              acc.total +=
                op.quantity *
                (op.promotionPrice && op.promotionPrice < op.price
                  ? op.promotionPrice
                  : op.price);

              return acc;
            },
            { numberOfParts: 0, total: 0 }
          );

          return (
            <Grid key={optional.guid} item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Typography className={classes.optionalText}>
                  {optional.name}
                </Typography>

                {optional.options?.length === 0 ? (
                  <Typography className={classes.optionalText}>
                    {toCurrencyValue(optional.price)}
                  </Typography>
                ) : (
                  <>
                    {optional.type === TypeOption.pizza ||
                    optional.type === TypeOption.size ? (
                      <Typography className={classes.optionalText}>
                        {toCurrencyValue(
                          optional.chargeType === ChargeType.highest
                            ? highestValue
                            : pizzaOpProps?.total ?? 0 * item.quantity
                        )}
                      </Typography>
                    ) : null}
                  </>
                )}
              </Box>

              {optional.type === TypeOption.pizza ||
              optional.type === TypeOption.size ? (
                <>
                  {optional.options?.map((option) => {
                    const partsInDecimal =
                      pizzaOpProps !== undefined
                        ? Number(
                            (
                              option.quantity / pizzaOpProps.numberOfParts
                            ).toFixed(4)
                          )
                        : 0;

                    if (option.quantity > 0) {
                      return (
                        <Box
                          key={`${optional.guid}_op${index}`}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Typography
                            className={classes.optionalText}
                            style={{ fontWeight: 400 }}
                          >
                            {`${round(
                              partsInDecimal *
                                (pizzaOpProps?.numberOfParts ?? 0)
                            )}/${pizzaOpProps?.numberOfParts} ${option.name}`}
                          </Typography>
                        </Box>
                      );
                    }
                  })}
                </>
              ) : (
                <>
                  {optional.options?.map((option, index) => (
                    <Box
                      key={`${optional.guid}_op${index}`}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography
                        className={classes.optionalText}
                        style={{ fontWeight: 400 }}
                      >
                        {`${option.quantity}x ${option.name}`}
                      </Typography>

                      <Typography
                        className={classes.optionalText}
                        style={{ fontWeight: 400 }}
                      >
                        {toCurrencyValue(option.price)}
                      </Typography>
                    </Box>
                  ))}
                </>
              )}
            </Grid>
          );
        })}
      </Grid>

      {!disableInteractions && (
        <ItemActions
          item={item}
          itemIndex={index}
          handleEditItem={() => handleEditItem(item, index)}
        />
      )}
    </Box>
  );
};

export default CartItem;
