import {
  alpha,
  Box,
  ButtonBase,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import DoneIcon from '../../../../../../../../shared/components/DoneIcon';
import Flags from '../../../../../../../../modules/assets/Flags';

interface StyleProps {
  color: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: 8,
    padding: 12,
    borderRadius: 4,
    border: ({ color }) => `1px solid ${color}`,

    transition: 'all 0.2s',

    '&:hover': {
      backgroundColor: ({ color }) => alpha(color, 0.05),
    },
  },
}));

interface PaymentMethodButtonProps {
  isSelected: boolean;
  icon: string | JSX.Element;
  label: string;
  onClick: () => void;
  showFlags?: boolean;
}

const PaymentMethodButton: React.FC<PaymentMethodButtonProps> = ({
  isSelected,
  icon,
  label,
  onClick,
  showFlags,
}) => {
  const theme = useTheme();
  const classes = useStyles(
    isSelected ? { color: theme.palette.primary.main } : { color: '#969C98' }
  );

  return (
    <>
      <ButtonBase
        data-testid="payment-method-button"
        className={classes.root}
        onClick={onClick}
      >
        <Box display="flex" alignItems="center" style={{ gap: 20 }}>
          {typeof icon === 'string' ? (
            <img height={24} src={icon} alt={label} />
          ) : (
            icon
          )}

          <Typography>{label}</Typography>
        </Box>

        {isSelected && <DoneIcon small />}
      </ButtonBase>
      {showFlags && <Flags style={{ marginTop: 16 }} />}
    </>
  );
};

export default PaymentMethodButton;
