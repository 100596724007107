import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Button, Divider, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { itemsTotalSelector } from '../../../redux/reducers/order/selectors';
import { toCurrencyValue } from '../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  highlight: {
    fontWeight: 600,
  },
  root: {
    marginTop: 24,
    padding: 16,
    borderRadius: 8,
    backgroundColor: '#F2F3F3',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '21px',
    display: 'flex',
    flexDirection: 'column',
  },
  progress: {
    margin: '16px 0',
    borderRadius: 2,
    backgroundColor: '#CACECC',

    '& .MuiLinearProgress-bar': {
      borderRadius: 2,
    },
  },
}));

type CartCouponBoxProps = {
  onClose: () => void;
  value: number;
  maxValue: number;
};

const CartCouponBox: React.FC<CartCouponBoxProps> = ({
  onClose,
  value,
  maxValue,
}) => {
  const classes = useStyles();

  const Highlight: React.FC = ({ children }) => (
    <span className={classes.highlight}>{children}</span>
  );

  const normalizedValue =
    (value * 100) / maxValue > 100 ? 100 : (value * 100) / maxValue;

  return (
    <>
      <Box className={classes.root} data-testid="cart-coupon-box">
        <Box>
          O <Highlight>cupom teste</Highlight> possui um valor de pedido mínimo
          de <Highlight>{toCurrencyValue(maxValue)}.</Highlight> Adicione mais{' '}
          <Highlight>{toCurrencyValue(maxValue - value)}</Highlight> para ativar
          o beneficio, ou remova o cupom de seu pedido
        </Box>
        <LinearProgress
          color={normalizedValue === 100 ? 'success' : 'primary'}
          variant="determinate"
          className={classes.progress}
          value={normalizedValue}
        />
        <Button
          color="primary"
          onClick={onClose}
          data-testid="add-more-itens-button"
        >
          ADICIONAR MAIS ITENS
        </Button>
      </Box>
      <Divider style={{ marginTop: 16 }} />
    </>
  );
};

export default CartCouponBox;
