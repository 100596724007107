import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import { useRouter } from 'next/router';
import React from 'react';
import { useSelector } from 'react-redux';
import { toCurrencyValue } from '../../../helpers/utils';
import { itemsTotalSelector } from '../../../redux/reducers/order/selectors';
import { RootState } from '../../../redux/types';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 380,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface MinDeliveryAmountAlertProps {
  open: boolean;
  onClose: () => void;
}

const MinDeliveryAmountAlert: React.FC<MinDeliveryAmountAlertProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const { push } = useRouter();

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const subtotal = useSelector(itemsTotalSelector);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{ className: classes.paper }}
    >
      <DialogTitle style={{ fontWeight: 700 }}>Valor mínimo</DialogTitle>

      <DialogContent>
        <Typography>
          O pedido mínimo é de{' '}
          <strong>
            {toCurrencyValue(
              establishment?.deliverySettings?.minDeliveryAmount ?? 0
            )}
          </strong>
          , sem contar a taxa de entrega. Adicione mais{' '}
          <strong>
            {toCurrencyValue(
              (establishment?.deliverySettings?.minDeliveryAmount ?? 0) -
                subtotal
            )}
          </strong>{' '}
          em itens para fazer o pedido.
        </Typography>
      </DialogContent>

      <DialogActions
        style={{ flexDirection: 'column', gap: 8, padding: '16px 32px' }}
      >
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => push('/')}
        >
          adicionar mais itens
        </Button>

        <Button fullWidth color="primary" onClick={onClose}>
          cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MinDeliveryAmountAlert;
