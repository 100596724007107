const refreshTokenKey = 'psyduck';
const accessTokenKey = 'golduck';

function setRefreshToken(refreshToken: string) {
  localStorage.setItem(refreshTokenKey, refreshToken);
}

function getRefreshToken() {
  return localStorage.getItem(refreshTokenKey);
}

function removeRefreshToken() {
  localStorage.removeItem(refreshTokenKey);
}

function setAccessToken(token: string) {
  localStorage.setItem(accessTokenKey, token);
}

function getAccessToken() {
  return localStorage.getItem(accessTokenKey);
}

function removeAccessToken() {
  localStorage.removeItem(accessTokenKey);
}

export const authService = {
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
  setAccessToken,
  getAccessToken,
  removeAccessToken,
};
