import { Delete, Edit, MoreVert } from '@mui/icons-material';
import {
  Box,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { useConfirm } from 'material-ui-confirm';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEventHandler } from '../../../../../../../helpers/hooks';
import { removeItem } from '../../../../../../../redux/reducers/order/reducer';
import { ItemCheckout } from '../../../../../../models';

interface ItemActionsProps {
  item: ItemCheckout;
  itemIndex: number;
  handleEditItem: () => void;
}

const ItemActions: React.FC<ItemActionsProps> = ({
  item,
  itemIndex,
  handleEditItem,
}) => {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const { eventHandler } = useEventHandler();

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setMenuAnchor(null);
  };

  const handleRemoveItem = (): void => {
    confirm({
      title: 'Remove item?',
      description: 'Esta ação não poderá ser desfeita',
      confirmationText: 'Remover',
    }).then(() => {
      dispatch(removeItem(itemIndex));

      eventHandler.removeFromCart({
        value: item.total,
        item: {
          item_id: item.guid,
          item_name: item.name,
          item_category: item.categoryGuid,
        },
      });
    });
  };

  return (
    <>
      <Hidden smDown>
        <Box display="flex" style={{ gap: 10 }}>
          <Tooltip arrow title="Editar item">
            <IconButton
              data-testid="EditItem"
              size="small"
              onClick={handleEditItem}
            >
              <Edit color="action" />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Remover item do carrinho">
            <IconButton size="small" onClick={handleRemoveItem}>
              <Delete style={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <IconButton
          data-testid="openMenuButton"
          size="small"
          onClick={handleOpenMenu}
        >
          <MoreVert />
        </IconButton>

        <Menu
          data-testid="Menu"
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={handleCloseMenu}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            data-testid="EditItem"
            onClick={() => {
              handleCloseMenu();
              handleEditItem();
            }}
          >
            <Edit color="action" />
            <Typography color="textSecondary">Editar item</Typography>
          </MenuItem>

          <MenuItem onClick={handleRemoveItem} data-testid="RemoveItem">
            <Delete style={{ color: red[500] }} />
            <Typography style={{ color: red[500] }}>Remover item</Typography>
          </MenuItem>
        </Menu>
      </Hidden>
    </>
  );
};

export default ItemActions;
