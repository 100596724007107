import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 390,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface NeedFiscalDocumentDialogProps {
  open: boolean;
  onClose: () => void;
  onYes: () => void;
  onNo: () => void;
}

const NeedFiscalDocumentDialog: React.FC<NeedFiscalDocumentDialogProps> = ({
  open,
  onClose,
  onYes,
  onNo,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{ className: classes.paper }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: '32px 32px 24px 32px' }}
      >
        <Typography style={{ fontSize: '1.125rem', fontWeight: 600 }}>
          CPF/CNPJ na nota?
        </Typography>

        <Tooltip arrow title="Fechar">
          <IconButton data-testid="close-button" size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Box>

      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          padding: '0 32px 32px 32px',
        }}
      >
        <Button
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          onClick={onYes}
        >
          sim
        </Button>

        <Button
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          onClick={onNo}
        >
          não
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default NeedFiscalDocumentDialog;
