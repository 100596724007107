import { Add } from '@mui/icons-material';
import { Box, Button, Card, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CartItemRecommendation } from '../../../../../../models';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 180,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 12,
  },

  addButton: {
    alignSelf: 'flex-end',
    width: 24,
    height: 24,
    minWidth: 'unset',
    padding: 0,
    borderRadius: '50%',
  },
}));

interface ItemRecommendationCardProps {
  recommendedItem: CartItemRecommendation;
  onAddRecommendedItem: (recommendation: CartItemRecommendation) => void;
}

const ItemRecommendationCard: React.FC<ItemRecommendationCardProps> = ({
  recommendedItem,
  onAddRecommendedItem,
}) => {
  const classes = useStyles();

  const { itemName, imageUrl } = recommendedItem;

  return (
    <Card variant="outlined" className={classes.root}>
      <Box>
        {imageUrl && (
          <img
            width="100%"
            height={74}
            src={imageUrl}
            alt={`Imagem de ${itemName}`}
            style={{ objectFit: 'contain', marginBottom: 16, borderRadius: 5 }}
          />
        )}

        <Typography style={{ fontSize: '.75rem', fontWeight: 400 }}>
          {itemName.length < 35 ? itemName : `${itemName.substring(0, 35)}...`}
        </Typography>
      </Box>

      <Tooltip arrow title="Adicionar ao carrinho">
        <Button
          disableElevation
          data-testid="add-recommended-item-btn"
          variant="contained"
          className={classes.addButton}
          onClick={() => onAddRecommendedItem(recommendedItem)}
        >
          <Add style={{ fontSize: 18 }} />
        </Button>
      </Tooltip>
    </Card>
  );
};

export default ItemRecommendationCard;
