import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DeliveryType,
  fetchOrderParams,
  PostOrderParams,
  updateOrderParams,
} from '../../../shared/models';
import { deliveryService } from '../../../shared/services/delivery';
import { orderService } from '../../../shared/services/order';

export const fetchOrder = createAsyncThunk(
  'order/fetch',
  async (params: fetchOrderParams) => {
    const { establishmentGuid, orderGuid, token } = params;
    return orderService.getPublicDetails(establishmentGuid, orderGuid, token);
  }
);

export const fetchDeliverySchedulingAvailableDates = createAsyncThunk(
  'order/fetchDeliverySchedulingAvailableDates',
  async (establishmentGuid: string) => {
    return deliveryService.getOrderSchedulingAvailableDates(
      establishmentGuid,
      DeliveryType.delivery
    );
  }
);

export const fetchTakeAwaySchedulingAvailableDates = createAsyncThunk(
  'order/fetchTakeAwaySchedulingAvailableDates',
  async (establishmentGuid: string) => {
    return deliveryService.getOrderSchedulingAvailableDates(
      establishmentGuid,
      DeliveryType.takeout
    );
  }
);

export const postOrder = createAsyncThunk(
  'order/post',
  async (params: PostOrderParams) => {
    const { establishmentGuid, order } = params;
    return orderService.sendPublicOrderFromPanel(establishmentGuid, order);
  }
);

export const updateStatusOrder = createAsyncThunk(
  'order/update',
  async (params: updateOrderParams) => {
    const { orderGuid, status, description } = params;
    return orderService.updatePublicStatus(orderGuid, status, description);
  }
);
