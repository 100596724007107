import { CreditCard } from '@mui/icons-material';
import Alelo from '../../../public/cardFlags/alelo.png';
import AmericanExpress from '../../../public/cardFlags/american-express.svg';
import DinersClub from '../../../public/cardFlags/diners-club.svg';
import DiscoverNetwork from '../../../public/cardFlags/discover-network.svg';
import Elo from '../../../public/cardFlags/elo.png';
import Hiper from '../../../public/cardFlags/hiper.png';
import HiperCard from '../../../public/cardFlags/hipercard.png';
import Maestro from '../../../public/cardFlags/maestro.svg';
import MasterCard from '../../../public/cardFlags/mastercard.svg';
import NutriCard from '../../../public/cardFlags/nutricard.png';
import Sodexo from '../../../public/cardFlags/sodexo.png';
import Ticket from '../../../public/cardFlags/ticket.png';
import ValeCard from '../../../public/cardFlags/valecard.png';
import VeroCheque from '../../../public/cardFlags/verocheque.png';
import Visa from '../../../public/cardFlags/visa.svg';
import { CardFlagsType } from '../../shared/models';

type FlagsProps = {
  name: CardFlagsType;
  icon: string | JSX.Element;
  label?: string;
};

export const getCardFlags = (flagName: string): FlagsProps => {
  const flags = [
    { name: CardFlagsType.Alelo, icon: Alelo.src },
    { name: CardFlagsType.AmericanExpress, icon: AmericanExpress.src },
    { name: CardFlagsType.DinersClub, icon: DinersClub.src },
    { name: CardFlagsType.ValeCard, icon: ValeCard.src },
    { name: CardFlagsType.Elo, icon: Elo.src },
    { name: CardFlagsType.Ticket, icon: Ticket.src },
    { name: CardFlagsType.MasterCard, icon: MasterCard.src },
    { name: CardFlagsType.Maestro, icon: Maestro.src },
    { name: CardFlagsType.Visa, icon: Visa.src },
    { name: CardFlagsType.Sodexo, icon: Sodexo.src },
    { name: CardFlagsType.Hiper, icon: Hiper.src },
    { name: CardFlagsType.HiperCard, icon: HiperCard.src },
    { name: CardFlagsType.DiscoverNetwork, icon: DiscoverNetwork.src },
    { name: CardFlagsType.VeroCheque, icon: VeroCheque.src },
    { name: CardFlagsType.NutriCard, icon: NutriCard.src },
  ];

  const flagsFound = flags.find((item) => item.name === flagName) || {
    name: CardFlagsType.others,
    icon: <CreditCard color="action" />,
    label: flagName,
  };

  return flagsFound;
};
