import { DeliveryType, OrderSchedulingAvailableDate } from '../models';
import Api from './api';

const baseUrl = '/api/Delivery';

function getOrderSchedulingAvailableDates(
  establishmentGuid: string,
  deliveryType: DeliveryType
): Promise<OrderSchedulingAvailableDate[]> {
  return Api.get(
    `${baseUrl}/${establishmentGuid}/scheduling?deliveryType=${deliveryType}`
  ).then((res) => res.data);
}

export const deliveryService = {
  getOrderSchedulingAvailableDates,
};
