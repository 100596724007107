import {
  Coupon,
  OrderCheckout,
  PublicUserAddress,
  PublicUserAddressRequest,
  PublicUserResponse,
  SignInProvider,
  discountCodePaginated,
} from '@wls-solucoes/lets-eat-types';
import { Order } from '../models';
import {
  PublicUserLoyaltyPlan,
  PublicUserOrderPaginatedResponse,
} from '../models/publicUser';
import Api from './api';
import { OrderMetadata } from './order';

const baseUrl = '/api/public-user';

async function createNewUser(
  name: string,
  whatsappPhoneNumber: string
): Promise<PublicUserResponse> {
  return Api.post(
    `${baseUrl}/signup`,
    {
      name,
      whatsappPhoneNumber,
    },
    {
      headers: {
        authHeader: 'true',
        skipExpiredValidation: 'true',
        'x-api-version': '3',
      },
    }
  ).then((res) => res.data);
}

async function signIn(): Promise<PublicUserResponse> {
  return Api.post(`${baseUrl}/signin`, undefined, {
    headers: {
      authHeader: 'true',
      skipExpiredValidation: 'true',
      'x-api-version': '3',
    },
  }).then((res) => res.data);
}

async function addNewAddress(
  address: PublicUserAddressRequest
): Promise<PublicUserAddress> {
  return Api.post(`${baseUrl}/address`, address, {
    headers: {
      authHeader: 'true',
      'x-api-version': '3',
    },
  }).then((res) => res.data);
}

async function getAddresses(): Promise<PublicUserAddress[]> {
  return Api.get(`${baseUrl}/address`, {
    headers: {
      authHeader: 'true',
      'x-api-version': '3',
    },
  }).then((res) => res.data);
}

async function updateAddress(
  address: PublicUserAddressRequest
): Promise<PublicUserAddress> {
  return Api.put(`${baseUrl}/address`, address, {
    headers: {
      authHeader: 'true',
      'x-api-version': '3',
    },
  }).then((res) => res.data);
}

async function setAddressAsDefault(
  addressGuid: string
): Promise<PublicUserAddress> {
  return Api.patch(`${baseUrl}/address/${addressGuid}/default`, undefined, {
    headers: {
      authHeader: 'true',
      'x-api-version': '3',
    },
  }).then((res) => res.data);
}

async function deleteAddress(addressGuid: string): Promise<void> {
  return Api.delete(`${baseUrl}/address/${addressGuid}`, {
    headers: {
      authHeader: 'true',
      'x-api-version': '3',
    },
  });
}

async function checkEmail(email: string): Promise<{
  exists: boolean;
  hasFirebaseAccount: boolean;
  providers?: SignInProvider[];
}> {
  return Api.get(`${baseUrl}/check-email?email=${email}`, {
    headers: {
      'x-api-version': '3',
    },
  }).then((res) => res.data);
}

async function getOrders(
  page: number,
  pageSize: number,
  establishmentGuid: string
): Promise<PublicUserOrderPaginatedResponse> {
  return Api.get(
    `${baseUrl}/order?page=${page}&pageSize=${pageSize}&establishmentGuid=${establishmentGuid}`,
    {
      headers: {
        authHeader: 'true',
        'x-api-version': '3',
      },
    }
  ).then((res) => res.data);
}

async function loyaltyPlan(
  establishmentGuid: string
): Promise<PublicUserLoyaltyPlan> {
  return Api.get(
    `${baseUrl}/loyalty-plan?establishmentGuid=${establishmentGuid}`,
    {
      headers: {
        authHeader: 'true',
        'x-api-version': '3',
      },
    }
  ).then((res) => res.data);
}

async function discountCode(
  page: number,
  pageSize: number,
  establishmentGuid: string
): Promise<discountCodePaginated> {
  return Api.get(
    `${baseUrl}/discount-code?establishmentGuid=${establishmentGuid}&pageSize=${pageSize}&page=${page}`,
    {
      headers: {
        authHeader: 'true',
        'x-api-version': '3',
      },
    }
  ).then((res) => res.data);
}

async function getDiscountCode(
  discountCode: string,
  establishmentGuid: string,
  hideErrorMessage?: boolean
): Promise<Coupon> {
  return Api.get(
    `${baseUrl}/discount-code/${discountCode}?establishmentGuid=${establishmentGuid}`,
    {
      headers: {
        authHeader: 'true',
        hideErrorMessage: String(hideErrorMessage),
        'x-api-version': '3',
      },
    }
  ).then((res) => res.data);
}

async function order(
  establishmentGuid: string,
  order: OrderCheckout,
  metadata?: OrderMetadata
): Promise<Order> {
  return Api.post(
    `${baseUrl}/order?establishmentGuid=${establishmentGuid}`,
    { ...order, metadata },
    {
      headers: {
        authHeader: 'true',
        'x-api-version': '3',
      },
    }
  ).then((res) => res.data);
}

export const publicUserService = {
  getDiscountCode,
  createNewUser,
  signIn,
  addNewAddress,
  getAddresses,
  updateAddress,
  setAddressAsDefault,
  deleteAddress,
  checkEmail,
  getOrders,
  loyaltyPlan,
  discountCode,
  order,
};
