import { createSlice } from '@reduxjs/toolkit';
import { UserInfoState } from '../../types';

const initialState: UserInfoState = {};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, { payload }) => {
      state.userInfo = state.userInfo
        ? { ...state.userInfo, ...payload }
        : payload;
    },
  },
});

export default userInfoSlice.reducer;
export const { setUserInfo } = userInfoSlice.actions;
