import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  debounce,
  Fade,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEventHandler } from '../../../../../helpers/hooks';
import {
  cleanSearch,
  setSearch,
} from '../../../../../redux/reducers/search/reducer';
import { RootState } from '../../../../../redux/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    left: 24,
    right: 24,
    display: 'flex',
    alignItems: 'center',
  },
  icons: {
    fontSize: 35,
  },
  SearchField: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 4,
    width: 'calc(98% - 100px)',
  },
}));

interface HeaderSearchBar {
  searchOpen: boolean;
  setSearchOpen: (value: boolean) => void;
}

const HeaderSearchBar: React.FC<HeaderSearchBar> = ({
  searchOpen,
  setSearchOpen,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { eventHandler } = useEventHandler();

  const { search } = useSelector((state: RootState) => state.searchReducer);

  const searchTriggerDebounce = useCallback(
    debounce((search_term: string) => {
      eventHandler.search(search_term);
    }, 1000),
    []
  );

  const handleOpenSearch = (): void => {
    setSearchOpen(true);
  };

  const handleCloseSearch = (): void => {
    setSearchOpen(false);
    dispatch(cleanSearch());
  };

  useEffect(() => {
    if (searchOpen) {
      document.getElementById('search-field')?.focus();
    }
  }, [searchOpen]);

  return (
    <Box className={classes.root}>
      <Fade in={searchOpen}>
        <TextField
          id="search-field"
          value={search}
          onChange={(e) => {
            dispatch(setSearch(e.target.value));
            searchTriggerDebounce(e.target.value);
          }}
          size="small"
          className={classes.SearchField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search style={{ fontSize: 24 }} />
              </InputAdornment>
            ),
          }}
        />
      </Fade>
      <Fade in={searchOpen}>
        <Button
          data-testid="close-button"
          style={{
            position: 'absolute',
            right: 0,
            fontSize: 14,
            fontWeight: 600,
          }}
          onClick={handleCloseSearch}
        >
          cancelar
        </Button>
      </Fade>
      <Fade in={!searchOpen}>
        <IconButton
          data-testid="open-button"
          style={{ position: 'absolute', right: 0 }}
          onClick={handleOpenSearch}
        >
          <Search className={classes.icons} />
        </IconButton>
      </Fade>
    </Box>
  );
};

export default HeaderSearchBar;
