import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { defaultColors } from '../../../helpers/utils';
import { RootState } from '../../../redux/types';
import CustomScrollBar from './components/CustomScrollBar/CustomScrollBar';

export interface AppThemeProps {
  children?: ReactElement<any, any>;
}

const AppTheme: React.FC<AppThemeProps> = (props) => {
  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );
  const materialTheme = useTheme();

  const { children } = props;
  const theme = establishment?.webSettings;
  const iNotSm = useMediaQuery(materialTheme.breakpoints.up('sm'));

  const appTheme = createTheme({
    typography: {
      fontFamily: ['Nunito', 'Roboto', 'sans-serif'].join(','),
      body1: {
        fontWeight: 500,
      },
      h1: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      },
      h2: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      },
      h3: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      },
      h4: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      },
      h5: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      },
      h6: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      },
      button: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
        fontWeight: 700,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '12px 16px',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: '.875rem',
            fontWeight: 500,
            color: '#636965',
          },
        },
      },
    },
    palette: {
      primary: {
        main: theme?.primaryColor ?? defaultColors.primary,
      },
      secondary: {
        main: theme?.secondaryColor ?? defaultColors.secondary,
      },
      grey: {
        50: '#F3F3F3',
        100: '#E4E6E5',
        200: '#CACECC',
        300: '#B0B5B2',
        400: '#969C98',
        500: '#7C837F',
        600: '#636965',
        700: '#4A4F4C',
        800: '#313533',
        900: '#191A19',
      },
    },
  });

  return (
    <div>
      <ThemeProvider theme={appTheme}>
        <StyledEngineProvider injectFirst>
          {iNotSm && <CustomScrollBar />}
          {children}
        </StyledEngineProvider>
      </ThemeProvider>
    </div>
  );
};

export default AppTheme;
