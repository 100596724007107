import {
  Address,
  DeliverySettings,
  OpeningHoursSettings,
  PaymentSettings,
} from '.';

export enum ManualCloseMode {
  nextOpeningDate = 'nextOpeningDate',
  nextClosingDate = 'nextClosingDate',
  manually = 'manually',
}

export enum ManualOpenMode {
  nextOpeningDate = 'nextOpeningDate',
  nextClosingDate = 'nextClosingDate',
  manually = 'manually',
}

export enum ClosedReason {
  outOfOpeningHours = 'outOfOpeningHours',
  closedManually = 'closedManually',
  paymentNotConfigured = 'paymentNotConfigured',
  deliveryNotConfigured = 'deliveryNotConfigured',
  orderLimitExceeded = 'orderLimitExceeded',
}

export interface WebSettings {
  primaryColor: string;
  secondaryColor: string;
}

export interface Settings {
  fiscalDocument: boolean;
  showPricesWhatsApp: boolean;
  showAddress: boolean;
  welcomePhrase: string;
  whatsApp: string;
  acceptsOrderType: string;
}

export interface EstablishmentBaseInfo {
  address: Address;
  name: string;
  about: string;
}

export interface Establishment {
  guid: string;
  loyaltyPlanGuid: string;
  name: string;
  path: string;
  fullLogoPath: string;
  thumbLogoPath: string;
  backgroundPath?: string;
  isOpen: boolean;
  forcedClose?: ManualCloseMode;
  forcedOpen?: ManualOpenMode;
  nextOpeningTimeAfterForcedClose?: Date;
  nextClosingTimeAfterForcedOpen?: Date;
  closedReasons: ClosedReason[];
  webSettings: WebSettings;
  deliverySettings?: DeliverySettings;
  paymentSettings: PaymentSettings;
  settings: Settings;
  baseInfo: EstablishmentBaseInfo;
  openingHoursSettings: OpeningHoursSettings;
}
