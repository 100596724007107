import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import {
  Badge,
  Box,
  IconButton,
  Tooltip,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  DiscountTarget,
  DiscountValueType,
} from '@wls-solucoes/lets-eat-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CouponIcon from '../../../../../../public/svg/CouponIcon';
import { toCurrencyValue } from '../../../../../helpers/utils';
import { RootState } from '../../../../../redux/types';
import CouponDialog from './components/CouponDialog';
import useCouponsList from './components/CouponDialog/hooks/useCouponsList';

const useStyles = makeStyles(() => ({
  couponIconContainer: {},
  badge: {
    backgroundColor: '#E81C0D',
    color: '#FFFFFF',
  },
}));

const CouponIconContainer = styled(Box)(() => ({
  height: 40,
  padding: 8,
  backgroundColor: '#ECF6F0',
  borderRadius: 4,
  position: 'relative',
}));

const DeliveryFeeDiscountLabel = styled('span')(({ theme }) => ({
  display: 'block',
  width: 'max-content',
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  fontSize: '0.875rem',
  fontWeight: 700,
  fontFamily: theme.typography.h1.fontFamily,
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  marginBottom: 16,
  padding: '0 8px',
  borderRadius: 4,
}));

const CartCouponSection: React.FC = () => {
  const classes = useStyles();

  const [_, { pagination }] = useCouponsList();

  const { orderCoupon } = useSelector(
    (state: RootState) => state.orderReducer.order
  );

  const [showCouponDialog, setShowCouponDialog] = useState(false);

  const handleOpenCouponDialog = (): void => {
    setShowCouponDialog(true);
  };

  const handleCloseCouponDialog = (): void => {
    setShowCouponDialog(false);
  };

  return (
    <>
      {showCouponDialog && <CouponDialog onClose={handleCloseCouponDialog} />}

      <Box padding="16px 0">
        {orderCoupon &&
        orderCoupon.discountTarget === DiscountTarget.deliveryFee ? (
          <DeliveryFeeDiscountLabel data-testid="delivery-fee-discount-label">
            desconto no valor do frete
          </DeliveryFeeDiscountLabel>
        ) : null}

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" style={{ gap: 16 }}>
            <Badge
              badgeContent={pagination.totalItems}
              classes={{
                badge: classes.badge,
              }}
            >
              <CouponIconContainer>
                <CouponIcon style={{ color: '#3DA865' }} />
              </CouponIconContainer>
            </Badge>

            {orderCoupon ? (
              <Box>
                <Typography style={{ fontWeight: 700 }}>
                  Cupom aplicado
                </Typography>

                <Typography style={{ fontWeight: 500, fontSize: '0.875rem' }}>
                  Cupom de{' '}
                  {orderCoupon.discountValueType ===
                  DiscountValueType.percentage
                    ? `${orderCoupon.discount}%${
                        orderCoupon.maximumDiscountValue
                          ? ` limitado a ${toCurrencyValue(
                              orderCoupon.maximumDiscountValue
                            )}`
                          : ''
                      }`
                    : toCurrencyValue(orderCoupon.discount as number)}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography style={{ fontWeight: 700 }}>
                  Adicionar um cupom
                </Typography>

                {pagination.totalItems ? (
                  <Typography style={{ fontWeight: 500, fontSize: '0.875rem' }}>
                    Cupons disponíveis
                  </Typography>
                ) : (
                  <></>
                )}
              </Box>
            )}
          </Box>

          <Tooltip
            arrow
            title={!orderCoupon ? 'Adicionar cupom' : 'Trocar cupom'}
          >
            <IconButton
              data-testid="coupon-button"
              size="small"
              onClick={() => {
                handleOpenCouponDialog();
              }}
            >
              {!orderCoupon ? (
                <Add style={{ color: '#7C837F' }} />
              ) : (
                <Edit style={{ color: '#7C837F' }} />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};

export default CartCouponSection;
