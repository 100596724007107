import { useState } from 'react';

type handleOpen = () => void;
type handleClose = () => void;

export const useBooleanState = (initialState: boolean | (() => boolean)) => {
  const [internalState, setInternalState] = useState(initialState);

  const handleOpen: handleOpen = () => {
    setInternalState(true);
  };

  const handleClose: handleClose = () => {
    setInternalState(false);
  };

  return [internalState, handleOpen, handleClose] as const;
};
