import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, SvgIconTypeMap, Typography } from '@mui/material';
import { Lock, Stars } from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '6px 16px',
    borderRadius: '0px 0px 8px 8px',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  text: {
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '150%',
  },
}));

type variants = 'loyalty' | 'blocked';

export type DisclaimerProps = {
  variant: variants;
  text: string;
};

interface variantDataProps {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  style: React.CSSProperties;
}

const variantData: Record<variants, variantDataProps> = {
  blocked: {
    icon: Lock,
    style: {
      backgroundColor: '#CACECC',
      color: '#313533',
    },
  },
  loyalty: {
    icon: Stars,
    style: {
      backgroundColor: '#FFBE5C',
      color: '#000',
    },
  },
};

const Disclaimer: React.FC<DisclaimerProps> = ({ variant, text }) => {
  const classes = useStyles();
  const currentVariantData = variantData[variant];

  return (
    <Box className={classes.root} style={currentVariantData.style}>
      <currentVariantData.icon style={{ fontSize: 16 }} />
      <Typography className={classes.text}>{text}</Typography>
    </Box>
  );
};

export default Disclaimer;
