import { isDev } from '../../shared/services/env';

export const fbPageview = () => {
  const { fbq } = window as any;

  fbq?.('track', 'PageView');
};

export const fbEvent = (name: string, options = {}) => {
  const { fbq } = window as any;

  fbq?.('track', name, options);

  if (isDev) {
    console.log(`Facebook Pixel event captured: ${name}`);
  }
};
