import { UserCredential } from 'firebase/auth';
import {
  setPublicUserInfo,
  setUserAvatar,
} from '../../redux/reducers/publicUser/reducer';
import { store } from '../../redux/store';
import { authService } from '../../shared/services/authService';
import { publicUserService } from '../../shared/services/publicUser';

export const SocialSignIn = async (
  token: string,
  userCredentials: UserCredential
): Promise<boolean> => {
  authService.setAccessToken(token);
  store.dispatch(setUserAvatar(userCredentials.user.photoURL));

  try {
    const { exists } = await publicUserService.checkEmail(
      userCredentials.user.email ?? ''
    );

    if (exists) {
      try {
        const { person, token } = await publicUserService.signIn();
        authService.setAccessToken(token ?? '');
        store.dispatch(setPublicUserInfo(person));
        return true;
      } catch {
        throw new Error('Algo deu errado ao obter os dados do usuário!');
      }
    } else {
      return false;
    }
  } catch {
    throw new Error('Não foi possível validar o e-mail!');
  }
};
