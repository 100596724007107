import { Login, MenuBook, Person, Receipt } from '@mui/icons-material';
import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import NavigationButton from './components/NavigationButton';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    width: '100%',
    height: 56,
    backgroundColor: '#fff',
    borderTop: '1px solid #CACECC',
    zIndex: 1,
  },
}));

const BottomAppBar: React.FC = () => {
  const classes = useStyles();

  const { user } = useSelector((state: RootState) => state.publicUserReducer);

  return (
    <Hidden smUp>
      <nav className={classes.root}>
        <NavigationButton icon={<MenuBook />} path="/" label="Cardápio" />

        <NavigationButton
          icon={<Receipt />}
          path="/orders"
          label="Status do pedido"
        />

        <NavigationButton
          exactPath={false}
          icon={
            !user ? (
              <Login data-testid="login-icon" />
            ) : (
              <Person data-testid="user-icon" />
            )
          }
          path="/user"
          label={!user ? 'Entrar' : 'Perfil'}
        />
      </nav>
    </Hidden>
  );
};

export default BottomAppBar;
