import { Button, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import React from 'react';
import { getTranslatedDayOfWeek } from '../../../../../../../../../../helpers/utils';
import { DayOfTheWeek } from '../../../../../../../../../../shared/models';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 16px',
    border: '1px solid #969C98',
    textTransform: 'none',
  },
}));

interface AvailableDayButtonProps {
  selected: boolean;
  date: string;
  dayOfWeek: DayOfTheWeek;
  onClick: () => void;
}

const AvailableDayButton: React.FC<AvailableDayButtonProps> = ({
  selected,
  date,
  dayOfWeek,
  onClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isToday = dayjs(date).isSame(dayjs(), 'day');

  return (
    <Button
      data-testid="button-root"
      variant="outlined"
      color={selected ? 'primary' : 'inherit'}
      className={classes.root}
      style={
        selected
          ? { border: `2px solid ${theme.palette.primary.main}` }
          : undefined
      }
      onClick={onClick}
    >
      <Typography
        style={{
          fontSize: '.875rem',
          color: !selected ? '#636965' : undefined,
        }}
      >
        {isToday ? 'Hoje' : getTranslatedDayOfWeek(dayOfWeek, true)}
      </Typography>

      <Typography
        style={{ fontSize: '1.125rem', fontWeight: 600, color: '#000' }}
      >
        {dayjs(date).date()}
      </Typography>
    </Button>
  );
};

export default AvailableDayButton;
