import { OrderSummary } from '@wls-solucoes/lets-eat-types';

export enum OrderStatus {
  pending = 'pending',
  accepted = 'accepted',
  finished = 'finished',
  canceled = 'canceled',
  delivered = 'delivered',
  takeout = 'takeout',
  preparing = 'preparing',
}

export interface paginated<T> {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  result?: T;
  query?: string;
}

export enum DiscountCodeType {
  generic = 'generic',
  personal = 'personal',
  firstPurchase = 'firstPurchase',
}

export enum DiscountValueType {
  percentage = 'percentage',
  value = 'value',
}

export enum Status {
  activated = 'activated',
  deactivated = 'deactivated',
}

export enum DiscountCodeStatus {
  activated = 'activated',
  deactivated = 'deactivated',
  notStarted = 'notStarted',
  expired = 'expired',
  depleted = 'depleted',
}

export interface DiscountCode {
  code: string;
  discountCodeType: DiscountCodeType;
  discountValueType: DiscountValueType;
  discount: number;
  minimumOrderValue: number;
  maximumDiscountValue?: number;
  quantity: number;
  initialDate?: string;
  endDate?: string;
  status: DiscountCodeStatus;
  discountCodeStatus: DiscountCodeStatus;
  guid: string;
}

export interface discountCodePaginated extends paginated<DiscountCode[]> {}
export interface PublicUserLoyaltyPlan {
  currentDeadLine?: string;
  currentPoints?: number;
}
export interface PublicUserOrderPaginatedResponse
  extends paginated<OrderSummary[]> {
  status: OrderStatus;
  lasTimeUpdated?: string;
  hideFinished: boolean;
  hideCanceled: boolean;
  startDate?: string;
  endDate?: string;
}
