import type {
  ICardPaymentBrickPayer,
  ICardPaymentFormData,
} from '@mercadopago/sdk-react/bricks/cardPayment/type';
import { useState } from 'react';

export const useMercadoPagoPayment = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleMercadoPagoSubmit = (
    onSubmit: (data: ICardPaymentFormData<ICardPaymentBrickPayer>) => void
  ) => {
    setIsLoading(true);
    (window as any).cardPaymentBrickController
      .getFormData()
      .then((cardFormData: ICardPaymentFormData<ICardPaymentBrickPayer>) => {
        if (cardFormData) {
          onSubmit(cardFormData);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    handleMercadoPagoSubmit,
  };
};
