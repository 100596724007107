import { GetServerSidePropsContext, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { Coupon, Establishment } from '../../shared/models';
import { discountCodeService } from '../../shared/services/discountCode';
import { establishmentService } from '../../shared/services/establishment';

export const getServerSidePropsCallback = async (
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
) => {
  const subdomain = context.req.headers.host?.split('.')[1]
    ? context.req.headers.host?.split('.')[0]
    : null;

  let establishmentData: Establishment | null = null;
  let DiscountCodeData: { error: string | null; Coupon: Coupon | null } = {
    error: null,
    Coupon: null,
  };

  if (subdomain) {
    establishmentData = (await establishmentService
      .getEstablishmentByPath(subdomain)
      .then((res) => res)) as Establishment;
  }
  if (context.query.disc) {
    const decoded = Buffer.from(
      context.query.disc as string,
      'base64'
    ).toString('ascii');

    await discountCodeService
      .getDiscountCode(decoded, establishmentData?.guid ?? '')
      .then(
        (resp) => (DiscountCodeData = { ...DiscountCodeData, Coupon: resp })
      )
      .catch(
        (err) =>
          (DiscountCodeData = {
            ...DiscountCodeData,
            error: err.response?.data?.message
              ? err.response?.data?.message
              : 'Cupom Invalido',
          })
      );
  }

  return {
    props: {
      establishmentData,
      disc: DiscountCodeData,
    },
  };
};
