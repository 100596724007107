import { ConfirmProvider } from 'material-ui-confirm';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { fbPageview } from '../lib/fbpixel';
import { gaPageview } from '../lib/ga';
import { persistor, store } from '../redux/store';
import AppTheme from '../shared/components/ApplicationTheme/ApplicationTheme';
import BottomAppBar from '../shared/components/BottomAppBar';
import CatalogFooter from '../shared/components/CatalogFooter';
import DispatchProvider from '../shared/components/DispatchProvider';
import FetchUserData from '../shared/components/FetchUserData';
import Header from '../shared/components/Header';
import ProgressBar from '../shared/components/ProgressBar';
import ProgressIndicator from '../shared/components/ProgressIndicator';
import { Coupon } from '../shared/models';
import { discountCodeService } from '../shared/services/discountCode';
import { isDev } from '../shared/services/env';
import { establishmentService } from '../shared/services/establishment';
import '../styles/globals.css';
import { useHistoryStore } from '../zustand';
import Custom404 from './404';
import { initMercadoPago } from '@mercadopago/sdk-react';
import {
  Establishment,
  MercadoPagoIntegrationsInfoStatus,
} from '@wls-solucoes/lets-eat-types';

const ToastContainer = dynamic(
  () => import('../shared/components/ToastContainer')
);
const Moment = dynamic(() => import('../shared/components/Moment'));

const isServerside = typeof window === 'undefined';

const redirectUser = (ctx: any, location: string) => {
  if (ctx.req) {
    ctx.res.writeHead(302, { Location: location });
    ctx.res.end();
  } else {
    Router.push(location);
  }
};

interface PageProps {
  establishmentData?: Establishment;
  subdomainNotFound?: boolean;
  hideHeader?: boolean;
  appBarTitle?: string;
}

function MyApp({ Component, pageProps }: AppProps<PageProps>) {
  const router = useRouter();
  const { addRoute } = useHistoryStore();
  const [canonicalURL, setCanonicalURL] = useState('');

  function pageView(url: string) {
    gaPageview(url);
    fbPageview();

    if (isDev) {
      console.log(`Page view changed to: ${url}`);
    }
  }

  const ComponentToRender = () => {
    if (pageProps.subdomainNotFound) {
      return <Custom404 subdomainNotFound {...pageProps} />;
    }

    return <Component {...pageProps} />;
  };

  useEffect(() => {
    pageView(router.pathname);
    router.events.on('routeChangeComplete', (url) => {
      addRoute(url);
      pageView(url);
    });

    return () => {
      router.events.off('routeChangeComplete', (url) => {
        addRoute(url);
        pageView(url);
      });
    };
  }, [router.events]);

  useEffect(() => {
    addRoute(router.pathname);
  }, []);

  useEffect(() => {
    const { publicKey, status } =
      pageProps?.establishmentData?.integrations.mercadoPagoIntegrationsInfo ??
      {};
    if (status === MercadoPagoIntegrationsInfoStatus.activated && publicKey) {
      initMercadoPago(publicKey);
    }
  }, [pageProps]);

  useEffect(() => {
    const url = new URL(window.location.href);
    setCanonicalURL(`${url.origin}${url.pathname}`);
  }, [router]);

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalURL} />
      </Head>
      <Provider store={store}>
        <AppTheme>
          <ConfirmProvider
            defaultOptions={{
              cancellationText: 'Cancelar',
            }}
          >
            <ProgressIndicator>
              <PersistGate persistor={persistor}>
                <FetchUserData />
                <DispatchProvider />

                {!pageProps.hideHeader ? (
                  <>
                    <Header title={pageProps.appBarTitle} />

                    <main>
                      <ComponentToRender />
                    </main>
                  </>
                ) : (
                  <ComponentToRender />
                )}

                {router.pathname === '/' ||
                router.pathname === '/orders' ||
                router.pathname.includes('/user') ? (
                  <BottomAppBar />
                ) : null}

                {router.pathname === '/' && <CatalogFooter />}

                <Moment />
                <ToastContainer />
                <ProgressBar />
              </PersistGate>
            </ProgressIndicator>
          </ConfirmProvider>
        </AppTheme>
      </Provider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: any) => {
  const { ctx } = appContext;
  const is404page = ctx.pathname === '/404';

  if (!isServerside) {
    return { pageProps: { establishmentData: null } };
  }

  const subdomain = ctx.req.headers.host?.split('.')[1]
    ? ctx.req.headers.host?.split('.')[0]
    : null;

  let establishmentData: Establishment | null = null;
  let DiscountCodeData: {
    error: string | null;
    errorName: string | null;
    Coupon: Coupon | null;
    couponCode: string | null;
  } = {
    error: null,
    errorName: null,
    Coupon: null,
    couponCode: null,
  };

  if (subdomain) {
    establishmentData = (await establishmentService
      .getEstablishmentByPath(subdomain)
      .then((res) => res)) as Establishment;
  }

  if (ctx.query.disc) {
    const decoded = Buffer.from(ctx.query.disc as string, 'base64').toString(
      'utf8'
    );

    DiscountCodeData = {
      ...DiscountCodeData,
      couponCode: decoded,
    };

    await discountCodeService
      .getDiscountCode(encodeURI(decoded), establishmentData?.guid ?? '')
      .then(
        (resp) => (DiscountCodeData = { ...DiscountCodeData, Coupon: resp })
      )
      .catch((err) => {
        DiscountCodeData = {
          ...DiscountCodeData,
          error: err.response?.data?.message
            ? err.response?.data?.message
            : 'Cupom Invalido',
          errorName: err.response?.data?.errorName,
        };
      });
  }

  if (!establishmentData) {
    return {
      pageProps: {
        subdomainNotFound: true,
      },
    };
  } else if (is404page) {
    redirectUser(ctx, '/');
  }
  return {
    pageProps: {
      establishmentData,
      disc: DiscountCodeData,
    },
  };
};

export default MyApp;
