export enum CardFlagsType {
  Visa= "Visa",
  MasterCard= "MasterCard",
  AmericanExpress= "American Express",
  DiscoverNetwork= "Discover Network",
  Maestro= "Maestro",
  Hiper= "Hiper",
  HiperCard= "HiperCard",
  DinersClub= "Diners Club",
  Elo= "Elo",
  Alelo= "Alelo",
  Sodexo= "Sodexo",
  Ticket= "Ticket",
  ValeCard= "ValeCard",
  VeroCheque= "VeroCheque",
  NutriCard= "NutriCard",
  others= "others"
}
  