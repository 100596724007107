import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { getLoyaltyPlan } from '../../../redux/reducers/loyaltyPlan/actions';

type DispatchProviderProps = {};

const DispatchProvider: React.FC<DispatchProviderProps> = () => {
  const dispatch = useDispatch();

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const { loyaltyPlan } = useSelector(
    (state: RootState) => state.loyaltyPlanReducer
  );

  useEffect(() => {
    if (!establishment?.loyaltyPlanGuid || loyaltyPlan) {
      return;
    }
    dispatch(
      getLoyaltyPlan({
        loyaltyPlanGuid: establishment.loyaltyPlanGuid,
        establishmentGuid: establishment.guid,
      })
    );
  }, [establishment, loyaltyPlan]); // eslint-disable-line

  return null;
};

export default DispatchProvider;
