import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import Router from 'next/router';
import { logout } from '../../helpers/utils/logout';
import { authService } from '../services/authService';
import { isTest } from '../services/env';
import { AUTHENTICATION_REQUIRED_HEADER_NAME as authHeader } from './headers';

export const authInterceptor = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const controller = new AbortController();
  const isAuthRequired = config.headers?.authHeader !== undefined;
  const skipExpiredValidation =
    config.headers?.skipExpiredValidation !== undefined;

  if (isAuthRequired && config.headers) {
    try {
      const accessToken = authService.getAccessToken();

      if (accessToken === '') {
        Router.push('/login');
      }

      if (!isTest) {
        if (accessToken === null) {
          controller.abort();
        } else {
          const decodedToken = jwtDecode<{ exp: number }>(accessToken);
          const expiresAt = dayjs.unix(decodedToken.exp ?? 0);
          const now = dayjs();

          if (
            expiresAt.isBefore(now.add(3, 'days')) &&
            !skipExpiredValidation
          ) {
            await logout();
            controller.abort();
          }
        }
      }

      const configWithAuth = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        } as any,
        signal: controller.signal,
      };
      delete configWithAuth.headers[authHeader];
      return configWithAuth;
    } catch (err) {
      Router.push('/login');
    }
  }

  return config;
};
