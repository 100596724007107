import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import UserAddressesContent from '../../../UserAddressesContent';

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    width: 480,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      borderRadius: 0,
      margin: 0,
    },
  },
}));

interface UserAddressesDialogProps {
  open: boolean;
  onClose: () => void;
}

const UserAddressesDialog: React.FC<UserAddressesDialogProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.paper }}
      onBackdropClick={onClose}
      onClose={onClose}
    >
      <Box
        padding="24px 24px 0"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="h6"
          style={{ fontSize: '1.125rem', fontWeight: 700 }}
        >
          Endereços
        </Typography>

        <Tooltip arrow title="Fechar">
          <IconButton data-testid="close-button" onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Box>

      <DialogContent style={{ padding: 24 }}>
        <UserAddressesContent />
      </DialogContent>
    </Dialog>
  );
};

export default UserAddressesDialog;
