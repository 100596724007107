export enum StatusType {
  activated = 'activated',
  deactivated = 'deactivated',
}

export enum ChargeType {
  highest = 'highest',
  average = 'average',
}

export enum TypeOption {
  undefined = '',
  simple = 'simple',
  selector = 'selector',
  pizza = 'pizza',
}
interface OptionalBase {
  name: string;
  price: number;
  type: TypeOption;
  status: StatusType;
  chargeType: ChargeType;
  optionsMin?: number;
  optionsMax?: number;
  optionsRepeat?: boolean;
}

export interface Optional extends OptionalBase {
  guid: string;
  options?: Option[];
  itemsQuantity: number;
}

export interface Option {
  guid?: string;
  name: string;
  description?: string;
  status: StatusType;
  price: number;
  thumbPath?: string;
  imagePath?: string;
}
