import { Dialog, Drawer, Hidden, Paper, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import SendOrderMessageDialogContent from './components/SendOrderMessageDialogContent';

const useStyles = makeStyles(() => ({
  drawerPaper: {
    borderRadius: '8px 8px 0 0',

    '& .MuiDialogContent-root': {
      padding: '0 16px 16px',
    },

    '& .MuiDialogActions-root': {
      flexDirection: 'column',
      padding: 16,
      gap: 16,
    },
  },
}));

const DialogPaper = styled(Paper)(() => ({
  width: 390,
  borderRadius: 8,
  margin: '8px !important',

  '& .MuiDialogContent-root': {
    padding: '0 32px 16px',
  },

  '& .MuiDialogActions-root': {
    flexDirection: 'column',
    padding: '16px 32px 32px',
    gap: 16,
  },
}));

interface SendOrderMessageDialogProps {
  open: boolean;
  onClose: () => void;
}

const SendOrderMessageDialog: React.FC<SendOrderMessageDialogProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <>
      <Hidden smDown>
        <Dialog open={open} PaperComponent={DialogPaper} onClose={onClose}>
          <SendOrderMessageDialogContent onClose={onClose} />
        </Dialog>
      </Hidden>

      <Hidden smUp>
        <Drawer
          open={open}
          anchor="bottom"
          PaperProps={{ className: classes.drawerPaper }}
          onBackdropClick={onClose}
          onClose={onClose}
        >
          <SendOrderMessageDialogContent onClose={onClose} />
        </Drawer>
      </Hidden>
    </>
  );
};

export default SendOrderMessageDialog;
