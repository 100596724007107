import { createAsyncThunk } from '@reduxjs/toolkit';
import { loyaltyPlanService } from '../../../shared/services/loyaltyPlan';
import { publicUserService } from '../../../shared/services/publicUser';
import { authService } from '../../../shared/services/authService';

interface getLoyaltyPlanPayload {
  loyaltyPlanGuid: string;
  establishmentGuid: string;
}

export const getLoyaltyPlan = createAsyncThunk(
  'loyaltyPlan/get',
  async ({ loyaltyPlanGuid, establishmentGuid }: getLoyaltyPlanPayload) => {
    const token = authService.getAccessToken();
    const promises: Promise<any>[] = [
      loyaltyPlanService.getLoyaltyPlanByGuid(loyaltyPlanGuid),
      token ? [publicUserService.loyaltyPlan(establishmentGuid)] : [],
    ].flat();

    return Promise.all(promises);
  }
);
