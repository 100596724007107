import { ClosedReason, ManualCloseMode, ManualOpenMode } from '.';

export enum DayOfTheWeek {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

export enum OpenCloseHourStatus {
  activated = 'activated',
  deactivated = 'deactivated',
}

export enum OpenType {
  open = 'open',
  openWithSchedule = 'openWithSchedule',
  closedWithSchedule = 'closedWithSchedule',
  closed = 'closed',
  onlySchedule = 'onlySchedule',
}

export interface OpenCloseHour {
  guid?: string;
  open: string;
  close: string;
  dayOfTheWeek: DayOfTheWeek;
  status: OpenCloseHourStatus;
}

export interface OpeningHoursSettings {
  isOpen: boolean;
  open24Hours: boolean;
  openType: OpenType;
  openingHours?: OpenCloseHour[];
  closedReasons: ClosedReason[];
  forcedClose?: ManualCloseMode;
  nextOpeningTimeAfterForcedClose?: Date;
  forcedOpen?: ManualOpenMode;
  nextClosingTimeAfterForcedOpen?: Date;
}
