import create from 'zustand';

export interface CouponWithRequiredAuthProps {
  onOpen: (onCloseAll?: () => void) => Promise<void>;
  onClose: () => void;
  isOpen: boolean;
  onCloseAllCallback?: () => void;
}

export const useCouponWithRequiredAuthStore =
  create<CouponWithRequiredAuthProps>((set) => ({
    isOpen: false,
    onOpen: async (onCloseAll) => {
      set(() => ({ isOpen: true, onCloseAllCallback: onCloseAll }));
    },
    onClose: () => set(() => ({ isOpen: false })),
  }));
