import { SvgIcon, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const ComprAquiGreenIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> = (
  props: any
) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon width="17" height="13" viewBox="0 0 17 13" {...props}>
      <path
        d="M11.9844 12.5C12.6934 12.5 13.2682 11.9586 13.2682 11.2907C13.2682 10.6228 12.6934 10.0813 11.9844 10.0813C11.2753 10.0813 10.7005 10.6228 10.7005 11.2907C10.7005 11.9586 11.2753 12.5 11.9844 12.5Z"
        fill="#3DA865"
      />
      <path
        d="M8.3381 12.5C9.04715 12.5 9.62195 11.9586 9.62195 11.2907C9.62195 10.6228 9.04715 10.0813 8.3381 10.0813C7.62905 10.0813 7.05425 10.6228 7.05425 11.2907C7.05425 11.9586 7.62905 12.5 8.3381 12.5Z"
        fill="#3DA865"
      />
      <path
        d="M16.9224 2.67741C16.8194 2.45047 16.6267 2.27637 16.3812 2.18622C16.2663 2.14394 16.1462 2.1228 16.0227 2.1228H10.0048C9.46618 2.1228 9.02723 2.53067 9.02723 3.03243C9.02723 3.53357 9.46552 3.94207 10.0048 3.94207H14.5712L13.8405 5.6171C13.2669 6.94456 11.8807 7.80259 10.3104 7.80259C8.73415 7.79824 7.30178 6.88984 6.74665 5.54435L4.92484 1.08632C4.78161 0.735647 4.4146 0.5 4.01129 0.5H0.977579C0.438955 0.5 0 0.907874 0 1.40964C0 1.9114 0.438295 2.31927 0.977579 2.31927H3.33736L4.91956 6.19099C5.75852 8.24342 7.92424 9.62311 10.3091 9.62311C10.9962 9.62311 11.6741 9.50995 12.3236 9.28674C13.8372 8.76694 15.0504 7.67824 15.6511 6.29979L16.927 3.37503C17.0261 3.1487 17.0241 2.90124 16.9224 2.67741Z"
        fill="#3DA865"
      />
    </SvgIcon>
  );
};

export default ComprAquiGreenIcon;
