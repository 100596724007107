import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Hidden,
  IconButton,
  Slide,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import React, { useRef } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { setUserInfo } from '../../../../../../redux/reducers/userInfo/reducer';
import { RootState } from '../../../../../../redux/types';
import { DialogDrawer } from '../../../../../../shared/components/DialogDrawer';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = z.object({
  addressee: z
    .string({ required_error: 'Digite seu nome e sobrenome' })
    .min(3, 'Mínimo de 3 letras')
    .regex(
      /[^0-9]{2,} [^0-9]{2,}( [^0-9]{2,}){0,}$/,
      'Digite seu nome e sobrenome'
    ),

  whatsApp: z.string({ required_error: 'Digite um WhatsApp' }).refine(
    (value) => {
      const regx = new RegExp(/(\d{10,11})/g);
      const unmasked = value?.replace(/\D/g, '') ?? '';
      return regx.test(unmasked);
    },
    {
      message: 'Número incompleto',
    }
  ),
});

interface UserInformationDialogProps {
  onSubmitCallback?: () => void;
  onClose: () => void;
}

const UserInformationDialog: React.FC<UserInformationDialogProps> = ({
  onSubmitCallback,
  onClose,
}) => {
  const classes = useStyles();
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state: RootState) => state.userInfoReducer);

  const initialValues = {
    addressee: userInfo?.name ?? '',
    whatsApp: userInfo?.whatsApp ?? '',
  };

  const {
    register,
    watch,
    setValue,
    formState: { errors },
    setFocus,
    handleSubmit,
  } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(validationSchema),
  });

  const whatsApp = watch('whatsApp');

  const onSubmit = ({ addressee, whatsApp }: typeof initialValues) => {
    dispatch(
      setUserInfo({
        name: addressee,
        whatsApp,
      })
    );

    if (onSubmitCallback) {
      onSubmitCallback();
      return;
    }

    onClose();
  };

  return (
    <DialogDrawer
      open
      onBackdropClick={onClose}
      onClose={onClose}
      onOpen={() => {}}
      TransitionComponent={Transition}
      PaperProps={{ className: classes.paper }}
      data-testid="UserInformationDialog"
      breakIn="sm"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={isSm ? 'center' : 'space-between'}
      >
        <Typography style={{ fontSize: '1.125rem', fontWeight: 600 }}>
          Suas informações
        </Typography>

        <Hidden smDown>
          <Tooltip arrow title="Fechar">
            <IconButton
              data-testid="close-button"
              size="small"
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Hidden>
      </Box>

      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          padding: '24px 0 0 0',
        }}
      >
        <TextField
          autoFocus
          fullWidth
          required
          variant="outlined"
          label="Nome e sobrenome"
          {...register('addressee')}
          error={errors.addressee !== undefined}
          helperText={errors.addressee && errors.addressee.message}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              setFocus('whatsApp');
            }
          }}
        />

        <InputMask
          mask={
            whatsApp.replace(/[^\d]+/g, '').length < 11
              ? '(99) 9999-99999'
              : '(99) 99999-9999'
          }
          value={whatsApp}
          onChange={(e) => {
            setValue('whatsApp', e.target.value.replace(/[^\d]+/g, ''));
          }}
        >
          {() => (
            <TextField
              inputProps={{ ref: register('whatsApp').ref }}
              fullWidth
              required
              variant="outlined"
              label="WhatsApp"
              name="whatsApp"
              type="tel"
              error={errors.whatsApp !== undefined}
              helperText={errors.whatsApp && errors.whatsApp.message}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleSubmit(onSubmit)();
                }
              }}
            />
          )}
        </InputMask>
      </DialogContent>

      <DialogActions style={{ padding: '24px 0 0 0' }}>
        <Button
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          salvar
        </Button>
      </DialogActions>
    </DialogDrawer>
  );
};

export default UserInformationDialog;
