import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRouter } from 'next/router';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '50%',
    height: '100%',
    borderRadius: 0,
    textTransform: 'none',
  },
}));

interface NavigationButtonProps {
  exactPath?: boolean;
  icon: JSX.Element;
  path: string;
  label: string;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  exactPath = true,
  icon,
  path,
  label,
}) => {
  const classes = useStyles();
  const { push, pathname } = useRouter();

  const isSelected = exactPath ? pathname === path : pathname.includes(path);

  return (
    <Button
      data-testid="navigation-btn"
      color={isSelected ? 'primary' : undefined}
      style={{ color: !isSelected ? '#4A4F4C' : undefined }}
      className={classes.root}
      onClick={() => {
        if (pathname !== path) {
          push(path);
        }
      }}
    >
      {icon}

      <Typography
        style={{
          fontSize: '.75rem',
          fontWeight: isSelected ? 600 : undefined,
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default NavigationButton;
