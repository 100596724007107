import { ItemType, Option, TypeOption } from '@wls-solucoes/lets-eat-types';
import {
  Address,
  AverageTime,
  ChargeType,
  DeliveryType,
  DocumentType,
  PaymentType,
} from '.';

export interface ItemCheckout {
  name: string;
  guid: string;
  type: ItemType;
  quantity: number;
  categoryGuid: string;
  price: number;
  priceGuid: string;
  priceName: string;
  annotation?: string;
  optionals: ItemOptionalCheckout[];
  total: number;
  thumbCoverImage?: string;
  description?: string;
  recommendationGuid?: string;
}

export interface CheckoutOption extends Option {
  guid: string;
  quantity: number;
}
export interface ItemOptionalCheckout {
  guid: string;
  options?: CheckoutOption[];
  type: TypeOption;
  numberOfParts?: number;
  chargeType: ChargeType;
  optionsMin?: number;
  optionsMax?: number;
  optionsRepeat?: boolean;
  quantity: number | null;
  price: number;
  name: string;
}

export interface PaymentCheckout {
  paymentType?: PaymentType;
  cardFlagGuid?: string;
  cardFlagName?: string;
  changeMoney?: number;
  paymentAmount?: number;
}

export interface DeliveryCheckout {
  neighborhoodFeeGuid?: string;
  fee?: number;
  averageTime?: AverageTime;
  type?: DeliveryType;
  addressee?: string;
  document?: string;
  whatsApp?: string;
  documentType?: DocumentType;
  address?: Address;
}

export enum OrderCheckoutErrors {
  default = 'Pedido inválido.',
  missingItems = 'Selecione um ou mais produtos para seu pedido.',
  missingAddress = 'Selecione um endereço para entrega.',
  missingDeliveryType = 'Selecione uma forma de entrega.',
  cityNotFound = 'Infelizmente não realizamos entrega nessa cidade.',
  neighborhoodNotListed = 'Bairro não encontrado. Verifique se o mesmo consta em nossa lista de entrega e se está escrito corretamente.',
  minDeliveryAmount = 'Esta loja não realiza entregas para pedidos abaixo de <MIN_AMOUNT>. Adicione mais itens.',
  missingPaymentType = 'Selecione uma forma de pagamento.',
  insufficientChangeMoney = 'Troco menor que o valor total do pedido.',
  cardFlagNotFound = 'Houve um erro ao processar seu pagamento, por favor selecione novamente sua forma de pagamento',
  missingBuyerName = 'Preencha seu nome.',
  buyerNameMin = 'Preencha seu nome com 3 ou mais caracteres.',
  missingBuyerPhone = 'Preencha seu WhatsApp, precisamos dele para nos comunicar.',
  buyerPhoneMinMax = 'WhatsApp inválido, seu numero deve possuir 10 ou 11 dígitos.',
  buyerDocumentMin = 'CPF incompleto, remova-o ou preencha-o corretamente.',
  buyerDocumentNotValid = 'CPF inválido, remova-o ou preencha-o corretamente.',
  couponMinValue = 'Esse cupom não aplica descontos para pedidos abaixo de <MIN_VALUE>. Remova-o ou adicione mais itens!',
  missingScheduleDate = 'Selecione uma data para a entrega do pedido.',
}
