import React, { memo } from 'react';
import { CardPayment } from '@mercadopago/sdk-react';
import { Box } from '@mui/material';

interface MPCardPaymentProps {
  email?: string;
  amount: number;
  maxInstallments: number;
}

const MPCardPayment: React.FC<MPCardPaymentProps> = ({
  email,
  amount,
  maxInstallments,
}) => {
  return (
    <Box style={{ minHeight: '300px', transition: 'all 2s' }}>
      <CardPayment
        locale="pt-BR"
        initialization={{
          amount,
          payer: {
            email,
          },
        }}
        onSubmit={async () => {}}
        customization={{
          paymentMethods: {
            maxInstallments,
          },
          visual: {
            hidePaymentButton: true,
            style: {
              customVariables: {
                formPadding: '0',
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default memo(MPCardPayment);
