import { createSelector } from '@reduxjs/toolkit';
import {
  Coupon,
  DeliveryCheckout,
  DiscountTarget,
  DiscountValueType,
  OrderCheckout,
} from '@wls-solucoes/lets-eat-types';
import { toCurrencyValue } from '../../../helpers/utils';
import { isValidCpf } from '../../../helpers/validators';
import {
  DeliveryFeeType,
  DeliveryType,
  Establishment,
  OrderCheckoutErrors,
  OrderTimingType,
  PaymentType,
} from '../../../shared/models';
import { RootState } from '../../types';

type OrderErrorType = {
  orderCheckout: OrderCheckout | undefined;
  establishment: Establishment | undefined;
};

const itemsCheckoutSelector = (state: RootState): OrderCheckout | undefined =>
  state.orderReducer.order;
const couponSelector = (state: RootState): Coupon | undefined =>
  state.orderReducer.order.orderCoupon;
const deliverySelector = (state: RootState): DeliveryCheckout | undefined =>
  state.orderReducer.order.delivery;

export const itemsTotalSelector = createSelector(
  itemsCheckoutSelector,
  (order) => {
    const itemsTotal =
      order?.items?.reduce((acc, item) => acc + item.total, 0) ?? 0;

    return itemsTotal;
  }
);

export const orderCheckoutDiscountSelector = createSelector(
  itemsTotalSelector,
  deliverySelector,
  couponSelector,
  (itemsTotal, delivery, discount) => {
    const isCartDiscount = discount?.discountTarget === DiscountTarget.cart;

    if (discount?.discountValueType === DiscountValueType.percentage) {
      if (!isCartDiscount && !delivery?.fee) return 0;

      const discountPercentage = (discount?.discount ?? 0) / 100;

      const discountValue =
        (isCartDiscount ? itemsTotal : delivery?.fee ?? 0) * discountPercentage;

      if (
        discount.maximumDiscountValue &&
        discountValue > discount.maximumDiscountValue
      ) {
        return discount.maximumDiscountValue ?? 0;
      }

      return discountValue;
    }

    if (!isCartDiscount && (discount?.discount ?? 0) > (delivery?.fee ?? 0)) {
      return delivery?.fee ?? 0;
    }

    return discount?.discount ?? 0;
  }
);

export const couponErrorSelector = createSelector(
  couponSelector,
  itemsTotalSelector,
  deliverySelector,
  (orderCoupon, itemsTotal, delivery) => {
    if (itemsTotal < (orderCoupon?.minimumOrderValue ?? 0)) {
      return `Faça um pedido acima de ${toCurrencyValue(
        orderCoupon?.minimumOrderValue ?? 0
      )} para ativar o cupom.`;
    }

    if (
      orderCoupon?.discountTarget === DiscountTarget.deliveryFee &&
      delivery?.type === DeliveryType.takeout
    ) {
      return 'Somente na opção "Entrega"';
    }

    return undefined;
  }
);

export const orderCheckoutTotalSelector = createSelector(
  itemsTotalSelector,
  orderCheckoutDiscountSelector,
  itemsCheckoutSelector,
  couponErrorSelector,
  (itemsTotal, discount, order, couponError) => {
    const fee = order?.delivery?.fee ?? 0;

    if (couponError || !discount) return itemsTotal + fee;

    if (order?.orderCoupon?.discountTarget === DiscountTarget.deliveryFee) {
      const feeWithDiscount = fee - discount;

      if (feeWithDiscount === 0) return itemsTotal;

      return itemsTotal + feeWithDiscount;
    }

    return itemsTotal - discount + fee;
  }
);

const orderErrorStateSelector = (state: RootState): OrderErrorType => {
  return {
    establishment: state.establishmentReducer.establishment,
    orderCheckout: state.orderReducer.order,
  };
};

export const orderErrorSelector = createSelector(
  orderErrorStateSelector,
  itemsTotalSelector,
  orderCheckoutTotalSelector,
  couponSelector,
  itemsTotalSelector,
  (state, subtotal, total, orderCoupon, itemsTotal) => {
    const { orderCheckout, establishment } = state;

    if (orderCheckout === undefined || establishment === undefined) {
      return OrderCheckoutErrors.default;
    }

    const { items, delivery, payment } = orderCheckout;

    const { deliverySettings, paymentSettings } = establishment;

    if ((items?.length ?? 0) === 0) {
      return OrderCheckoutErrors.missingItems;
    }

    if (delivery?.type === undefined) {
      return OrderCheckoutErrors.missingDeliveryType;
    }

    const {
      address,
      type,
      neighborhoodFeeGuid,
      addressee,
      whatsApp,
      document,
    } = delivery;

    if (type === DeliveryType.delivery) {
      if (
        establishment.deliverySettings?.minDeliveryAmount !== undefined &&
        subtotal < establishment.deliverySettings.minDeliveryAmount
      ) {
        return OrderCheckoutErrors.minDeliveryAmount.replace(
          '<MIN_AMOUNT>',
          toCurrencyValue(establishment.deliverySettings.minDeliveryAmount)
        );
      }

      if (address === undefined) {
        return OrderCheckoutErrors.missingAddress;
      }

      if (
        deliverySettings?.deliveryFeeType === DeliveryFeeType.neighborhoodFee &&
        (!establishment?.deliverySettings?.freeDeliveryValue ||
          subtotal < establishment?.deliverySettings.freeDeliveryValue)
      ) {
        const city = deliverySettings.neighborhoodFees.find(
          (n) => n.city.ibge === (address?.ibge ?? -1)
        );
        if (city === undefined) {
          return OrderCheckoutErrors.cityNotFound;
        }

        const fee = city.neighborhoodFees.find(
          (n) => n.guid === neighborhoodFeeGuid
        );
        if (fee === undefined) {
          return OrderCheckoutErrors.neighborhoodNotListed;
        }
      }
    }

    if (payment?.paymentType === undefined) {
      return OrderCheckoutErrors.missingPaymentType;
    }

    const { changeMoney, paymentType, cardFlagGuid } = payment;

    if (
      paymentType === PaymentType.cash &&
      (changeMoney ?? Number.MAX_SAFE_INTEGER) < total
    ) {
      return OrderCheckoutErrors.insufficientChangeMoney;
    }

    if (cardFlagGuid !== undefined) {
      const flags = [
        ...paymentSettings.creditCardFlags,
        ...paymentSettings.debitCardFlags,
        ...paymentSettings.voucherCardFlags,
      ];

      const cardFlag = flags.find((c) => c.guid === cardFlagGuid);
      if (cardFlag === undefined) {
        return OrderCheckoutErrors.cardFlagNotFound;
      }
    }

    if (addressee === undefined) {
      return OrderCheckoutErrors.missingBuyerName;
    }

    if (addressee.length < 3) {
      return OrderCheckoutErrors.buyerNameMin;
    }

    if (whatsApp === undefined || whatsApp === '') {
      return OrderCheckoutErrors.missingBuyerPhone;
    }

    if (whatsApp.length < 10 || whatsApp.length > 11) {
      return OrderCheckoutErrors.buyerPhoneMinMax;
    }

    if (document !== undefined && document !== '') {
      if (document.length < 11) {
        return OrderCheckoutErrors.buyerDocumentMin;
      }

      if (!isValidCpf(document)) {
        return OrderCheckoutErrors.buyerDocumentNotValid;
      }
    }

    if (
      orderCheckout.timing?.orderTimingType === OrderTimingType.scheduled &&
      (!orderCheckout.timing?.deliveryDateTimeStart ||
        !orderCheckout.timing?.deliveryDateTimeEnd)
    ) {
      return OrderCheckoutErrors.missingScheduleDate;
    }

    if (itemsTotal < (orderCoupon?.minimumOrderValue ?? 0)) {
      return OrderCheckoutErrors.couponMinValue;
    }

    return undefined;
  }
);
