export const toCurrencyValue = (n: number,showCurrencySymbol = true) : string => {

  // eslint-disable-next-line no-restricted-globals
  const valueToUse = isNaN(n) ? 0 : n;
  return valueToUse.toLocaleString('pt-br', {
    style: showCurrencySymbol ? 'currency' : 'decimal',
    currency:  'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export const round = (num: number): number => Math.round(num * 100 + Number.EPSILON) / 100
