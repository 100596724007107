import { discountCodePaginated } from '@wls-solucoes/lets-eat-types';
import { Coupon } from '../models';
import Api from './api';

const baseUrl = '/api/DiscountCode';

function getDiscountCode(
  discountCode: string,
  establishmentGuid: string
): Promise<Coupon> {
  return Api.get(
    `${baseUrl}/${discountCode}?establishmentGuid=${establishmentGuid}`
  ).then((r) => r.data);
}

function getPublicDiscountCode(
  page: number,
  pageSize: number,
  establishmentGuid: string
): Promise<discountCodePaginated> {
  return Api.get(
    `${baseUrl}/establishment/${establishmentGuid}/public-discount-code`,
    { params: { page, pageSize } }
  ).then((r) => r.data);
}

export const discountCodeService = {
  getDiscountCode,
  getPublicDiscountCode,
};
