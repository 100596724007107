import { unwrapResult } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { getDiscountCodeByCode } from '../../../../../../../../redux/reducers/discountCode/actions';
import { setDiscountCode } from '../../../../../../../../redux/reducers/order/reducer';
import { RootState } from '../../../../../../../../redux/types';
import { setCouponAwaitingAuth } from '../../../../../../../../redux/reducers/discountCode/reducer';
import { useCouponWithRequiredAuthStore } from '../../../../../../../../zustand/couponWithRequiredAuth';

type useCouponCodeProps = {
  onClose: () => void;
};

interface Controller {
  isSubmitting: boolean;
  showCouponWithRequiredAuth: boolean;
  handleOpenCouponWithRequiredAuth: () => void;
  handleCloseCouponWithRequiredAuth: () => void;
  submit: () => void;
}

type useCouponCodeReturn = [Object, Controller];

const validationSchema = Yup.object().shape({
  discountCode: Yup.string()
    .trim()
    .min(3, 'Mínimo de 3 caracteres')
    .max(40, 'Máximo de 40 caracteres')
    .required('Informe um código de desconto'),
});

const useCouponCode = ({
  onClose,
}: useCouponCodeProps): useCouponCodeReturn => {
  const { user } = useSelector((state: RootState) => state.publicUserReducer);
  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const dispatch = useDispatch();

  const {
    onOpen: handleOpenCouponWithRequiredAuth,
    onClose: handleCloseCouponWithRequiredAuth,
    isOpen: showCouponWithRequiredAuth,
  } = useCouponWithRequiredAuthStore();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: { discountCode: '' },
    validationSchema,
    onSubmit: async (values) => {
      const coupon = await dispatch(
        getDiscountCodeByCode({
          Code: values.discountCode,
          establishmentGuid: establishment?.guid ?? '',
        }) as any
      ).then(unwrapResult);
      if (coupon.needsPublicUserAuth && !user) {
        dispatch(setCouponAwaitingAuth(values.discountCode));
        handleOpenCouponWithRequiredAuth(onClose);

        return;
      }
      dispatch(setDiscountCode(coupon));
      onClose();
    },
  });

  const inputProperties = {
    value: values.discountCode,
    onChange: handleChange,
    onBlur: handleBlur,
    error: errors.discountCode !== undefined && touched.discountCode === true,
    helperText:
      errors.discountCode && touched.discountCode && errors.discountCode,
  };

  const controller: Controller = {
    isSubmitting,
    showCouponWithRequiredAuth: showCouponWithRequiredAuth,
    handleOpenCouponWithRequiredAuth: () =>
      handleOpenCouponWithRequiredAuth(onClose),
    handleCloseCouponWithRequiredAuth,
    submit: handleSubmit,
  };

  return [inputProperties, controller];
};

export default useCouponCode;
