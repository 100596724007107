export enum DiscountCodeStatus {
  activated = 'activated',
  deactivated = 'deactivated',
  notStarted = 'notStarted',
  expired = 'expired',
  depleted = 'depleted',
}

export enum DiscountValueType {
  percentage = 'percentage',
  value = 'value',
}

export interface Coupon {
  guid?: string;
  code?: string;
  discountCodeType?: string;
  discountValueType?: DiscountValueType;
  discount?: number;
  minimumOrderValue?: number;
  maximumDiscountValue?: number;
  quantity?: number;
  initialDate?: Date | string | null;
  endDate?: Date | string | null;
  status?: string;
  discountCodeStatus?: DiscountCodeStatus;
}
